<div class="dummy-will-change" appMovable [movable.option]="movableOption">
</div>
<div class="component is-3d is-grab is-pointer-events-none" [style.width.px]="size * gridSize"
  [style.height.px]="size * gridSize" appMovable [movable.option]="movableOption" (movable.ondragstart)="onMove()"
  (movable.ondragend)="onMoved()">
  <div class="component-content is-3d" [@bounceInOut]="'in'">
    <div class="component-content is-3d is-pointer-events-auto" appRotable [rotable.option]="rotableOption"
      (rotable.ondragstart)="onMove()" (rotable.ondragend)="onMoved()" [rotable.disable]="stopRotate">
      <div [@switchImagePedestal]="gameCharacter.currntImageIndex" class="pedestal-inner" [ngClass]="{'not-inventory': !gameCharacter.isInventoryIndicate }"></div>
      <div [@switchImagePedestal]="gameCharacter.currntImageIndex" class="pedestal-outer"></div>
      <div class="pedestal-grab rotate-grab">
        <div class="pedestal-grab-border">
          <div class="material-icons of-front">autorenew</div>
          <div class="material-icons of-back">autorenew</div>
        </div>
      </div>
      <img *ngIf="isDropShadow && (0 < imageFile.url.length || 0 < shadowImageFile.url.length)"
        class="is-pointer-events-none image is-3d"
        [ngStyle]="shadowStyle" [style.height.px]="size * gridSize * heightWidthRatio"
        [src]="((0 < shadowImageFile.url.length) ? shadowImageFile.url : imageFile.url) | safe: 'resourceUrl'"
        [@switchImageShadow]="gameCharacter.currntImageIndex">
      <div class="upright-transform is-fit-width is-3d" [ngClass]="{fall: altitude == 0}" (dblclick)="nextImage()"
        [ngStyle]="{'transform': 'rotateY(90deg) rotateZ(-90deg) rotateY(-90deg) translateY(-50%) translateY(' + (-altitude) * gridSize +'px)'}">
        <div class="rotate-frame is-3d" [style.width.px]="size * gridSize" [style.height.px]="size * gridSize"
          appRotable [(rotable.value)]="roll" (rotable.ondragstart)="onMove()" (rotable.ondragend)="onMoved()"  [rotable.disable]="stopRotate">
          <div class="rotate-inner is-3d">
            <div *ngIf="0 < name.length"
              [ngStyle]="{'transform': 'translateX(-50%) translateX(' + size * gridSize / 2 +'px) rotateX(' + nameTagRotate + 'deg)'}"
              class="name-tag is-nowrap is-black-background is-3d">
              <div class="fornt-tag has-length-limit is-3d">{{name}}</div>
              <div class="back-tag has-length-limit is-3d">{{name}}</div>
            </div>
            <div *ngIf="statusBar1 != null" [ngStyle]="{'width': size * gridSize + 'px'}" class=" status-bar-base is-3d">
              <div class="status-bar"
                [ngStyle]="{'width': size * gridSize * statusBar1 + 'px' , 'background-color': statusColor1}"
              ></div>
            </div>
            <div *ngIf="statusBar2 != null" [ngStyle]="{'width': size * gridSize + 'px'}" class=" status-bar-base is-3d">
              <div class="status-bar"
                [ngStyle]="{'width': size * gridSize * statusBar2 + 'px' , 'background-color': statusColor2}"
              ></div>
            </div>
            <div *ngIf="statusBar3 != null" [ngStyle]="{'width': size * gridSize + 'px'}" class=" status-bar-base is-3d">
              <div class="status-bar"
                [ngStyle]="{'width': size * gridSize * statusBar3 + 'px' , 'background-color': statusColor3}"
              ></div>
            </div>
            <img *ngIf="0 < imageFile.url.length && auraStyle" class="image is-3d aura" [ngStyle]="auraStyle" [src]="imageFile.url | safe: 'resourceUrl'">
            <img #characterImage *ngIf="0 < imageFile.url.length" class="image is-3d frontimage" [ngStyle]="imgStyle" [src]="imageFile.url | safe: 'resourceUrl'"
            [@switchImage]="gameCharacter.currntImageIndex">
            <div class="grab-group">
              <div class="rotate-grab rotate-grab-icon of-top"
                [ngStyle]="{'transform': 'translateX(-50%) translateX(' + size * gridSize / 2 +'px)'}">
                <i class="material-icons">autorenew</i>
              </div>
              <div class="rotate-grab rotate-grab-icon of-bottom"
                [ngStyle]="{'transform': 'translateX(-50%) translateX(' + size * gridSize / 2 +'px'}">
                <i class="material-icons">autorenew</i>
              </div>
            </div>
          </div>
        </div>
        <div #chatBubble class="chat-bubble is-3d"
          [ngStyle]="{'bottom': (chatBubbleAltitude - (isEmote ? gridSize / 2 : 0) + 4) + 'px',
            'transform': 'translateX(-50%) translateX(' + (size * gridSize / 2) +'px) rotateY(' + (rotate + viewRotateZ) + 'deg) rotateX(' + (chatBubbleXDeg) + 'deg)',
            'visibility': gameCharacter.isShowChatBubble ? 'visible' : 'hidden'}">
          <div *ngIf="isListen" [@fadeAndScaleInOut]="isEmote" class="is-3d" style="transform-origin: center bottom;"
            [style.color]=" isEmote ? dialogColor : '#222'"
            [style.text-shadow]="'1px 1px ' + dialogColor">
            <div class="is-3d">
              <p class="is-3d chat-bubble-buyo" [style.min-width.px]="dialogChatBubbleMinWidth" [style.border-color]="dialogColor" style="position: absolute; backface-visibility: hidden"
                [ngClass]="{'emote': isEmote, 'is-whisper': isWhisper}"><span class="face-icon-box"
                  *ngIf="!isEmote && isUseFaceIcon && 0 < dialogFaceIcon?.url.length">
                  <img class="face-icon" [style.border-color]="dialogColor" [src]="dialogFaceIcon.url | safe: 'resourceUrl'">
                </span><span [innerHTML]="dialogText"></span></p>
              <p class="is-3d chat-bubble-buyo back" [style.min-width.px]="dialogChatBubbleMinWidth" [style.border-color]="dialogColor" style="backface-visibility: hidden; transform: rotateY(-180deg)"
                [ngClass]="{'emote': isEmote, 'is-whisper': isWhisper}"><span class="face-icon-box"
                  *ngIf="!isEmote && isUseFaceIcon && 0 < dialogFaceIcon?.url.length">
                  <img class="face-icon" [style.border-color]="dialogColor" [src]="dialogFaceIcon.url | safe: 'resourceUrl'">
                </span><span [innerHTML]="dialogText"></span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="is-3d" *ngIf="isAltitudeIndicate && (altitude <= -0.5 || 0.5 <= altitude)"
      style="display: flex; flex-flow: column nowrap; justify-content: space-between; border-left: 2px dashed black; transform-origin: top left"
      [style.height.px]="math.abs(altitude * gridSize) - (altitude > 0 ? 0 : chatBubbleAltitude + 4)"
      [ngStyle]="{transform: 'translateX(' + (size * gridSize / 2) + 'px) translateY(' + (-size * gridSize / 2) + 'px) rotateX(-90deg) translateY(' + (altitude > 0 ? -altitude * gridSize : 0) + 'px) rotateY(' + (viewRotateZ) + 'deg)'}">
      <div class="is-3d" [style.visibility]="altitude > 0 ? 'visible' : 'hidden'">
        <span class="elevation-indicator is-3d" style="position: absolute"><i>{{ elevation }}</i></span>
        <span class="elevation-indicator is-3d" style="position: absolute; transform: rotateY(-180deg)"><i>{{ elevation }}</i></span>
      </div>
      <div class="is-3d">
        <span class="altitude-indicator is-3d" style="position: absolute">{{ math.abs(altitude) }}</span>
        <span class="altitude-indicator is-3d" style="position: absolute; transform: rotateY(-180deg)">{{ math.abs(altitude) }}</span>
      </div>
      <div class="is-3d" [style.visibility]="altitude < 0 ? 'visible' : 'hidden'">
        <span class="elevation-indicator is-3d" style="position: absolute"><i>{{ elevation }}</i></span>
        <span class="elevation-indicator is-3d" style="position: absolute; transform: rotateY(-180deg)"><i>{{ elevation }}</i></span>
      </div>
    </div>
  </div>
</div>

