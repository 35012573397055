<div class="dummy-will-change" appMovable [movable.option]="movableOption">
</div>
<div class="component is-3d is-grab is-pointer-events-none" [style.width.px]="size * gridSize" [style.height.px]="size * gridSize"
  appMovable [movable.option]="movableOption" (movable.ondragstart)="onMove()" (movable.ondragend)="onMoved()">
  <div class="component-content is-3d" [@bounceInOut]="'in'">
    <div appRotable [rotable.option]="rotableOption" (rotable.ondragstart)="onMove()" (rotable.ondragend)="onMoved()"
      class="component-content is-3d is-pointer-events-auto">
      <div class="pedestal-inner" [ngClass]="{'is-gray-border': hasOwner}"></div>
      <div class="pedestal-outer"></div>
      <ng-container *ngIf="!isIconHidden">
      <div class="pedestal-grab rotate-grab is-3d">
        <div class="pedestal-grab-border">
          <div class="material-icons of-front">autorenew</div>
          <div class="material-icons of-back">autorenew</div>
        </div>
      </div>
      </ng-container>
      <div class="component-content is-3d">
        <div class="is-fit-width is-3d upright-transform">
          <div class="rotate-frame is-3d" [style.width.px]="size * gridSize" [style.height.px]="size * gridSize">
            <div class="rotate-inner is-3d">
              <div class="is-3d" [@changeFaceNameTag]="isVisible ? face : null" [@diceRollNameTag]="animeState" (@diceRollNameTag.done)="animationShuffleDone($event)" style="transform-origin: center bottom;">
                <div *ngIf="0 < name.length" class="name-tag is-nowrap is-black-background is-3d" [style.transfrom]="'translateX(-50%) translateX(' + size * gridSize / 2 +'px) rotateX(' + nameTagRotate + 'deg)'">
                  <div class="fornt-tag is-3d"><i *ngIf="isLock && hasOwner" class="material-icons lock-icon">lock</i>{{name}}:<span *ngIf="isVisible">{{face}}</span><span *ngIf="!isVisible">???</span></div>
                  <div class="back-tag is-3d"><i *ngIf="isLock && hasOwner" class="material-icons lock-icon">lock</i>{{name}}:<span *ngIf="isVisible">{{face}}</span><span *ngIf="!isVisible">???</span></div>
                </div>
              </div>
              <div *ngIf="hasOwner && 0 < ownerName.length" class="owner-tag is-nowrap is-black-background is-3d" [ngStyle]="{'background-color': ownerColor + 'dd', 'transform': 'translateX(-50%) translateX(' + size * gridSize / 2 +'px) rotateX(' + nameTagRotate + 'deg)'}">
                <div class="fornt-tag is-3d" style="color: #222" ><b>{{ownerName}}</b></div>
                <div class="back-tag is-3d" style="color: #222" ><b>{{ownerName}}</b></div>
              </div>
              <div class="component-content is-3d"
                [@changeFace]="!isCoin && isVisible ? face : null" [@changeFaceCoin]="isCoin && isVisible ? (face == '裏' ? 0 : 1) : null"
                [@diceRoll]="!isCoin && animeState" [@coinFlip]="isCoin && animeState"
                (@diceRoll.done)="animationShuffleDone($event)" (@coinFlip.done)="animationShuffleDone($event)" [style.transform]="isCoin ? 'rotateX(30deg)' : ''">
                  <img class="image front-face is-3d" *ngIf="0 < imageFile.url.length" [src]="imageFile.url | safe: 'resourceUrl'" [ngClass]="{'is-black-mask': !isVisible && !canTransparent, 'is-harf-black-mask': isMine || canTransparent }">
                  <img class="image back-face is-3d" *ngIf="0 < backFaceImageFile.url.length" [src]="backFaceImageFile.url | safe: 'resourceUrl'" [ngClass]="{'is-black-mask': !isVisible && !canTransparent, 'is-harf-black-mask': isMine || canTransparent || (isVisible && isCoin)}" style="transform: rotateY(-180deg)">
              </div>
            </div>
          </div>
        </div>
        <div class="is-3d" style="position: relative; top: -20%;" [style.width.px]="size * gridSize" [style.height.px]="size * gridSize"
          [@changeFace]="!isCoin && isVisible ? face : null" [@changeFaceCoin]="isCoin && isVisible ? (face == '表' ? 1 : 2) : null"
          [@diceRoll]="animeState"
          (@diceRoll.done)="animationShuffleDone($event)">
          <img *ngIf="!isFlip && isDropShadow && 0 < imageFile.url.length" class="image shadow is-3d" [src]="imageFile.url | safe: 'resourceUrl'" style="opacity: 0.7; filter: brightness(0) blur(1px)">
          <img *ngIf="isFlip && isDropShadow && 0 < backFaceImageFile.url.length" class="image shadow is-3d" [src]="backFaceImageFile.url | safe: 'resourceUrl'" style="opacity: 0.7; filter: brightness(0) blur(1px)" [style.transform]="isCoin ? 'scaleX(-1)' : 'scaleX(-1) scaleY(-1)'">
        </div>
      </div>
    </div>
  </div>
</div>
