<div class="container">
  <div class="title">画像のアップロード・設定(ファイル→画像)</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/image1.png">
    </div>
    <div class="description">
      画像は1ファイル2MByteまで、ルーム一つに付きサーバで許可された容量までアップロード可能です。<br/>
      容量を超えるとアップロードできなくなります。<br/>
      <br/>
      アップロードした画像は自分のものしか表示されません。<br/>
      ルームマスターのみ全ての画像を確認する事が可能です。<br/>
      <br/>
      画像はタグを付けて管理することが可能です。<br/>
      タグは全プレイヤー共通です。<br/>
    </div>
  </div>
  <div class="title">画像の公開</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/image2.png">
    </div>
    <div class="description">
      画像を選択して、灰色の画像追加ボタンを押せば画像を全プレイヤーに公開できます。<br/>
      ただし、公開後に参加したプレイヤーは見ることができません。
    </div>
  </div>
  <div class="title">画像の削除</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/image2.png">
    </div>
    <div class="description">
      画像を選択して、赤いゴミ箱のボタンを押せば画像を削除可能です。<br/>
      もし複数のプレイヤーが同じ画像をアップロードしていた場合、自分のみ一覧に表示されなくなります。<br/>
      ルームマスターが画像を削除する場合、画像は完全に削除されます。<br/>
    </div>
  </div>
  <div class="title">音楽の再生(ルーム→音楽)</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/audio1.png">
    </div>
    <div class="description">
      再生したい音楽をクリックして再生ボタンを押すことで再生可能です<br>
      <br/>
      再生方法は以下の3種類があり、それぞれ1ファイルのみ同時に再生できます。<br/>
      試聴：自分だけ再生されます。<br/>
      BGM：全員に再生され、ループします。<br/>
      SE：全員に再生され、ループしません。<br/>
      <br/>
      音量は上部のパネルで自分の再生音量を調整できます。<br/>
      また、別途音楽個別に再生音量を調整できます。これは全プレイヤー共通です。<br/>
    </div>
  </div>
  <div class="title">音楽のアップロード(ルーム→音楽→ファイルの追加)</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/audio2.png">
    </div>
    <div class="description">
      アップロードはファイルのアップロードと外部URLの登録の2種類あります。<br/>
      <br/>
      ファイルをアップロードする場合、<br/>
      音楽は1ファイル10MByteまで、ルーム一つに付きサーバで許可された容量までアップロード可能です。<br/>
      容量を超えるとアップロードできなくなります。<br/>
      音楽ファイルはルームに誰もいなくなると削除されます。<br/>
      <br/>
      外部URLを登録する場合、<br/>
      ファイルサイズの指定・容量の上限はなく、ルームに誰もいなくなっても削除されません。<br/>
      ただし、ファイルの設置場所が他のURLからアクセスを受け付けるように設定されている必要があります。<br/>
      (CORS設定)<br/>
      <br/>
       クラウドストレージサービスではdropboxで動作確認がとれています。<br/>
       (URLを書き換える必要あり)<br/>
    </div>
  </div>
  <div class="title">音楽の編集(ルーム→音楽→音楽を選択→編集)</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/audio3.png">
    </div>
    <div class="description">
      音楽ファイルを編集することが可能です。<br/>
      <br/>
      ・名前<br/>
      表示名を変更します。<br/>
      ・ボリューム<br/>
      個別に再生音量を調整できます。これは全プレイヤー共通です。<br/>
      ・タグ<br/>
      音楽にタグをつけて管理可能です。<br/>
      編集中にタグを選ぶと、選んだタグが自動で入力されます。<br/>
      ・削除<br/>
      アップロードした音楽を削除します。<br/>
    </div>
  </div>
  <div class="title">音楽のインポート</div>
  <div class="section">
    <div class="image">
    </div>
    <div class="description">
      ルーム管理者がルームデータを全部保存するときのみ、<br/>
      外部URLから登録された音楽リストを保存することが可能です。<br/>
      <br/>
      またそれとは別にどどんとふで保存された.sav形式のファイルから、<br/>
      「カットイン名」「URL」を抽出してインポート可能です。<br/>
      .tgz形式はそのままではインポートできないので、<br/>
      ファイルを解凍して中から拡張子が.savのファイルを取り出してください。<br/>
      <br/>
      インポートする際には、ユドナイトの保存形式「audio.json」<br/>
      またはどどんとふの保存形式「～.sav」のファイルをユドナイトにドラッグ＆ドロップしてください。<br/>
    </div>
  </div>
</div>
