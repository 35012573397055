<div [class]="bgColor">
  <div class="message"
    [style.border-left-color]="chatMessage.isSystem && !(chatMessage.isDicebot || chatMessage.isCalculate) ? '#444444' : chatMessage.color"
    [ngClass]="{'isSelect': isSelected , 'is-black': isBlack, 'direct-message': (chatMessage.isDirect || chatMessage.isSecret), 'system-message':chatMessage.isSystem, 'dicebot-message': (chatMessage.isDicebot || chatMessage.isCalculate)}"
    [@flyInOut]>
    <div class="image">
      <img *ngIf="0 < imageFile?.url?.length && auraStyle" class="image aura" [ngStyle]="auraStyle" [src]="imageFile.url | safe: 'resourceUrl'">
      <img class="image front-image" *ngIf="0 < imageFile?.url?.length" [src]="imageFile?.url | safe: 'resourceUrl'" [ngStyle]="imgStyle" />
    </div>
    <div class="body">
      <div class="title">
        <span class="msg-name" [style.color]="!chatMessage.isSpecialColor && chatMessage.color && chatMessage.color != '#ffffff' ? chatMessage.color : ''">{{chatMessage.name}}</span>
        <span class='tip msg-from'>　{{chatMessage.from}}</span>
        <span class='tip msg-timestamp'>　{{chatMessage.timestamp | date:'y/M/d H:mm'}}</span>
      </div>
      <div class="text msg-text" [style.font-size.px]="localFontsize">
        <ng-container *ngIf="!chatMessage.isSecret || chatMessage.isSendFromSelf; then visible; else secret"></ng-container>
      </div>
    </div>
    <div *ngIf="isSelected" class="sticky-note">
      <button [ngClass]="{'pushed': mySticky}" (click)="toggleMySticky($event)">
        <i *ngIf="!mySticky" class="material-icons" style="font-size: 12px;">
          bookmark
        </i>
        <i *ngIf="mySticky" class="material-icons" style="font-size: 12px;">
          close
        </i>
        自分
      </button>
      <button [ngClass]="{'pushed': shareSticky}" (click)="toggleShareSticky($event)">
        <i *ngIf="!shareSticky" class="material-icons" style="font-size: 12px;">
          bookmark
        </i>
        <i *ngIf="shareSticky" class="material-icons" style="font-size: 12px;">
          close
        </i>
        共有
      </button>
    </div>
    <div *ngIf="isSelected && isStickyNote" class="sticky-note-jump">
      <button (click)="jumpTab($event)">
        ジャンプ
      </button>
    </div>
  </div>
</div>
<ng-template #visible>
  <span [style.color]="!chatMessage.isSpecialColor && chatMessage.color && chatMessage.color != '#ffffff' ? chatMessage.color : ''"
    [innerHTML]="chatMessage.escapeHtmlAndRuby"></span>
  <span *ngIf="chatMessage.tags.includes('edit')" class="edited" [ngClass]="{'is-black': isBlack}" [style.font-size.px]="localFontsize - 2">
    (<i class="material-icons" [style.font-size.px]="localFontsize - 2">create</i>
    編集済み)
  </span>
  <button *ngIf="chatMessage.isSecret" (click)="discloseMessage()">結果を公開</button>
</ng-template>
<ng-template #secret>
  <span>（シークレットダイス）</span>
</ng-template>

