<div class="container">
  <div class="title">カットイン設定</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/cutin1.png">
    </div>
    <div class="description">
      カットインの名前、表示時間、位置と幅と高さ（それぞれ画面サイズに対する相対指定）、<br/>
      チャット送信時にカットインが表示される条件を設定できます。<br/>
      また、動画を再生する場合および「見切れ防止」にチェックを入れた場合、<br/>
      画面内に収まるように位置とサイズが調整されます。<br/>
      <br/>
      横位置（PosX）と縦位置（PosY）は、画面の左上隅から<br/>
      カットインの中心位置までの距離となります。<br/>
      サイズの幅（Width）と高さ（Height）のどちらかを0とした場合、<br/>
      元画像の縦横比を保って拡大縮小します。<br/>
      （ただし、カットインの最小幅、高さは250ピクセルとなります）。<br/>
      <br/>
      動画を再生するカットインは必ず前面、<br/>
      その他は後から表示されるカットイン画像がより前面になりますが、<br/>
      重なり順（Z-Index）を指定することで制御可能です。<br/>
      <br/>
      アップロードされた音楽ファイルをカットイン表示時の効果音として設定できます。<br/>
      音量にはジュークボックスの設定<br/>
      （「テスト (自分だけ見る)」の場合は試聴音量）が使用されます。<br/>
      表示時間や手動操作によってカットインが停止した際には、途中であっても音声も停止します。<br/>
      カットインや部屋のセーブデータ（zip）には音楽ファイルは含まれませんので、<br/>
      必要でしたら別途アップロードしてください。<br/>
      （カットインと音楽ファイルのリンクはファイルの内容によります、<br/>
      同名の別ファイルをアップロードしても再リンクされません）<br/>
      <br/>
      カットインに動画を使用する場合、URLは現在YouTubeのもののみ有効です。<br/>
      動画を利用する際は権利者およびYouTubeの定めた利用規約を参照し、順守してください。<br/>
    </div>
  </div>
  <div class="title">カットイン再生</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/cutin2.png">
    </div>
    <div class="description">
      チャットテキストに指定したタグが含まれていればカットインが再生されます。<br/> 
      <br/>
      同じカットイン、動画を再生するカットイン、同じタグが指定されたカットインを再生する場合は、<br/>
      以前のものは停止します。<br/>
      また、チャット末尾条件を満たすカットインが複数ある場合、<br/>
      以下の条件に従って再生されます。<br/>
      <br/>
     ・タグが設定されていないものはすべて<br/>
     ・タグが設定されたものは、同じタグのものの中からランダムに1つ<br/>
     ・動画を再生するカットインは上記の中からランダムに1つを選択<br/>
      <br/>
      カットインはドラッグによって移動可能です<br/>
      （動画を再生するカットインは端をドラッグ）。<br/>
      またダブルクリックで閉じる（自分だけ停止）、<br/>
      右クリックでコンテキストメニューから操作が可能です。<br/>
      （「閉じる」「ウィンドウの背面に表示」「最小化」が可能、<br/>
      動画を再生するカットインは端で受付）。<br/>
    </div>
  </div>
</div>
