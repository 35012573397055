<div *ngIf="!isMine" #cursor class="component is-3d is-transition is-pointer-events-none">
  <div #opacity class="peer-cursor is-3d is-transition will-change" [style.background-color]="color + 'dd'"
  [style.transform]="transform">
    <div class="icon" [style.background-image]="'url(' + iconUrl + ')' | safe: 'style'">
      <div class="name is-center"  [style.background-color]="color + 'dd'">
        <span style="white-space: nowrap">{{name}}</span>
      </div>
    </div>
  </div>
</div>
