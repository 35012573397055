<div class="component">
  <ng-container *ngTemplateOutlet="inventoryTab"></ng-container>
  <ng-container *ngTemplateOutlet="inventoryViewSetting"></ng-container>
  <ng-container *ngTemplateOutlet="inventoryMultiMove"></ng-container>
  <ng-container *ngTemplateOutlet="InitiativeNumber"></ng-container>
  <div *ngIf="getGameObjects(selectTab).length < 1">{{getTabTitle(selectTab)}}インベントリは空です</div>
  <div (contextmenu)="onContextMenu($event, gameObject)" *ngFor="let gameObject of getGameObjects(selectTab); trackBy: trackByGameObject"
    (click)="selectGameObject(gameObject)" [ngClass]="{'box': true, 'selected': (selectedIdentifier === gameObject.identifier)}">
    <ng-container *ngTemplateOutlet="gameObjectTags; context:{ gameObject: gameObject}"></ng-container>
  </div>
  <div *ngIf="selectTab === 'graveyard'" style="padding-top: 6px;">
    <button class="danger" (click)="cleanInventory()" [attr.disabled]="getGameObjects(selectTab).length < 1 ? '' : null">墓場を空にする</button>
  </div>
</div>
<ng-template #inventoryTab>
  <form class="is-sticky-top">
    <div class="tab">
      <label *ngFor="let inventoryType of inventoryTypes">
        <input name="tab" type="radio" value="{{inventoryType}}" ng-control="options" [(ngModel)]="selectTab">
        <div><span style="font-weight: bold;">{{getTabTitle(inventoryType)}}</span> ({{getInventory(inventoryType).length}})</div>
      </label>
    </div>
  </form>
</ng-template>
<ng-template #inventoryViewSetting>
  <ng-container *ngIf="isEdit">
    <div style="font-size: 12px;">
      <div>並び順</div>
      <div>
        <form>
         <input name="sortType" type="radio" value="initiative" [disabled]="initiativeStatus.length < 1" [ngModelOptions]="{ standalone: true }" [(ngModel)]="sortType">
         イニシアティブ
         <input name="sortType" type="radio" value="template" [disabled]="templateStatus.length < 1" [ngModelOptions]="{ standalone: true }" [(ngModel)]="sortType">
         テンプレートから選択
         <input name="sortType" type="radio" value="free" [ngModelOptions]="{ standalone: true }" [(ngModel)]="sortType">
         自由記入
        </form>
      </div>
      <div style="width:180px ;padding-top: 2px;display:inline-block">
        <ng-container [ngSwitch]="sortType">
          <ng-container *ngSwitchCase="'initiative'">
            {{initiativeStatus}}
          </ng-container>
          <ng-container *ngSwitchCase="'template'">
            <select [(ngModel)]="sortTag">
              <option value="name">name</option>
              <option *ngFor="let dataElmName of templateStatus" value="{{dataElmName}}">{{dataElmName}}</option>
            </select>
          </ng-container>
          <ng-container *ngSwitchCase="'free'">
            <input [(ngModel)]="sortTag" placeholder="タグ名" />
          </ng-container>
        </ng-container>
      </div>
      <select *ngIf="!isInitiative" [(ngModel)]="sortOrder">
        <option value="ASC">昇順</option>
        <option value="DESC">降順</option>
      </select>
    </div>
    <div style="font-size: 12px; padding-top: 6px;">表示項目</div>
    <input style="width: 100%; box-sizing: border-box;" [(ngModel)]="dataTag" placeholder="スペース区切りでタグ名 スラッシュで改行 ex.「HP MP / メモ」" />
    <div style="font-size: 12px;">
      ステータスバー(リソースのみ指定できます)<br/>
      1：<input type="color" style="width:2em; height:2em;" [(ngModel)]="color1"> <input style="width: 5em;" [(ngModel)]="bar1" placeholder="タグ名" />
      2：<input type="color" style="width:2em; height:2em;" [(ngModel)]="color2"> <input style="width: 5em;" [(ngModel)]="bar2" placeholder="タグ名" />
      3：<input type="color" style="width:2em; height:2em;" [(ngModel)]="color3"> <input style="width: 5em;" [(ngModel)]="bar3" placeholder="タグ名" />
    </div>
    <div style="padding-top: 6px;">
      <button class="tab-setting small-font" (click)="toggleEdit()"><i class="material-icons small-font">settings</i>完了</button>
    </div>
  </ng-container>
  <ng-container *ngIf="!isEdit">
    <table style="font-size: 0.8em;">
      <tr>
        <th style=" vertical-align:middle;">並び順:</th>
        <td style=" vertical-align:middle;">
          <ng-container *ngIf="!isInitiative">
            <ng-container *ngIf="0 < sortTag.length">{{sortTag}} ({{sortOrderName}})</ng-container>
            <ng-container *ngIf="sortTag.length < 1">-</ng-container>
          </ng-container>
          <ng-container *ngIf="isInitiative">
            イニシアティブ({{sortTag}})
          </ng-container>
        </td>
        <td><button class="tab-setting small-font" (click)="toggleEdit()"><i class="material-icons small-font">settings</i>設定</button></td>
      </tr>
    </table>
  </ng-container>
</ng-template>
<ng-template #inventoryMultiMove>
  <ng-container *ngIf="isMultiMove">
    <div style="font-size: 0.8em;">
      <span style="font-weight: bold">一括移動:</span>
      <button class="tab-setting small-font" (click)="allTabBoxCheck();">
        <ng-container *ngIf="existsMultiMoveSelectedInTab(); else selectAll">全解除<i class="material-icons small-font">remove_done</i></ng-container>
        <ng-template #selectAll>全選択<i class="material-icons small-font">done_all</i></ng-template>
      </button>
      <button class="tab-setting small-font" (click)="onMultiMoveContextMenu()"><i class="material-icons small-font">settings</i>実行</button>
      <button class="tab-setting small-font" (click)="toggleMultiMove()"><i class="material-icons small-font">settings</i>キャンセル</button>
    </div>
  </ng-container>
  <ng-container *ngIf="!isMultiMove">
    <div>
      <button class="tab-setting small-font" (click)="toggleMultiMove()"><i class="material-icons small-font">settings</i>一括移動</button>
    </div>
  </ng-container>
</ng-template>
<ng-template #InitiativeNumber>
  <div>
    <span  style="font-size: 12px;font-weight: bold">
      イニシアティブ修正値設定:
    </span>
    <button class="tab-setting small-font" (click)="toggleInitiativeNumber()"><i class="material-icons small-font">settings</i>
      <ng-container *ngIf="!isInitiativeEdit">
        設定
      </ng-container>
      <ng-container *ngIf="isInitiativeEdit">
        完了
      </ng-container>
    </button>
  </div>
</ng-template>
<ng-template #gameObjectTags let-gameObject="gameObject">
  <div class="inventory-object">
    <div class="object-name">
      <div *ngIf="selectTab === 'table'" class="initiative">
        {{initiative(gameObject)}}
      </div>
      {{gameObject.name}}
      <button style="font-size: 0.8em; padding: 2px 5px;" *ngIf="selectedIdentifier === gameObject.identifier" [disabled]="!gameObject.canView" (click)="onContextMenu($event, gameObject)">
        <i class="material-icons" style="font-size: 1em;">settings</i>
      </button>
    </div>
    <div class="object-tags-box">
      <div class="table-cell"  *ngIf="isMultiMove">
        <input type="checkbox" [checked]="multiMoveTargets.has(gameObject.identifier)" (change)="toggleMultiMoveTarget($event, gameObject)" />
      </div>
      <div class="table-cell">
        <div class="image-box">
          <img *ngIf="gameObject.imageFile.url" class="aura" [ngStyle]="gameObject.auraStyle" [src]="gameObject.imageFile.url | safe: 'resourceUrl'" />
          <img *ngIf="gameObject.imageFile.url" class="front-image" [ngStyle]="gameObject.imgStyle" [src]="gameObject.imageFile.url | safe: 'resourceUrl'" />
        </div>
      </div>
      <ng-container *ngIf="!isInitiativeEdit">
        <ng-container *ngIf="!gameObject.hasOwner || gameObject.isMine || gameObject.canTransparent; else forbidden">
          <ng-container *ngFor="let dataElm of getInventoryTags(gameObject); trackBy: trackByGameObject">
            <ng-container *ngTemplateOutlet="dataElmTag; context:{ dataElm: dataElm}"></ng-container>
          </ng-container>
       </ng-container>
      </ng-container>
      <ng-container *ngIf="isInitiativeEdit">
        <ng-container *ngTemplateOutlet="initiativeNumberSetting; context:{ gameObject: gameObject}"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #dataElmTag let-dataElm="dataElm">
  <ng-container *ngIf="dataElm?.name === newLineString"><br></ng-container>
  <div *ngIf="dataElm && dataElm.name !== newLineString" class="object-tag">
    <ng-container *ngTemplateOutlet="dataElement; context: { dataElm: dataElm }">
    </ng-container>
  </div>
</ng-template>

<ng-template #dataElement let-dataElm="dataElm">
  <ng-container *ngIf="0 < dataElm.children.length; then thenBlock; else elseBlock"></ng-container>
  <ng-template #thenBlock>
    <div class="section">
      <div *ngIf="0 < dataElm.name.trim().length" [attr.title]="dataElm.name">{{dataElm.name}}</div>
      <ng-container *ngFor="let childElm of dataElm.children">
        <ng-container *ngTemplateOutlet="dataElement; context: { dataElm: childElm }"></ng-container>
      </ng-container>
    </div>
  </ng-template>
  <ng-template #elseBlock>
    <data-element [dataElement]="dataElm"></data-element>
  </ng-template>
</ng-template>

<ng-template #forbidden>
  非公開
</ng-template>

<ng-template #initiativeNumberSetting let-gameObject="gameObject">
  <div style="margin-left:8px;">
    イニシアティブ修正値 ：
    <input type="number" style="width:4em;" [(ngModel)]="gameObject.initiative" />
  </div>
</ng-template>

