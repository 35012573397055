<ng-container *ngIf="gameDataElement && !(isHideText && (name == 'text' || name == 'fontsize' || name == 'color'))">
  <ng-container *ngIf="gameDataElement.children.length < 1">
    <div *ngIf="isEdit" class="table-cell table-cell_solid">
      <ng-container *ngTemplateOutlet="editButtons"></ng-container>
      <select (change)="setElementType($event.target.value)" [ngModel]="gameDataElement.type">
        <option value="">通常</option>
        <option value="simpleNumber">数値</option>
        <option value="numberResource">リソース</option>
        <option value="abilityScore">能力値</option>
        <option value="checkProperty">チェック</option>
        <option value="note">ノート</option>
        <option value="url">参照URL</option>
      </select>
    </div>
    <div class="table-cell table-cell_solid">
      <ng-container *ngIf="isCommonValue">
        <div style="font-family: Cambria, Georgia; font-size: 0.9rem;">{{commonTagName}}</div>
      </ng-container>
      <ng-container *ngIf="!isCommonValue">
        <div class="hidden-spacer" style="font-family: Cambria, Georgia; font-size: 0.9rem;">{{name}}</div>
        <input style="width:100%; min-width:10px; font-family: Cambria, Georgia; font-size: 0.9rem;" size="1"
          [(ngModel)]="name" placeholder="Value" />
      </ng-container>
    </div>
    <div class="table-cell">
      <ng-container *ngIf="commonTypeIsNumber">
        <input style="width:90%;" [style]="'font-size: 1.0em; min-width: 90px; width: 90%; --min: 0; --max: ' + value + '; --val:' + currentValue +';' | safe: 'style'"
          [(ngModel)]="value" type="range" class="status-range" title="{{value}}" min="{{name === 'altitude' ? -12 : name === 'fontsize' ? 1 : 0}}" max="{{name === 'fontsize' ? 128 : 12}}" step="{{name === 'fontsize' ? 1 : 0.5}}" />
        <br />
        <input style="width:40%; max-width:8em; text-align:right;" type="number" min="{{name === 'altitude' ? -24 : name === 'fontsize' ? 1 : 0}}" step="{{name === 'fontsize' ? 1 : 0.1}}" size="1" [(ngModel)]="value" placeholder="Value" />
      </ng-container>
      <ng-container *ngIf="!commonTypeIsNumber">
        <ng-container [ngSwitch]="gameDataElement.type">
        <ng-container *ngSwitchCase="'simpleNumber'">
            <!-- size属性がないと小さくならない -->
            <div class="hidden-spacer" style="font-size: 1.0em; font-family: Cambria, Georgia;">{{value}}</div>
            <input type="{{isNum(value) ? 'number' : 'text'}}" style="width:40%; min-width: 3em; max-width:8em; text-align:right" size="1" [(ngModel)]="value" placeholder="Number" />
          </ng-container>
          <ng-container *ngSwitchCase="'numberResource'">
            <input [style]="'font-size: 1.0em; min-width: 90px; width: 90%; --min: 0; --max: ' + value + '; --val:' + currentValue +';' | safe: 'style'"
              [(ngModel)]="currentValue" type="range" class="status-range" title="{{currentValue}}" min="0" max="{{!isNum(value) ? 1000 : value}}" />
            <br>
            <input style="width: 5em; text-align: right;" size="1" type="number" [(ngModel)]="currentValue" placeholder="Value" />
            /
            <input style="width: 5em;" size="1" type="number" [(ngModel)]="value" placeholder="Value" />
          </ng-container>
          <ng-container *ngSwitchCase="'abilityScore'">
            <input style="width:40%; max-width:5em; text-align:right" type="{{isNum(value) ? 'number' : 'text'}}" size="1" [(ngModel)]="value" placeholder="Value">
            <ng-container *ngIf="isEdit">
              <select class="modeifire-type" style="width:40%; max-width:5em" [(ngModel)]="currentValue">
                <option value="">なし</option>
                <option value="div2">÷2</option>
                <option value="div3">÷3 SRS,LHZ</option>
                <option value="div4">÷4</option>
                <option value="div5">÷5</option>
                <option value="div6">÷6 SW</option>
                <option value="div10">÷10</option>
                <option value="DnD3">D&amp;D 3e～</option>
              </select>
            </ng-container>
            <ng-container *ngIf="!isEdit"><span style="font-size: smaller;">{{'(' + (abilityScore >=0 ? '+' : '') + abilityScore + ')'}}</span></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'checkProperty'">
              <input style="width:50%; max-width:4em" type="text" size="1" [(ngModel)]="currentValue" placeholder="Option">
              <input type="checkbox" class="status-check" [(ngModel)]="value" checked="{{value ? 'checked' : ''}}" (change)="value = (value ? name : '')">
            </ng-container>
          <ng-container *ngSwitchCase="'note'">
            <textarea style="width:100%; height:5em; min-width:50px; resize: bose; font-size: 1em;" [(ngModel)]="value" placeholder="Note"></textarea>
          </ng-container>
          <ng-container *ngSwitchCase="'url'">
            <!-- size属性がないと小さくならない -->
            <div class="hidden-spacer" style="font-size: 1.0em; font-family: Cambria, Georgia;">{{value}}</div>
            <span style="white-space: nowrap">
              <input type="url" style="width:calc(100% - 1.6rem); min-width:10px;" size="1" [(ngModel)]="value" placeholder="https://www.example.com" />
              <button style="width: 1.6rem" [attr.disabled]="!stringUtil.validUrl(value) ? '' : null" (click)="stringUtil.validUrl(value) ? openUrl(value) : false"><i class="material-icons" style="font-size: 1.0rem">{{ !stringUtil.validUrl(value) ? 'link_off' : (stringUtil.sameOrigin(value) ? 'link' : 'open_in_new') }}</i></button>
            </span>
          </ng-container>
          <ng-container *ngSwitchCase="'colors'">
            <label class="color-sample" style="padding: 0px 2px 0px 0xp; width: 6.5em" [style.color]="value" [style.background-color]="currentValue"><input type="color" style="width: 1em" [(ngModel)]="value" />{{value}}</label>
            <label class="color-sample" style="padding: 0px 2px 0px 0xp; width: 7.5em" [style.color]="value" [style.background-color]="currentValue"><input type="color" style="width: 1em" [(ngModel)]="currentValue" />({{currentValue}})</label>
          </ng-container>
          <ng-container *ngSwitchCase="'color'">
            <label class="color-sample" style="padding: 0px 2px 0px 0xp; width: 6.5em" [style.color]="value"><input type="color" style="width: 1em" [(ngModel)]="value" />{{value}}</label>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <!-- size属性がないと小さくならない -->
            <div class="hidden-spacer" style="font-size: 1.0em; font-family: Cambria, Georgia;">{{value}}</div>
            <input style="width:100%; min-width:10px;" size="1" [(ngModel)]="value" placeholder="" />
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="0 < gameDataElement.children.length">
    <div *ngIf="isEdit" class="table-cell table-cell_solid" style="vertical-align: top;">
      <ng-container *ngTemplateOutlet="editButtons"></ng-container>
    </div>
    <div class="table-cell table-cell_inner-table" style="padding:0; width:0;"></div>
    <div class="table-cell_inner-table">
      <div class="table-cell_inner-table-title">
        <ng-container *ngIf="isCommonValue">
          <div style="padding: 2px; font-family: Cambria, Georgia; font-size: 1.0rem; font-weight: bold;">{{name}}</div>
        </ng-container>
        <ng-container *ngIf="!isCommonValue">
          <input style="width:100%; min-width:10px; font-family: Cambria, Georgia; font-size: 1.0rem; font-weight: bold;"
            size="1" [(ngModel)]="name" placeholder="Value" />
        </ng-container>
      </div>
      <div class="table" *ngIf="0 < gameDataElement.children.length">
        <div game-data-element class="table-row" *ngFor="let childElement of gameDataElement.children"
          [gameDataElement]="childElement" [tableTopObjectName]="tableTopObjectName" [characterIdentifier]="characterIdentifier" [isEdit]="isEdit" [isCommonValue]="isCommonValue" [isHideText]="isHideText"></div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #editButtons>
    <button class="element-button element-icon" [attr.data-tooltip]="'要素を追加'" (click)="addElement()"><i class="material-icons">add_circle</i></button>
    <button class="element-button element-icon" [attr.data-tooltip]="'要素を上に移動'" (click)="upElement()"><i class="material-icons">arrow_upward</i></button>
    <button *ngIf="characterIdentifier.length > 0"  class="element-button element-icon" [attr.data-tooltip]="'要素をリソースに変更'" (click)="changeResource()"><i class="material-icons">token</i></button>
  <br>
    <button class="element-button element-icon"  [attr.data-tooltip]="'要素を削除'" (click)="deleteElement()"><i class="material-icons">remove_circle_outline</i></button>
    <button class="element-button element-icon" [attr.data-tooltip]="'要素を下に移動'"  (click)="downElement()"><i class="material-icons">arrow_downward</i></button>
</ng-template>
