<label>
  <div class="drop-zone" *ngIf="!roomService.disableImageLoad">
    <div>
      <i class="material-icons large-font">add_photo_alternate</i>
    </div>
    <div>ここに画像をドロップ</div>
    <div class="small-font">またはここをクリックして選択</div>
    <input type="file" style="display: none;" (change)="handleFileSelect($event)" multiple accept="image/*"/>
    <div class="small-font">
      <br>１ファイルにつき2MBまで</div>
  </div>
</label>
<div class="sticky-top">
  <div *ngIf="roomService.adminAuth" style="float: right; margin-top: 3px; margin-right: 3px">
    <label sytle="white-space: nowrap">
      <input name="show-allimages" type="checkbox" value="Show Hides" checked="{{isShowAllImages ? 'checked' : ''}}" (click)="onShowAllImages($event)">
      <i class="material-icons" style="font-size: smaller" [ngClass]="{'red-eye': isShowAllImages}">{{ isShowAllImages ? 'visibility' : 'visibility_off' }}</i>
    </label>
  </div>
  <span class="word-selecter">
    <label>
      <input name="word-selecter" type="checkbox" value="ALL" [checked]="showType == 'ALL'" (click)="onAll()">
      <div class="all-tags"><b>ALL</b><badge class="badge" [count]="images.length" [@.disabled]="true"></badge></div>
    </label>
    <label>
      <input name="word-selecter" type="checkbox" value="No Tag" [checked]="showType == 'No Tag'"  (click)="onNoTag()">
      <div class="no-tag"><s>No Tag</s><badge *ngIf="countImagesHasWord(null) > 0" class="badge" [count]="countImagesHasWord(null)" [@.disabled]="true"></badge></div>
    </label>
    <label *ngFor="let tag of allTags">
      <input name="word-selecter" type="checkbox" value="{{tag}}" [checked]="showType == 'Tag' && searchTags.includes(tag)" (click)="onTagSelect(tag)">
      <div>{{tag}}<badge *ngIf="countImagesHasWord(tag) > 0" class="badge" [count]="countImagesHasWord(tag)" [@.disabled]="true"></badge></div>
    </label>
  </span>
  <span style="white-space: nowrap">
    <select [(ngModel)]="serchCondIsOr" style="margin-left: 3px">
      <option value="true">いずれか (OR)</option>
      <option value="">すべて (AND)</option>
    </select>
  </span>
</div>
<div id="file-list" [ngClass]="{ selecter: isSelected }" style="padding-top: 3px;">
  <div *ngFor="let file of images; trackBy: identify" class="image" @scaleInOut [ngClass]="{ selected: selected(file) }" style="text-align: center; min-width: 100px; height: 120px; position: relative;">
    <div style="position: absolute; font-size: xx-small; text-align: left; bottom: 0px; width: 100%; height: 100%; overflow: hidden; pointer-events: none">
      <ng-container *ngIf="file.tag.length > 0; then tagsBlock; else noTagBlock"></ng-container>
      <ng-template #tagsBlock>
        <div style="position: absolute; bottom: 0px; z-index: 5">
          <ng-container *ngFor="let tag of file.tag; index as i">
            <label *ngIf="i < 6" class="word-tag grow" style="display: block; width: 88px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; pointer-events: none">{{ tag }}</label>
            <span *ngIf="i == 6" class="grow" style="display: block; width: 88px; overflow: hidden; padding-left: 2px; font-weight: bold; text-overflow: ellipsis; white-space: nowrap; pointer-events: none">…</span>
          </ng-container>
        </div>
      </ng-template>
      <ng-template #noTagBlock>
        <div style="position: absolute; bottom: 0px; z-index: 5">
          <s class="grow" style="color:gray; display: block; padding-left: 2px; font-weight: bold; text-overflow: ellipsis; white-space: nowrap; pointer-events: none">No Tag</s>
        </div>
      </ng-template>
    </div>
    <img [src]="file.url | safe: 'resourceUrl'" height="120" (click)="onSelectedFile(file)">
  </div>
</div>
<div *ngIf="isSelected" class="sticky-bottom" @fadeAndUpInOut>
  <div>
    <button style="font-size: x-small; margin-right: 2em" (click)="onUnselect()">選択解除 ({{ selectedImageFiles.length }} 選択中)</button>
    <input attr.list="{{panelId}}_suggest" type="text" [(ngModel)]="addingTagWord" style="width: 14em" placeholder="スペース 区切りで 複数可能"><button style="font-size: x-small" [disabled]="addingTagWord == null || addingTagWord.trim() === ''" (click)="addTagWord()">タグ追加 ➡</button>
    <ng-container *ngIf="selectedImagesOwnWords().length > 0">
      <label *ngFor="let word of selectedImagesOwnWords()" class="word-tag" (click)="removeTagWord(word)">{{ word }}<sup style="font-size: xx-small; color: red">×</sup></label>
    </ng-container>
    <ng-container *ngIf="selectedImagesOwnWords().length == 0">
      <span style="font-size: smaller; font-weight: bolder; margin-left: 3px; color: gray"><s>No Tag</s></span>
    </ng-container>
  </div>
  <div>
    <button class="share tooltip" [attr.data-tooltip]="'画像を全員に公開'" (click)="imageShare()"><i class="material-icons">add_photo_alternate</i></button>
    <button class="remove tooltip" [attr.data-tooltip]="'画像の削除'" (click)="remove()"><i class="material-icons">delete</i></button>
  </div>
</div>
