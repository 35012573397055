<div class="box">
  <div class="box-item">プレイ中に使用するプレイヤー情報を設定します。</div>
  <div class="box-item">
    <form name="playerType">
      <input name="playerType" type="radio"  value="NEW" [(ngModel)]="playerType" >新規プレイヤー
      <input name="playerType" type="radio"  value="SAVE" [(ngModel)]="playerType" >既存のプレイヤー
    </form>
  </div>
  <div class="box-item">
    <div *ngIf="playerType == 'NEW' ;else savedPlayer" class="player">
      <div class="flex-item">
        <div class="item-name">
          プレイヤーアイコン
        </div>
        <div class="item-data">
          <label class="icon">
            <img class="image covor" [src]="imageBlob | safe: 'resourceUrl'" />
            <input type="file" name="icondata" (change)="changeIcon($event)" style="display: none;" accept="image/*">
          </label>
        </div>
      </div>
      <div class="flex-item">
        <div class="item-name">
          あなたの色
        </div>
        <div class="item-data">
          <input type="color" style="width:2em; height:2.5em;" [(ngModel)]="color">
        </div>
      </div>
      <div class="flex-item">
        <div class="item-name">
          ニックネーム
        </div>
        <div class="item-data">
          <input [(ngModel)]="playerName" [style.color]="color == '#444444' ? '#000000' : color" placeholder="ニックネーム" />
        </div>
      </div>
      <div class="flex-item">
        <div class="item-name">
          パスワード
        </div>
        <div class="item-data">
          <input [(ngModel)]="password" placeholder="空ならパスワードなし"/>
        </div>
      </div>
      <div class="flex-item">
        <div class="item-name">
          パスワードを保存
        </div>
        <div class="item-data">
          <div class="toggle"><label>
            <input name="savePW" type="checkbox" [(ngModel)]="savePW">
            <div><div></div></div>
          </label></div>
        </div>
      </div>
    </div>
  </div>
  <div  class="box-item">
    <button (click)="login()" [disabled]="!canLogin">ルームに入室</button>
  </div>
</div>

<ng-template #savedPlayer >
  <div *ngIf="allPlayers.length > 0 ;else noPlayer" class="player">
    <div class="flex-item">
      <div class="item-name">
        プレイヤーを選択
      </div>
      <div class="item-data">
        <select name="saved" style="width:10em"  [ngStyle]="{'color': selectedPlayer.color }" [(ngModel)]="selectedPlayer" >
          <option *ngFor="let player of allPlayers" value="{{player.identifier}}" [style.color]="player.color" style="font-weight: bolder;">
            <span *ngIf="player != myPlayer ;else self">{{player.name}}</span> 
          </option>
        </select>
      </div>
    </div>
    <div class="flex-item" *ngIf="needAuth">
      <div class="item-name">
        パスワード
      </div>
      <div class="item-data" *ngIf="!authSuccess ;else Success">
        <input [(ngModel)]="password"/><span style="color: red;font-size: 12px;">必須！</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noPlayer >
  保存されたプレイヤー情報がありません。<br>
  データの読み込みに時間がかかっている可能性があります。<br>
</ng-template>
<ng-template #self >
  プレイヤーを選択
</ng-template>
<ng-template #Success >
  <div class="item-data">
    認証に成功しました
  </div>
</ng-template>
