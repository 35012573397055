<div class="flex-container">
  <div class="flex-item no-grow">
    <div>
      <button style="font-size: smaller; margin-bottom: 3px" (click)="add()">カットインの追加</button>
    </div>
    <select #cutInSelecter style="width: 12em;" size="15" (change)="onChangeCutIn($event.target.value)">
      <option *ngFor="let cutIn of cutIns" value="{{ cutIn.identifier }}" [style.color]="cutIn.isValidAudio ? '' : 'red'">{{( isPlayingNow(cutIn) ? '▶ ' : '■ ') + (cutIn.isValidAudio ? '' : '⚠️' ) + cutIn.name }}</option>
    </select>
  </div>
  <div class="flex-item">
    <div style="float: right; margin-top: 3px; margin-right: 3px">
      <button (click)="helpCutIn()">?</button>
    </div>
    <ng-container *ngIf="!isEmpty && selectedCutIn">
      <div style="display: flex">
        <div>
          <div (dblclick)="isEditable && (!cutInVideoId || cutInisSoundOnly) ? openModal() : false" style="position: relative; overflow: hidden">
            <div *ngIf="(!cutInVideoId || cutInisSoundOnly) && getHidden(cutInImage)" style="position: absolute; text-align: right; top: 0px; right: 0px; pointer-events: none; z-index: 5">
              <i class="material-icons" [class]="isShowHideImages ? 'red-eye': 'grow'" style="margin-right: 2px; font-size: 16px; display: block">{{ isShowHideImages ? 'visibility' : 'visibility_off' }}</i>
            </div>
            <div *ngIf="isEditable" style="position: absolute; top: 1px; left: -1px; z-index: 5">
              <button *ngIf="!cutInVideoId || cutInisSoundOnly" (click)="openModal()"><b>画像変更</b></button>
            </div>
            <div *ngIf="cutInVideoId && !cutInisSoundOnly" class="grow" style="opacity: 0.8; position: absolute; bottom: 1px; right: -1px; z-index: 5"><i style="font-size: 32px" class="material-icons">{{ cutInPlayListId ? 'featured_play_list' : 'movie' }}</i></div>
            <img style="width: 160px; height: 160px; object-fit: contain" [ngClass]="{ blackout: (!cutInVideoId || cutInisSoundOnly) && !isShowHideImages && getHidden(cutInImage) }" [src]="cutInImageUrl | safe: 'resourceUrl'">
          </div>
        </div>
        <div style="display: flex; flex-direction: column; width: 100%">
          <div style="padding-bottom: 2px">
            <div style="float: right">
              <label sytle="white-space: nowrap">
                <input name="show-hide-images" type="checkbox" value="Show Hides" checked="{{isShowHideImages ? 'checked' : ''}}" (click)="onShowHiddenImages($event)">
                <i class="material-icons" style="font-size: smaller" [ngClass]="{'red-eye': isShowHideImages}">{{ isShowHideImages ? 'visibility' : 'visibility_off' }}</i>
              </label>
            </div>
          </div>
          <div style="padding-left: 2px">
            <b style="font-size: smaller">Name: </b><input type="text" style="width: 8em" placeholder="Name" [(ngModel)]="cutInName" [attr.disabled]="!isEditable ? '' : null">
            <b style="font-size: smaller; margin-left: 1em">Tag: </b><input type="text" style="width: 3em" placeholder="Tag" [(ngModel)]="cutInTag" [attr.disabled]="!isEditable ? '' : null">
          </div>
          <div style="padding-left: 2px; padding-top: 2px">
            <b style="font-size: smaller">Duration (0=無制限): </b><input type="number" min="0" max="1000" [(ngModel)]="cutInDuration" style="width: 3.5em" [attr.disabled]="!isEditable || isPlaying ? '' : null"><b style="font-size: smaller; padding-left: 2px">sec.</b>
          </div>
          <div style="padding-left: 2px">
            <b style="font-size: smaller">Cut In: </b>
            <select [(ngModel)]="cutInType" style="min-width: 8em" [attr.disabled]="!isEditable ? '' : null">
              <option [value]="-1" [selected]="cutInType == -1">即時出現</option>
              <option [value]="0" [selected]="cutInType == 0">浮き出る</option>
              <option [value]="1" [selected]="cutInType == 1">飛び出す</option>
              <option [value]="2" [selected]="cutInType == 2">開く（左右）</option>
              <option [value]="3" [selected]="cutInType == 3">開く（上下）</option>
            </select>
            <label style="margin-left: 1em; font-size: smaller"><input name="borderStyle" type="checkbox" (click)="borderStyle = borderStyle == 0 ? 1 : 0" [checked]="borderStyle == 1" [attr.disabled]="!isEditable ? '' : null"><b>枠あり</b></label>
          </div>
          <div style="padding-left: 2px">
            <b style="font-size: smaller">チャット末尾: </b><br>
            <textarea style="width: 100%; height: 3em" placeholder="1行に一つ、冒頭@でマッチ時にテキストから切り取り&#13;&#10;@街並み" [(ngModel)]="cutInCond" [attr.disabled]="!isEditable ? '' : null"></textarea>
          </div>
        </div>
      </div>
      <div style="font-size: smaller; display: flex; flex-direction: column; width: 100%">
        <div>
          <button [attr.disabled]="!isEditable ? '' : null" (click)="testCutIn()"><b>テスト (自分だけ見る)<sup class="self-only">*</sup></b></button>
          <span style="margin-left: 2em">
            <button style="margin-right: 0px" [attr.disabled]="!isEditable ? '' : null" (click)="playCutIn()"><b><i class="material-icons" style="font-size: smaller">play_arrow</i>再生</b></button>
            <button style="margin-left: 0px" [attr.disabled]="!isEditable ? '' : null" (click)="stopCutIn()"><b><i class="material-icons" style="font-size: smaller">stop</i>停止</b></button>
            <span style="white-space: nowrap"> ➡
              <select style="max-width: 10em" [(ngModel)]="sendTo" [ngClass]="{'not-specified': sendTo == ''}" [ngStyle]="{'color': (sendTo == '' ? null : sendToColor), 'font-weight': (sendTo == '' ? 'normal' : 'bolder')}">
                <option value="" class="not-specified">全員</option>
                <option *ngFor="let peer of otherPeers" value="{{peer.identifier}}" [style.color]="peer.color" style="font-weight: bolder;">{{peer.name}}
                  <ng-container *ngIf="peer === myPeer">（あなた）</ng-container>
                </option>
              </select>
            </span>
          </span>
        </div>
        <div class="section">
          <div>
            <b class="header">位置</b>
            <label style="margin-left: 0.6em"><input name="cutInIsPreventOutBounds" type="checkbox" [(ngModel)]="cutInIsPreventOutBounds" [checked]="cutInIsPreventOutBounds" [value]="true" [attr.disabled]="!isEditable || cutInVideoId ? '' : null"><b>見切れ防止</b></label>
          </div>
          <div style="padding-left: 1em">
            <b>PosX: </b><input [(ngModel)]="cutInPosX" type="range" title="{{cutInPosX}}" min="{{minSize}}" max="{{maxSize}}" style="width: 180px" name="cutInPosX" [attr.disabled]="!isEditable ? '' : null" />
            <input [(ngModel)]="cutInPosX" type="number" min="{{minSize}}" max="{{maxSize}}" style="width: 3.5em" [attr.disabled]="!isEditable ? '' : null"><b style="padding-left: 2px">%</b>
          </div>
          <div style="padding-left: 1em">
            <b>PosY: </b><input [(ngModel)]="cutInPosY" type="range" title="{{cutInPosY}}" min="{{minSize}}" max="{{maxSize}}" style="width: 180px" name="cutInPosY" [attr.disabled]="!isEditable ? '' : null" />
            <input [(ngModel)]="cutInPosY" type="number" min="{{minSize}}" max="{{maxSize}}" style="width: 3.5em" [attr.disabled]="!isEditable ? '' : null"><b style="padding-left: 2px">%</b>
          </div>
          <div style="padding-left: 1em">
            <b>Z-Index (大きいものが前): </b><input [(ngModel)]="cutInZIndex" type="number" min="{{-maxSize}}" max="{{maxSize}}" style="width: 3.5em" [attr.disabled]="!isEditable  || cutInVideoId ? '' : null">
            <label style="margin-left: 0.6em"><input name="cutInIsFrontOfStand" type="checkbox" [(ngModel)]="cutInIsFrontOfStand" [checked]="cutInIsFrontOfStand" [value]="true" [attr.disabled]="!isEditable || cutInVideoId ? '' : null"><b>スタンドの前面に表示</b></label>
          </div>
        </div>
        <div class="section">
          <div>
            <b class="header">サイズ（幅、高さ共に0の場合は元画像のまま）</b>
          </div>
          <div style="padding-left: 1em">
            <b>Width (0=指定なし): </b><input [(ngModel)]="cutInWidth" type="range" title="{{cutInWidth}}" min="{{minSize}}" max="{{maxSize}}" style="width: 180px" name="cutInWidth" [attr.disabled]="!isEditable ? '' : null" />
            <input [(ngModel)]="cutInWidth" type="number" min="{{minSize}}" max="{{maxSize * 2}}" style="width: 3.5em" [attr.disabled]="!isEditable ? '' : null"><b style="padding-left: 2px">%</b>
          </div>
          <div style="padding-left: 1em">
            <b>Height (0=指定なし): </b><input [(ngModel)]="cutInHeight" type="range"　title="{{cutInHeight}}" min="{{minSize}}" max="{{maxSize}}" style="width: 180px" name="cutIHeight" [attr.disabled]="!isEditable ? '' : null" />
            <input [(ngModel)]="cutInHeight" type="number" min="{{minSize}}" max="{{maxSize * 2}}" style="width: 3.5em" [attr.disabled]="!isEditable ? '' : null"><b style="padding-left: 2px">%</b>
          </div>
          <div style="padding-left: 1em">
            <b>元画像と縦横比が異なる場合: </b>
            <select [(ngModel)]="objectFitType" [attr.disabled]="(cutInVideoId && !cutInisSoundOnly) || !isEditable ? '' : null">
              <option [value]="0" [selected]="objectFitType == 0">伸縮する</option>
              <option [value]="1" [selected]="objectFitType == 1">切り取る</option>
              <!-- <option [value]="2" [selected]="objectFitType == 2">範囲に収める</option> -->
            </select>
          </div>
        </div>
        <div class="section">
          <div>
            <b class="header">効果音・動画</b>
            <label style="margin-left: 0.6em"><input name="cutInSEIsLoop" type="checkbox" [(ngModel)]="cutInSEIsLoop" [checked]="cutInSEIsLoop" [value]="true" [attr.disabled]="!isEditable || isPlaying ? '' : null"><b>繰り返し</b></label>
          </div>
          <div style="padding-left: 1em">
            <input type="radio" name="{{panelId}}-media" id="{{panelId}}-media-sound" [(ngModel)]="cutInIsVideo" [checked]="!cutInIsVideo" [value]="false" [attr.disabled]="!isEditable || isPlaying ? '' : null">
            <label for="{{panelId}}-media-sound"><b>音楽ファイル: </b></label>
            <select 
              [style.min-width.em]="isValidAudio ? 10 : 5"
              [style.max-width.em]="isValidAudio ? 20 : 5"
              [(ngModel)]="cutInAudioIdentifier" 
              [ngClass]="{'not-specified': cutInAudioIdentifier == ''}" 
              [attr.disabled]="cutInIsVideo || !isEditable || isPlaying ? '' : null"
              (change)="onAudioFileChange($event.target.value)">
              <option value="" class="not-specified">効果音なし</option>
              <option *ngFor="let audio of audios" value="{{audio.identifier}}">{{ audio.name }}</option>
            </select>
            <ng-container *ngIf="isEditable && this.cutInAudioFileName.length > 0">
              <span *ngIf="isValidAudio" style="margin-left: 0.6em"><i class="material-icons" style="font-size: 14px">check</i> <span style="color: blue">Link OK</span></span>
              <span *ngIf="!isValidAudio" style="margin-left: 0.6em; color: red; display:inline-block; white-space: nowrap; max-width: 20em; overflow: hidden; text-overflow: ellipsis"><i class="material-icons"></i><span style="font-weight: bolder">⚠️Link NG:</span> {{ this.cutInAudioFileName }}</span>
            </ng-container>
          </div>
          <div style="padding-left: 1em">
            <input type="radio" name="{{panelId}}-media" id="{{panelId}}-media-video" [(ngModel)]="cutInIsVideo" [checked]="cutInIsVideo" [value]="true" [attr.disabled]="!isEditable || isPlaying ? '' : null">
            <label for="{{panelId}}-media-video"><b>動画URL: </b></label><input type="text" style="width: 24em" [(ngModel)]="cutInVideoURL" [attr.disabled]="!cutInIsVideo || !isEditable || isPlaying ? '' : null">
          </div>
          <div style="padding-left: 2.5em">
            <a href="https://www.youtube.com/terms" target="_blank" class="outer-link" (click)="openYouTubeTerms()">YouTube 利用規約</a>
          </div>
        </div>
      </div>
      <hr/>
      <div>
        <ng-container *ngIf="!isDeleted">
          <button (click)="upTabIndex()">↑</button>
          <button (click)="downTabIndex()">↓</button>
          <div style="float: right">
            <button *ngIf="!roomService.disableSeparateDataSave" [disabled]="isSaveing" (click)="save()">
              <div style="position: relative;">
                <div [ngClass]="{'is-hidden': isSaveing}">保存</div>
                <div *ngIf="isSaveing" class="progress">{{progresPercent}}%</div>
              </div>
            </button>
            <button class="danger" (click)="delete()">削除</button>
          </div>
        </ng-container>
        <ng-container *ngIf="isDeleted && selectedCutInXml">
          <button (click)="restore()">元に戻す</button>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
<ng-container *ngIf="isEmpty">
  <div style="font-size: 12px;">
    ※カットインがありません。
    <ng-container *ngIf="isDeleted && selectedCutInXml">
      <button (click)="restore()">元に戻す</button>
    </ng-container>
  </div>
</ng-container>