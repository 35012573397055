<div class="container">
  <div class="title">チャットウィンドウ</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/chat-window.png">
    </div>
    <div class="description">
      タイトルが「*チャットウィンドウ*」となっている場合、<br/>
      チャットタブ選択の優先権を持つチャットウィンドウです。<br/>
      選択したタブは他のパネルと連携されます。<br/>
      チャットウィンドウでは下記の設定が可能です<br/>
      <ul>
        <li>送信先：<br/>メッセージを個別の宛先に贈ります。</li>
        <li>ダイスボット：<br/>ダイスボットを選択します。</li>
        <li>立ち絵設定：<br/>ゲームキャラクターのみ。コマで使用している画像を立ち絵に使用します。</li>
        <li>立ち絵選択：<br/>ゲームキャラクターのみ。どの立ち絵を使うか選択します。</li>
        <li>立ち絵位置：<br/>ゲームキャラクターのみ。立ち絵の位置を変更します。</li>
        <li>色：<br/>ゲームキャラクターのみ。チャットカラーを変更します。</li>
      </ul>
      <i class="material-icons" style="font-size: 14px;">settings</i>をクリックすることで表示項目を切り替えることが可能です。<br/>
      また、自動スクロールのチェックボックスをOFFにすることで、<br/>
      新しいメッセージが追加されたときに自動スクロールされるのを止めることが可能です。
    </div>
  </div>
  <div class="title">チャット修正</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/chat-edit.png">
    </div>
    <div class="description">
      自分のメッセージをWクリックするとチャットメッセージ編集モードに入ります。<br/>
      enterキーで編集を確定させることができ、escキーで編集をキャンセルします。<br/>
      編集済みのメッセージは(編集済み)と表示されます。
    </div>
  </div>
  <div class="title">チャット設定</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/chat-tab-setting.png">
    </div>
    <div class="description">
      チャットウィンドウ設定<br/>
      自分のチャットウィンドウ個別に対する設定です
      <ul>
        <li>メッセージの背景色：<br/>チャットウィンドウの背景色を変更ます。</li>
        <li>メッセージの文字サイズ：<br/>チャットの文字サイズを変更します</li>
        <li>操作パネル：<br/>チャットウィンドウでのキャラクターステータス表示・イニシアティブリスト・非表示を切り替えます。</li>
        <li>メッセージ簡易表示：<br/>発言者とテキストのみの簡素なチャットログ表示に変更します。</li>
        <li>チャット入力欄表示：<br/>チャット入力欄の表示/非表示を切り替えます。</li>
      </ul>
      チャットタブ設定<br/>
      <ul>
        <li>チャットタブを作る：<br/>チャットタブを新たに作成します。</li>
        <li>立ち絵表示：<br/>チャットタブで立ち絵を表示する/表示しないを切り替えます。</li>
        <li>↑/↓：<br/>チャットタブの表示順を移動します。</li>
        <li>保存：<br/>チャットタブをZIPで保存します。</li>
        <li>チャットログ保存：<br/>チャットタブのログを保存します</li>
        <li>ログのクリア：<br/>ルームマスターのみ。チャットタブのログを削除します。</li>
        <li>削除：<br/>ルームマスターのみ。チャットタブを削除します。</li>
        <li>アクセス権限：<br/>ルームマスターのみ。チャットタブを読込できるプレイヤーを設定します</li>
      </ul>
    </div>
  </div>
  <div class="title">パレットバインダー</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/chat-palette.png">
    </div>
    <div class="description">
      通常のユドナリウムと違い、一つのパネルにチャットパレットが統合されています<br/>
      キャラクターはタブで切り替えます<br/>
      優先権を持つチャットウィンドウのチャットタブ選択と同期します。<br/>
      (ON/OFF可能です)<br/>
      チャットパレット<br/>
      <ul>
        <li>ON/OFF：<br/>チャットパレット部分の表示/非表示を切り替えます。</li>
        <li>編集：<br/>チャットパレットを編集します。</li>
        <li>追加：<br/>ゲームキャラクターのみ。選択したキャラクターのチャットパレットタブを追加します。</li>
        <li>削除：<br/>ゲームキャラクターのみ。選択したチャットパレットタブを削除します。</li>
      </ul>
      キャラクターリスト<br/>
      <ul>
        <li>新規作成(簡易)：<br/>新規キャラクターを作成します。作成したキャラクターは個人インベントリに入ります。</li>
        <li>追加：<br/>ゲームキャラクターをタブに追加します。</li>
        <li>削除：<br/>ゲームキャラクターをタブから削除します。</li>
      </ul>
    </div>
  </div>
  <div class="title">イニシアティブリスト</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/chat-inventory.png">
    </div>
    <div class="description">
      「機能」で表示されるイニシアティブリストと同じです<br/>
    </div>
  </div>
  <div class="title">ステータス管理</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/chat-status.png">
    </div>
    <div class="description">
      キャラクターを選択した場合、<br/>
      インベントリに表示されているステータスと同じものがチャットウィンドウに表示されます<br/>
      ステータスをWクリックすると編集モードになりステータスに対し<br/>+,-,上書きが可能です。
    </div>
  </div>
  <div class="title">リソース管理</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/chat-resource.png">
    </div>
    <div class="description">
      ステータス管理左端のステータスボタンを押すと、消耗リソース管理モードになります<br/>
      表記がリソースに変わり、もう一度押すとステータス管理モードに戻ります<br/>
      <br/>
      表示されたリソースは残り回数が1以上ある限りWクリックで使用できます。<br/>
      使用すると使用したことをチャットに出力します。<br/>
      種類が「リソース」「チェックボックス」のものに限り「使用回数を回復」ボタンで上限まで回数を回復可能です。<br/>
    </div>
  </div>
  <div class="title">ルビ</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/chat-ruby.png">
    </div>
    <div class="description">
      |ルビを振られる文字《ルビ》で文字にルビを降ることが可能です<br/>
      チャット入力欄を右クリックするとテンプレートを入力することが可能です<br/>
      例）｜約束された勝利の剣《エクスカリバー》！
    </div>
  </div>
  <div class="title">入力履歴</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/chat-history.png">
    </div>
    <div class="description">
      Ctrlキー+↑キー・↓キーで過去10件分の入力履歴を表示可能です。<br/>
      Ctrlキー+↑キーで一件分古い履歴に・Ctrlキー+↓キーで一件分新しい履歴に移動します。<br/>
      <br/>
      また、各履歴にはCtrlキー+1~0でショートカットが割り振られており、<br/>
      キーボードの配置に合わせ「1」が10件前の送信履歴、<br/>
      「0」が1件前の送信履歴にそれぞれ対応しています。<br/>
      このショートカットは履歴を表示していなくても使用可能です、<br/>
      <br/>
      履歴はマウスでも操作可能で、チャット入力欄を右クリックして履歴を選ぶと履歴が表示され、<br/>
      各履歴クリックで入力可能です。<br/>
    </div>
  </div>
</div>
