<div style="display: flex; flex-direction: column" class="component" [ngClass]="{'icons-panel': isIconsMenu, 'submenu-panel': isSubmenu, 'is-max-limit': !isSubmenu, 'pointer-events-none': isPointerDragging}"
  (mouseleave)="hideSubMenu()"  #root>
  <div class="title-area" *ngIf="(!isIconsMenu && 0 < title.length) || altitudeHande"><div class="title" [style.color]="titleColor" [style.font-weight]="titleBold ? 'bolder' : 'normal'">{{title}}<span style="font-size: small;" *ngIf="altitudeHande"> (高度 <input type="number" step="0.1" style="font-size: small; width:3.6em; text-align:right" [(ngModel)]="altitudeHande.altitude">)</span></div></div>
  <div style="display: flex; align-items: center">
    <div *ngIf="altitudeHande" #altitudeSliderRuler class="altitude ruler" style="display: flex; align-self: stretch; flex-direction: column; align-content: center"><div style="margin-top: 2em;">高</div><div style="align-self: center; margin-top: auto; margin-bottom: auto">0</div><div style="margin-bottom: 2em;">低</div></div>
    <div *ngIf="altitudeHande" #altitudeSliderRoot class="altitude bar-root"><input #altitudeSlider class="altitude bar" title="{{altitudeHande.altitude}}" [(ngModel)]="altitudeHande.altitude" type="range" max="12" min="-12" step="0.5" orient="vertical"></div>
    <div style="flex-grow: 1">
      <ul>
        <ng-container *ngFor="let action of actions">
          <ng-container *ngIf="action">
            <ng-container *ngTemplateOutlet="action?.type === 'separator' ? separator : actionMenu; context: { action: action }"></ng-container>
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
<ng-template #actionMenu let-action="action">
  <li [ngClass]="{'hasHighlight': !(action.disabled || action.icon || (action.altitudeHande && action.altitudeHande.altitude == 0)), 'disabled': (action.disabled || (action.altitudeHande && action.altitudeHande.altitude == 0))}"
      (click)="(action.disabled || (action.altitudeHande && action.altitudeHande.altitude == 0)) ? false : doAction(action)"
      (mouseenter)="(action.disabled || (action.altitudeHande && action.altitudeHande.altitude == 0)) ? false : showSubMenu(action)">
    <div class="menu-title" [ngClass]="{'icon-menu': action.icon, default: action.default}">
      <span *ngIf="action.error" title="{{action.error}}">⚠️</span>
      <input *ngIf="action.name && action.name.indexOf('☑') == 0" type="checkbox" [disabled]="action.disabled" checked/>
      <input *ngIf="action.name && action.name.indexOf('☐') == 0" type="checkbox" [disabled]="action.disabled" />
      <input *ngIf="action.name && action.name.indexOf('◉') == 0" type="radio" [disabled]="action.disabled" checked />
      <input *ngIf="action.name && action.name.indexOf('○') == 0" type="radio" [disabled]="action.disabled" />
      <span *ngIf="action.name && action.name.indexOf('ブラック') == 2" class="color-indicator" style="color: #000">■</span>
      <span *ngIf="action.name && action.name.indexOf('ブルー') == 2" class="color-indicator" style="color: #00f">■</span>
      <span *ngIf="action.name && action.name.indexOf('グリーン') == 2" class="color-indicator" style="color: #0f0">■</span>
      <span *ngIf="action.name && action.name.indexOf('シアン') == 2" class="color-indicator" style="color: #0ff">■</span>
      <span *ngIf="action.name && action.name.indexOf('レッド') == 2" class="color-indicator" style="color: #f00">■</span>
      <span *ngIf="action.name && action.name.indexOf('マゼンタ') == 2" class="color-indicator" style="color: #f0f">■</span>
      <span *ngIf="action.name && action.name.indexOf('イエロー') == 2" class="color-indicator" style="color: #ff0">■</span>
      <span *ngIf="action.name && action.name.indexOf('ホワイト') == 2" class="color-indicator" style="color: #fff">■</span>
      <span *ngIf="action.materialIcon" style="vertical-align: top"><i style="font-size: 150%; padding-left: 2px;" class="material-icons">{{action.materialIcon}}</i></span>
      {{ action.name.replace('☑', '').replace('☐', '').replace('◉', '').replace('○', '') }}<sup *ngIf="action.selfOnly" class="self-only">*</sup>
      <span *ngIf="action.icon && 0 < action.icon?.url.length" class="imagebox"><img class="image" [src]="action.icon.url | safe: 'resourceUrl'"></span>
      <i *ngIf="action.isOuterLink" class="material-icons" style="font-size: 1rem">open_in_new</i>
    </div>
    <div *ngIf="action.subActions && !action.disabled" class="sub-menu-arrow">
      <i class="material-icons" style="font-size: 1em; vertical-align: bottom;">arrow_forward_ios</i>
    </div>
    <context-menu *ngIf="subMenu && parentMenu === action" [title]="action.name" [actions]="subMenu" [isSubmenu]="true"></context-menu>
  </li>
</ng-template>
<ng-template #separator let-action="action">
  <li>
    <hr class="separator" />
  </li>
</ng-template>
