<div class="flex-box">
  <div class="room-info">
    <div class="title">ルーム基本情報</div>
    <ng-container>
      <div class="flex-item" (dblclick)="toggleEdit()">
        <div class="item-name">
          ルーム名
        </div>
        <div class="item-data">
          <span *ngIf="!isEdit" style="font-weight: bold;">{{roomService.roomData.roomNo}}/{{roomService.roomData.roomName}}</span>
          <input *ngIf="isEdit" [(ngModel)]="roomName" placeholder="空欄だと変更なし" />
        </div>
      </div>
      <div class="flex-item" (dblclick)="toggleEdit()">
        <div class="item-name">
          パスワード
        </div>
        <div class="item-data">
          <span *ngIf="!isEdit">{{roomService.roomData.password}}</span>
          <input *ngIf="isEdit" [(ngModel)]="password"/>
        </div>
      </div>
      <div class="flex-item">
        <div class="item-name">
          ルームマスター
        </div>
        <div class="item-data">
          <span *ngIf="!enableAdmin">無効</span>
          <span *ngIf="enableAdmin">{{adminPlayer}}</span>
        </div>
      </div>
      <div class="flex-item">
        <div class="item-name">
          画像データ使用量
        </div>
        <div class="item-data">
          {{imageSize}} KByte
        </div>
      </div>
      <div class="flex-item">
        <div class="item-name">
          音楽データ使用量
        </div>
        <div class="item-data">
          {{audioSize}} KByte
        </div>
      </div>
      <div class="flex-item">
        <div class="item-name">
          ダイスボット
        </div>
        <div class="item-data">
          <ng-container *ngIf="adminAuth">
            <select *ngIf="diceBotInfosIndexed.length < 1" style="width: 16em;">
              <option value="" class="not-specified">ダイスボット読込中..</option>
            </select>
            <select *ngIf="diceBotInfosIndexed.length > 0" style="width: 16em;" [(ngModel)]="roomService.roomAdmin.gameType" [ngModelOptions]="{standalone: true}">
              <option value="" class="not-specified">ダイスボット指定なし</option>
              <optgroup *ngFor="let group of diceBotInfosIndexed" class="dice-bot-group" label="{{group.index}}">
                <option *ngFor="let diceBotInfo of group.infos" value="{{diceBotInfo.script}}">{{diceBotInfo.game}}</option>
              </optgroup>
            </select>
          </ng-container>
          <ng-container *ngIf="!adminAuth">
            {{currentDicebot}}
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="room-info">
    <div class="title">ルーム設定</div>
    <div class="flex-item">
      <div class="item-name">
        アラーム
      </div>
      <div class="item-data">
        <div>
          <input type="number" style="width:3em" min="0" max="999" step="1" name="alarmTime" [(ngModel)]="alarmMinute"  />分
          <input type="number" style="width:2em" min="0" max="60" step="1" name="alarmTime" [(ngModel)]="alarmSecond"  />秒 後
        </div>
        <div>送信先:
        <select name="sendTo" style="width:10em" [(ngModel)]="sendTo" >
          <option value="" class="not-specified">全員</option>
          <option *ngFor="let peer of otherPeers" value="{{peer.peerId}}" [style.color]="peer.color" style="font-weight: bolder;">{{peer.player.name}}
            <ng-container *ngIf="peer === myPeer">（あなた）</ng-container>
          </option>
        </select>
        <button [disabled]="alarmInterval" style="padding: 0.1em 0.4em;" (click)="alarmSend()">送信</button></div>
      </div>
    </div>
    <div class="flex-item">
      <div class="item-name">
        操作ログ
      </div>
      <div class="item-data">
        <ng-container *ngIf="adminAuth; else systemLog">
          <select style="width: 16em;" [(ngModel)]="roomService.roomAdmin.chatTab" [ngModelOptions]="{standalone: true}">
            <option value="" class="not-specified">ログを出力しない</option>
            <option *ngFor="let chatTab of chatTabs" value="{{chatTab.identifier}}">{{chatTab.name}}</option>
          </select>
          <ng-container *ngIf="roomService.roomAdmin.chatTab">
            <div>出力するデータを指定します</div>
            <div class="toggle"><label>ダイス操作ログ
              <input name="diceLog" type="checkbox" [(ngModel)]="roomService.roomAdmin.diceLog">
              <div><div></div></div>
            </label></div><br>
            <div class="toggle"><label>カード操作ログ
              <input name="cardLog" type="checkbox" [(ngModel)]="roomService.roomAdmin.cardLog">
              <div><div></div></div>
            </label></div><br>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="flex-item">
      <div class="item-name">
        キャラクター<br>テンプレート
      </div>
      <div class="item-data">
        <ng-container  *ngIf="adminAuth; else template">
          <select name="template" style="width: 16em;height: 1.5em;" [(ngModel)]="roomService.roomAdmin.templateCharacter">
            <option *ngFor="let gameCharacter of gameCharacterService.list(false)"
              value="{{gameCharacter.identifier}}">{{gameCharacter.name}}
            </option>
          </select>
        </ng-container>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!adminAuth || roomService.roomAdmin.adminPlayer.length < 1; else editSetting">
    <div class="room-info" >
      <div class="title">ルーム権限-透過モード</div>
      <div>
       現在、下記の制御が有効になっています。
        <ul>
          <li *ngIf="roomService.roomAdmin.transparentMode">透過モード</li>
        </ul>
      </div>
    </div>
    <div class="room-info" >
      <div class="title">ルーム権限-アップロード禁止</div>
      <div>
       現在、下記の制御が有効になっています。
        <ul>
          <li *ngIf="roomService.roomAdmin.disableRoomLoad">ルームデータアップロード禁止</li>
          <li *ngIf="roomService.roomAdmin.disableTabletopLoad">データアップロード禁止(オブジェクト)</li>
          <li *ngIf="roomService.roomAdmin.disableObjectLoad">データアップロード禁止(その他)</li>
          <li *ngIf="roomService.roomAdmin.disableImageLoad">画像アップロード禁止</li>
          <li *ngIf="roomService.roomAdmin.disableAudioLoad">音楽アップロード禁止</li>
        </ul>
      </div>
    </div>
    <div class="room-info" >
      <div class="title">ルーム権限-設定・操作禁止</div>
      <div>
       現在、下記の制御が有効になっています。
        <ul>
          <li *ngIf="roomService.roomAdmin.disableTableSetting">テーブル設定禁止</li>
          <li *ngIf="roomService.roomAdmin.disableTabSetting">チャットタブ設定禁止</li>
          <li *ngIf="roomService.roomAdmin.disableSetCounter">カウンター設定禁止</li>
          <li *ngIf="roomService.roomAdmin.disableCutinSetting">カットイン設定禁止</li>
          <li *ngIf="roomService.roomAdmin.disableDiceRollSetting">ダイスボット表設定禁止</li>
          <li *ngIf="roomService.roomAdmin.disableRoundControl">ラウンド操作禁止</li>
        </ul>
      </div>
    </div>
    <div class="room-info" >
      <div class="title">ルーム権限-保存禁止</div>
      <div>
       現在、下記の制御が有効になっています。
        <ul>
          <li *ngIf="roomService.roomAdmin.disableAllDataSave">部屋全データセーブ禁止</li>
          <li *ngIf="roomService.roomAdmin.disableSeparateDataSave">個別データセーブ禁止</li>
        </ul>
      </div>
    </div>
  </ng-container>
  <div class="help">
    <button (click)="helpRoomControl()">?</button>
  </div>
</div>

<ng-template #systemLog>
  <span *ngIf="roomService.roomAdmin.diceLog">ダイス操作ログ<br/></span>
  <span *ngIf="roomService.roomAdmin.cardLog">カード操作ログ<br/></span>
</ng-template>
<ng-template #template>
  <span *ngIf="roomService.roomAdmin.templateCharacter">{{gameCharacterService.get(roomService.roomAdmin.templateCharacter).name}}</span>
</ng-template>

<ng-template #editSetting>
  <div class="room-info" >
    <div class="title">ルーム権限-透過モード</div>
    <div>
      <div class="flex-item  between-item">
        <div class="control-name">
          透過モード
        </div>
        <div class="control-toggle">
          <div class="toggle"><label>
            <input name="transparentMode" type="checkbox" [(ngModel)]="roomService.roomAdmin.transparentMode">
            <div><div></div></div>
          </label></div>
        </div>
      </div>
    </div>
  </div>
  <div class="room-info" >
    <div class="title">ルーム権限-アップロード禁止</div>
    <div>
      <div class="flex-item  between-item">
        <div class="control-name">
          ルームデータアップロード禁止
        </div>
        <div class="control-toggle">
          <div class="toggle"><label>
            <input name="disableRoomLoad" type="checkbox" [(ngModel)]="roomService.roomAdmin.disableRoomLoad">
            <div><div></div></div>
          </label></div>
        </div>
      </div>
      <div class="flex-item  between-item">
        <div class="control-name">
          データアップロード禁止(オブジェクト)
        </div>
        <div class="control-toggle">
          <div class="toggle"><label>
            <input name="disableTabletopLoad" type="checkbox" [(ngModel)]="roomService.roomAdmin.disableTabletopLoad">
            <div><div></div></div>
          </label></div>
        </div>
      </div>
      <div class="flex-item  between-item">
        <div class="control-name">
          データアップロード禁止(その他)
        </div>
        <div class="control-toggle">
          <div class="toggle"><label>
            <input name="disableObjectLoad" type="checkbox" [(ngModel)]="roomService.roomAdmin.disableObjectLoad">
            <div><div></div></div>
          </label></div>
        </div>
      </div>
      <div class="flex-item  between-item">
        <div class="control-name">
          画像アップロード禁止
        </div>
        <div class="control-toggle">
          <div class="toggle"><label>
            <input name="disableImageLoad" type="checkbox" [(ngModel)]="roomService.roomAdmin.disableImageLoad">
            <div><div></div></div>
          </label></div>
        </div>
      </div>
      <div class="flex-item  between-item">
        <div class="control-name">
          音楽アップロード禁止
        </div>
        <div class="control-toggle">
          <div class="toggle"><label>
            <input name="disableImageLoad" type="checkbox" [(ngModel)]="roomService.roomAdmin.disableAudioLoad">
            <div><div></div></div>
          </label></div>
        </div>
      </div>
    </div>
  </div>
  <div class="room-info" >
    <div class="title">ルーム権限-設定・操作禁止</div>
    <div>
      <div class="flex-item  between-item">
        <div class="control-name">
          テーブル設定禁止
        </div>
        <div class="control-toggle">
          <div class="toggle"><label>
            <input name="disableTableSetting" type="checkbox" [(ngModel)]="roomService.roomAdmin.disableTableSetting">
            <div><div></div></div>
          </label></div>
        </div>
      </div>
      <div class="flex-item  between-item">
        <div class="control-name">
          チャットタブ設定禁止
        </div>
        <div class="control-toggle">
          <div class="toggle"><label>
            <input name="disableTabSetting" type="checkbox" [(ngModel)]="roomService.roomAdmin.disableTabSetting">
            <div><div></div></div>
          </label></div>
        </div>
      </div>
      <div class="flex-item  between-item">
        <div class="control-name">
          カウンター設定禁止
        </div>
        <div class="control-toggle">
          <div class="toggle"><label>
            <input name="disableTabSetting" type="checkbox" [(ngModel)]="roomService.roomAdmin.disableSetCounter">
            <div><div></div></div>
          </label></div>
        </div>
      </div>
      <div class="flex-item  between-item">
        <div class="control-name">
          カットイン設定禁止
        </div>
        <div class="control-toggle">
          <div class="toggle"><label>
            <input name="disableTabSetting" type="checkbox" [(ngModel)]="roomService.roomAdmin.disableCutinSetting">
            <div><div></div></div>
          </label></div>
        </div>
      </div>
      <div class="flex-item  between-item">
        <div class="control-name">
          ダイスボット表設定禁止
        </div>
        <div class="control-toggle">
          <div class="toggle"><label>
            <input name="disableTabSetting" type="checkbox" [(ngModel)]="roomService.roomAdmin.disableDiceRollSetting">
            <div><div></div></div>
          </label></div>
        </div>
      </div>
      <div class="flex-item  between-item">
        <div class="control-name">
          ラウンド操作禁止
        </div>
        <div class="control-toggle">
          <div class="toggle"><label>
            <input name="disableTabSetting" type="checkbox" [(ngModel)]="roomService.roomAdmin.disableRoundControl">
            <div><div></div></div>
          </label></div>
        </div>
      </div>
    </div>
  </div>
  <div class="room-info" >
    <div class="title">ルーム権限-保存禁止</div>
    <div>
      <div class="flex-item  between-item">
        <div class="control-name">
          部屋全データセーブ禁止
        </div>
        <div class="control-toggle">
          <div class="toggle"><label>
            <input name="disableAllDataSave" type="checkbox" [(ngModel)]="roomService.roomAdmin.disableAllDataSave">
            <div><div></div></div>
          </label></div>
        </div>
      </div>
      <div class="flex-item  between-item">
        <div class="control-name">
          個別データセーブ禁止
        </div>
        <div class="control-toggle">
          <div class="toggle"><label>
            <input name="disableSeparateDataSave" type="checkbox" [(ngModel)]="roomService.roomAdmin.disableSeparateDataSave">
            <div><div></div></div>
          </label></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
