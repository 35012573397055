<div class="sticky-top">
  <div style="float: right; margin-top: 3px; margin-right: 3px">
    <label *ngIf="roomService.adminAuth" sytle="white-space: nowrap">
      <input name="show-allimages" type="checkbox" value="Show Hides" checked="{{isShowAllImages ? 'checked' : ''}}" (click)="onShowAllImages($event)">
      <i class="material-icons" style="font-size: smaller" [ngClass]="{'red-eye': isShowAllImages}">{{ isShowAllImages ? 'visibility' : 'visibility_off' }}</i>
    </label>
  </div>
  <span class="word-selecter">
    <label> 
      <input name="word-selecter" type="checkbox" value="ALL" [checked]="showType == 'ALL'" (click)="onAll()">
      <div class="all-tags"><b>ALL</b></div>
    </label>
    <label>
      <input name="word-selecter" type="checkbox" value="No Tag" [checked]="showType == 'No Tag'"  (click)="onNoTag()">
      <div class="no-tag"><s>No Tag</s></div>
    </label>
    <label *ngFor="let tag of allTags">
      <input name="word-selecter" type="checkbox" value="{{tag}}" [checked]="showType == 'Tag' && searchTags.includes(tag)" (click)="onTagSelect(tag)">
      <div>{{tag}}</div>
    </label>
  </span>
  <span style="white-space: nowrap">
    <select [(ngModel)]="serchCondIsOr" style="margin-left: 3px">
      <option value="true">いずれか (OR)</option>
      <option value="">すべて (AND)</option>
    </select>
  </span>
</div>
<div id="file-list" style="min-height: 250px">
  <span class="empty" *ngIf="isAllowedEmpty">
    <button (click)="nonSelectedFile()" style="min-width: 100px; height: 120px">画像なし<br>/設定解除</button>
  </span>
  <div *ngFor="let file of images; trackBy: identify"  @scaleInOut class="image" style="text-align: center; min-width: 100px; height: 120px; position: relative" [ngClass]="{'current-images': getCurrent(file)}">
    <div style="position: absolute; font-size: xx-small; text-align: left; bottom: 0px; width: 100%; height: 100%; overflow: hidden; pointer-events: none">
      <ng-container *ngIf="file.tag.length > 0; then tagsBlock; else noTagBlock"></ng-container> 
      <ng-template #tagsBlock>
        <div style="position: absolute; bottom: 0px; z-index: 5">
          <ng-container *ngFor="let tag of file.tag; index as i">  
            <label *ngIf="i < 6" class="word-tag grow" style="display: block; width: 88px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; pointer-events: none">{{ tag }}</label>
            <span *ngIf="i == 6" class="grow" style="display: block; width: 88px; overflow: hidden; padding-left: 2px; font-weight: bold; text-overflow: ellipsis; white-space: nowrap; pointer-events: none">…</span>
          </ng-container>
        </div>
      </ng-template>
      <ng-template #noTagBlock>
        <div style="position: absolute; bottom: 0px; z-index: 5">
          <s class="grow" style="color:gray; display: block; padding-left: 2px; font-weight: bold; text-overflow: ellipsis; white-space: nowrap; pointer-events: none">No Tag</s>
        </div>
      </ng-template>
    </div>
    <img [src]="file.url | safe: 'resourceUrl'" height="120" (click)="getCurrent(file) ? false : onSelectedFile(file)">
  </div>
</div>
