<div class="log" [ngStyle]="{'minHeight.px':  minScrollHeight}"  #logContainer>
  <div class="virtual-scroll-container" [style.marginBottom.px]="bottomSpace" #messageContainer>
    <ng-container *ngIf="0 < chatMessages.length">
      <ng-container *ngFor="let chatMessage of chatMessages; trackBy: trackByChatMessage">
        <chat-message *ngIf="chatMessage.isDisplayable"
         [chatMessage]="chatMessage" [localFontsize]='this.localFontsize' [bgColor]='this.bgColor' [isSelected]="selectMessageIdentifier === chatMessage.identifier" (click)="selectMessage(chatMessage)" (dblclick)="editMessage(chatMessage)">
        </chat-message>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="chatMessages.length <= 0">
      <chat-message *ngFor="let chatMessage of sampleMessages" [chatMessage]="chatMessage">
      </chat-message>
    </ng-container>
  </div>
</div>
