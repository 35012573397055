<div class="tag-menu scroll-black" style="font-size:16px;">
  <form>
    <label>
      <input name="menu" type="radio" value="main" [(ngModel)]="menu">
       <div>全体</div>
    </label>
    <label>
      <input name="menu" type="radio" value="menu" [(ngModel)]="menu">
      <div>メニュー</div>
    </label>
    <label>
      <input name="menu" type="radio" value="room" [(ngModel)]="menu">
       <div>ルーム</div>
    </label>
    <label>
      <input name="menu" type="radio" value="chat" [(ngModel)]="menu">
       <div>チャット</div>
    </label>
    <label>
      <input name="menu" type="radio" value="sheet" [(ngModel)]="menu">
       <div>キャラクターシート</div>
    </label>
    <label>
      <input name="menu" type="radio" value="character" [(ngModel)]="menu">
       <div>キャラクター</div>
    </label>
    <label>
      <input name="menu" type="radio" value="popup" [(ngModel)]="menu">
       <div>ポップアップメッセージ</div>
    </label>
    <label>
      <input name="menu" type="radio" value="object" [(ngModel)]="menu">
       <div>オブジェクト</div>
    </label>
    <label>
      <input name="menu" type="radio" value="stand" [(ngModel)]="menu">
       <div>立ち絵</div>
    </label>
    <label>
      <input name="menu" type="radio" value="counter" [(ngModel)]="menu">
       <div>ラウンド/カウンター</div>
    </label>
    <label>
      <input name="menu" type="radio" value="board" [(ngModel)]="menu">
       <div>掲示板</div>
    </label>
    <label>
      <input name="menu" type="radio" value="cutin" [(ngModel)]="menu">
       <div>カットイン</div>
    </label>
    <label>
      <input name="menu" type="radio" value="diceroll" [(ngModel)]="menu">
       <div>ダイスボット表</div>
    </label>
    <label>
      <input name="menu" type="radio" value="image" [(ngModel)]="menu">
       <div>画像・音楽</div>
    </label>
  </form>
</div>
<div class="help">
  <ng-container [ngSwitch]="menu">
     <ng-container *ngSwitchCase="'main'">
      <help-main class="main-content"></help-main>
     </ng-container>
     <ng-container *ngSwitchCase="'menu'">
      <help-menu class="main-content"></help-menu>
     </ng-container>
     <ng-container *ngSwitchCase="'room'">
      <help-room class="main-content"></help-room>
     </ng-container>
     <ng-container *ngSwitchCase="'chat'">
      <help-chat class="main-content"></help-chat>
     </ng-container>
     <ng-container *ngSwitchCase="'sheet'">
      <help-sheet class="main-content"></help-sheet>
     </ng-container>
     <ng-container *ngSwitchCase="'character'">
      <help-character class="main-content"></help-character>
     </ng-container>
     <ng-container *ngSwitchCase="'popup'">
      <help-popup class="main-content"></help-popup>
     </ng-container>
     <ng-container *ngSwitchCase="'object'">
      <help-object class="main-content"></help-object>
     </ng-container>
     <ng-container *ngSwitchCase="'stand'">
      <help-stand class="main-content"></help-stand>
     </ng-container>
     <ng-container *ngSwitchCase="'counter'">
      <help-counter class="main-content"></help-counter>
     </ng-container>
     <ng-container *ngSwitchCase="'board'">
      <help-board class="main-content"></help-board>
     </ng-container>
     <ng-container *ngSwitchCase="'cutin'">
      <help-cutin class="main-content"></help-cutin>
     </ng-container>
     <ng-container *ngSwitchCase="'diceroll'">
      <help-diceroll class="main-content"></help-diceroll>
     </ng-container>
     <ng-container *ngSwitchCase="'image'">
      <help-image class="main-content"></help-image>
     </ng-container>
  </ng-container>
</div>
