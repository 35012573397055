<div>
  <div class="stand-container">
    <div class="stand-image-container">
      <ng-container *ngIf="standImage">
        <div [ngStyle]="{'transform': isApplyRoll ? 'rotate(' + gameCharacter.roll + 'deg)' : ''}">
          <img class="stand-image aura" [src]="standImage.url | safe: 'resourceUrl'" [ngStyle]="auraStyle">
          <img class="stand-image front-image" (dblclick)="openModal()" [src]="standImage.url | safe: 'resourceUrl'"
          [ngStyle]="imgStyle">
        </div>
        <button class="stand-image-button" (click)="openModal()" style="font-size: smaller; padding: 2px 3px;"><b>{{ isSpeakable ? '通常' : '' }}画像変更</b></button>
        <div *ngIf="isSpeakable" style="position: absolute; margin-top: -26px; margin-left: 120px; font-size: 22px; filter: grayscale(100%); opacity: 0.5; cursor: default;">{{ isSpeaking ? '😃' : '🤐' }}</div>
      </ng-container>
    </div>
    <div style="display: flex; flex-direction: column; padding-left: 12px; width: 100%">
      <div>
        <ng-container *ngIf="nameElement">
          <span style="font-size: smaller;">
            <input type="text" style="width: 7em" [(ngModel)]="nameElement.value" placeholder="Name">
          </span>
        </ng-container>
        <ng-container *ngIf="conditionTypeElement">
          <b style="font-size: smaller">　Cond: </b><select style="width: 15.5em" [(ngModel)]="conditionTypeElement.value">
            <option value="{{ standConditionType.Default }}">デフォルト</option>
            <option value="{{ standConditionType.Image }}">指定画像</option>
            <option value="{{ standConditionType.Postfix }}">チャット末尾</option>
            <option value="{{ standConditionType.PostfixOrImage }}">チャット末尾 または 指定画像</option>
            <option value="{{ standConditionType.PostfixAndImage }}">チャット末尾 かつ 指定画像</option>
            <option value="{{ standConditionType.NotConditionStandUp }}">選択時のみ</option>
          </select>
        </ng-container>
        <ng-container *ngIf="nameElement">
          <span style="font-size: smaller;">
            <b style="margin-left: 1em;">Tag: </b><input type="text" style="width: 3em" [(ngModel)]="nameElement.currentValue" placeholder="Tag">
          </span>
        </ng-container>
      </div>
      <div>
        <ng-container *ngIf="showNameElement">
          <label style="margin-right: 0.6em">
            <input type="checkbox" [(ngModel)]="showNameElement.value" checked="{{showNameElement.value ? 'checked' : ''}}" (change)="showNameElement.value = (showNameElement.value ? 'showName' : '')">
            <b style="font-size: smaller;">ネームタグ</b>
          </label>
        </ng-container>
        <ng-container *ngIf="applyImageEffectElement">
          <label style="margin-right: 0.6em">
            <input type="checkbox" [(ngModel)]="applyImageEffectElement.value" checked="{{applyImageEffectElement.value ? 'checked' : ''}}" (change)="applyImageEffectElement.value = (applyImageEffectElement.value ? 'applyImageEffect' : '')">
            <b style="font-size: smaller;">画像効果反映</b>
          </label>
        </ng-container>
        <ng-container *ngIf="applyRollElement">
          <label style="margin-right: 0.6em">
            <input type="checkbox" [(ngModel)]="applyRollElement.value" checked="{{applyRollElement.value ? 'checked' : ''}}" (change)="applyRollElement.value = (applyRollElement.value ? 'applyRoll' : '')">
            <b style="font-size: smaller;">回転反映</b>
          </label>
        </ng-container>
        <ng-container *ngIf="applyDialogElement">
          <label style="margin-right: 0.2em">
            <input type="checkbox" [(ngModel)]="applyDialogElement.value" checked="{{applyDialogElement.value ? 'checked' : ''}}" (change)="applyDialogElement.value = (applyDialogElement.value ? 'applyDialog' : '')">
            <b style="font-size: smaller;">💭</b>
          </label>
        </ng-container>
        <button (click)="openSpeakingModal()" style="font-size: 9px" [style.font-weight]="isSpeakable ? 'bolder' : 'normal'">口パク画像 (APNGなど)</button>
        <ng-container *ngIf="positionElement">
          <br><button style="font-size: 9px; margin-right: 1em;" (click)="testStandUp()"><b>テスト (自分だけ見る)<sup class="self-only">*</sup></b></button>
          <label style="margin-right: 0.3em;">
            <input type="checkbox" [(ngModel)]="positionElement.currentValue" checked="{{positionElement.currentValue ? 'checked' : ''}}" (change)="positionElement.currentValue = (positionElement.currentValue ? 'posSpecialize' : '')">
            <b style="font-size: smaller;">Pos個別指定: </b>
          </label>
          <span style="white-space: nowrap; font-size: smaller">
            <input style="width: 140px" type="range" min="0" max="100" [(ngModel)]="positionElement.value" [disabled]="!positionElement.currentValue">
            <input style="width: 3em; text-align: right;" type="number" min="0" max="100" [(ngModel)]="positionElement.value" [disabled]="!positionElement.currentValue">
            <b style="display: inline-block; font-size: smaller; margin-left: 3px" [ngClass]="{'disabled-text': !positionElement.currentValue }">%</b>
          </span>
        </ng-container>
        <ng-container *ngIf="heightElement">
          <br><span style="white-space: nowrap; font-size: smaller">
            <b style="display: inline-block; margin-left: 3px">Height (0=指定なし): </b>
            <input style="width: 130px" type="range" min="0" max="100" [(ngModel)]="heightElement.value">
            <input style="width: 3em; text-align: right;" type="number" min="0" max="200" [(ngModel)]="heightElement.value">
            <b style="display: inline-block; font-size: smaller; margin-left: 3px">%</b>
          </span>
          <span style="white-space: nowrap; margin-left: 0.8em; font-size: smaller">
            <b style="display: inline-block; margin-left: 3px">AdjY: </b>
            <input type="number" min="-100" max="100" style="width: 3.4em; text-align: right" [(ngModel)]="heightElement.currentValue">
            <b style="display: inline-block; font-size: smaller; margin-left: 3px">%</b>
          </span>
        </ng-container>
      </div>
      <div *ngIf="postfixElement && (conditionTypeElement.value == standConditionType.Postfix || conditionTypeElement.value == standConditionType.PostfixOrImage || conditionTypeElement.value == standConditionType.PostfixAndImage)">
        <textarea [(ngModel)]="postfixElement.value" style="width: 100%; margin-top: 3px; height: 2.4rem" placeholder="1行に一つ、冒頭@を付けるとマッチ時にテキストから切り取り&#13;&#10;@怒り&#13;&#10;@必殺技"></textarea>
      </div>
      <div *ngIf="conditionTypeElement.value == standConditionType.Image || conditionTypeElement.value == standConditionType.PostfixOrImage || conditionTypeElement.value == standConditionType.PostfixAndImage">
        <div *ngIf="imageList && imageList.length > 0" style="display: flex; flex-wrap: wrap; margin-right: -30px;">
          <ng-container *ngFor="let image of imageList">
            <div *ngIf="0 < image.url.length" class="icon-image-box" [ngClass]="{'current-icon': isSelectedImage(image.identifier)}">
              <img [src]="image.url | safe: 'resourceUrl'" [alt]="image.name" (click)="selectImage(image.identifier)" />
            </div>
          </ng-container>
        </div>
        <div *ngIf="!imageList || imageList.length == 0">キャラクター画像、顔ICが設定されていません</div>
      </div>
    </div>
  </div>
</div>
