<div #setting class="flex-box" (contextmenu)="config($event); false">
  <div class="flex-column" *ngIf="isSendTo">
        ➡
        <select style="width: 10em" [(ngModel)]="sendTo" [ngClass]="{'not-specified': sendTo == ''}" [ngStyle]="{'color': (sendTo == '' ? null : sendToColor), 'font-weight': (sendTo == '' ? 'normal' : 'bolder')}">
          <option value="" class="not-specified">全員</option>
          <option *ngFor="let player of otherPlayers" value="{{(player && player?.playerId) ? player.playerId : '' }}" [style.color]="(player && player?.color )? player.color : playerService.CHAT_WHITETEXT_COLOR" style="font-weight: bolder;">{{(player && player?.name) ? player.name : '接続中' }}
            <ng-container *ngIf="player === myPlayer">（あなた）</ng-container>
          </option>
        </select>
  </div>
  <div class="flex-column" *ngIf="isGameType">
        <select *ngIf="diceBotInfosIndexed.length < 1" style="width: 12em;">
          <option value="" class="not-specified">ダイスボット読込中..</option>
        </select>
        <select *ngIf="diceBotInfosIndexed.length > 0" style="width:12em;" (change)="loadDiceBot($event.target.value)" [(ngModel)]="gameType" [ngModelOptions]="{standalone: true}"
          [ngClass]="{'not-specified': gameType == ''}">
          <option value="" class="not-specified">ダイスボット指定なし</option>
          <optgroup *ngFor="let group of diceBotInfosIndexed" class="dice-bot-group" label="{{group.index}}">
            <option *ngFor="let diceBotInfo of group.infos" value="{{diceBotInfo.script}}">{{diceBotInfo.game}}</option>
          </optgroup>
        </select>
  </div>
  <div class="flex-column" *ngIf="isStandSetting">
    <button *ngIf="!hasStand && hasImage" style="height:2em;font-size:14px;padding: 3px 2em;" (click)="instantStandSetting()">立ち絵簡易設定</button>
    <button *ngIf="hasStand || !hasImage" style="height:2em;font-size:14px;padding: 3px 2em;" (click)="showStandSetting()">立ち絵設定</button>
  </div>
  <div class="flex-column" *ngIf="isStand">
    <b style="font-size: 10px;">立ち絵: </b>
    <input type="checkbox" [(ngModel)]="isUseStandImage" checked="{{isUseStandImage ? 'checked' : ''}}" (change)="isUseStandImage = (isUseStandImage ? true : false)" />
    <select style="width: 8em; font-size:12px;" [(ngModel)]="standName" [ngModelOptions]="{standalone: true}" [ngClass]="{'not-specified': standName == ''}" >
        <option value="" class="not-specified">未選択</option>
        <option *ngFor="let name of standNameList" value="{{name}}">{{name}}</option>
    </select>
  </div>
  <div class="flex-column" *ngIf="isStandPos">
    <b style="font-size: 14px;">立ち絵位置: </b>
    <input style="width: 3em; text-align: right; margin-right: 3px;" type="number" min="0" max="100" (change)="isUseStandImage = true" [(ngModel)]="character.standList.position">
  </div>
  <div class="flex-column" *ngIf="isColor">
    <b style="font-size: 0.7em;">色: </b>
    <input type="color" style="width:4em;" [(ngModel)]="paletteColor" [ngModelOptions]="{standalone: true}">
  </div>
  <button *ngIf="!minimumMode" class="setting" style="padding:0;"(click)="showDicebotHelp()"><img src="./assets/images/dice_icon.png"></button>
  <div *ngIf="!minimumMode" class="material-icons setting" (click)="config($event)">
    settings
  </div>
</div>
