import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'help-main',
  templateUrl: './help-main.component.html',
  styleUrls: ['./help-main.component.css']
})
export class HelpMainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
