<div class="container scroll-white">
  <table class="inventory">
     <tr>
       <th style="width:13em">キャラクター</th>
       <th style="width:14em">名前</th>
       <th style="width:14em">説明</th>
       <th style="width:5em">寿命</th>
       <th style="width:14em">コメント</th>
       <th style="width:4em">削除</th>
     <tr>
    <tr *ngFor="let counterinfo of myList; let i = index" [ngStyle]="{'background-color': counterinfo.age === 0 && !counterinfo.isPermanent ? 'maroon' : 'black'}">
       <td *ngIf="isFirst(i)"class="character" [attr.rowspan]="mergeRow(counterinfo.characterIdentifier)"
       [ngStyle]="{'background-image': counterinfo.isBlackPaint ? 'url()' : 'url(' + counterinfo.characterImage + ')'}">
         <span class="name">{{counterinfo.characterName}}</span>
        </td>
       <td>{{counterinfo.name}}</td>
       <td>{{counterinfo.desc}}</td>
       <td class="center"><p *ngIf="!counterinfo.isPermanent; else permanent">{{counterinfo.age}}/{{counterinfo.maxAge}}</p></td>
       <td>{{counterinfo.comment}}</td>
       <td class="center"><button *ngIf="!disableRemove" class="close" (click)="remove(counterinfo.identifier)"><i class="material-icons" style="font-size: 14px;">close</i></button></td>
    </tr>
  </table>
</div>
<div *ngIf="auth" class="control-panel">
  <button (click)="resetAge()">寿命をリセット</button>
  <button (click)="removeAll(false)">全て削除</button>
  <button (click)="removeAll(true)">全て削除(永続含む)</button>
</div>
<ng-template #permanent>
    永続
</ng-template>
