<div class="component is-3d is-pointer-events-none"
  [style.width.px]="width * gridSize" [style.height.px]="depth * gridSize"
  appMovable [movable.option]="movableOption" [movable.disable]="isLocked" [movable.interact]="isInteract" (movable.ondragstart)="onMove()" (movable.ondragend)="onMoved()">
  <div class="component-content is-3d is-pointer-events-auto" [ngClass]="{'is-grab': !isLocked}"
    appRotable [rotable.option]="rotableOption" (rotable.ondragstart)="onMove()" (rotable.ondragend)="onMoved()" [rotable.disable]="isLocked || stopRotate">
    <ng-container *ngIf="hasFloor && isVisibleFloor && 0 < floorImage?.url.length">
      <div class="texture" [ngClass]="{'blinking-animation': !isLocked}"
        [style.background-image]="'url(' + floorImage.url + ')' | safe: 'style'"></div>
    </ng-container>
    <ng-container *ngIf="!isLocked">
      <div class="pedestal-grab rotate-grab is-3d"></div>
        <div class="rotate-grab of-left-top rotate-grab-icon is-3d">
          <i class="material-icons">autorenew</i>
        </div>
        <div class="rotate-grab of-left-bottom rotate-grab-icon is-3d">
          <i class="material-icons">autorenew</i>
        </div>
        <div class="rotate-grab of-right-bottom rotate-grab-icon is-3d">
          <i class="material-icons">autorenew</i>
        </div>
        <div class="rotate-grab of-right-top rotate-grab-icon is-3d">
          <i class="material-icons">autorenew</i>
        </div>
    </ng-container>
  </div>
</div>