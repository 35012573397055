<!--<h2>チャットシステム</h2>-->
<div class="component"  [class]="bgColor">
<div class="log">
  <chat-tab *ngIf="chatTab && !isEase && chatTab.isAllowed" [chatTab]="chatTab" [chatWindowIdentifier]="chatWindowID" (onAddMessage)="scrollToBottom()" (edit)="messageEdit($event)" [localFontsize]="localFontsize" [bgColor]="bgColor"></chat-tab>
  <chat-tab-ease *ngIf="chatTab && isEase && chatTab.isAllowed" [chatTab]="chatTab" [chatWindowIdentifier]="chatWindowID" (onAddMessage)="scrollToBottom()" (edit)="messageEdit($event)" [localFontsize]="localFontsize" [bgColor]="bgColor"></chat-tab-ease>
  <div *ngIf="!chatTab" [ngStyle]="{'color':bgColor == 'black' ? '#FFF' : '#222'}">※チャットタブの内容が更新されました。チャットタブを選択しなおすか、このチャットウィンドウを開きなおしてください。</div>
  <div *ngIf="chatTab && !chatTab.isAllowed" [ngStyle]="{'color':bgColor == 'black' ? '#FFF' : '#222'}">※このチャットタブへの権限がありません</div>
</div>
<div class="sticky-bottom metal-background">
  <div class="flex-row flex-between">
    <div class="chat-tab-background chat-tab scroll-white" style="border-bottom-right-radius: 20px;">
      <form>
        <label *ngFor="let chatTab of chatMessageService.controledChatTabList">
          <input name="chat-tab" type="radio" value="{{chatTab.identifier}}" ng-control="options" [disabled]="!chatTab.isAllowed" [ngModelOptions]="{ standalone: true }" [(ngModel)]="chatTabidentifier">
          <div><span *ngIf="chatTab.isLock"><i class="material-icons" style="font-size:0.8em;color: #FFF;">lock</i></span>{{chatTab.name}}<badge *ngIf="chatTab.chatTab.hasUnread" class="badge" [count]="chatTab.chatTab.unreadLength"></badge></div>
        </label>
      </form>
    </div>
    <div class="flex-row setting">
      <div class="flex-column-nowrap" style="padding-right:6px;">
        <span>スクロール</span>
        <div class="toggle"><label>
          <input name="autoScroll" type="checkbox" [(ngModel)]="canAutoScroll">
          <div><div></div></div>
        </label></div>
      </div>
      <button style="padding: 0.2em 1em;height: 2.8em;" (click)="showTabSetting()">チャット設定</button>
    </div>
  </div>
  <div *ngIf="isLogOnly && !isEdit">
    <ng-container [ngSwitch]="controlType">
      <ng-container *ngSwitchCase="'resource'">
        <player-palette-control *ngIf="!disableControl" class="scroll-white" [sendFrom]="sendFrom" (chat)="sendChat($event)"></player-palette-control>
      </ng-container>
      <ng-container *ngSwitchCase="'inventory'">
        <simple-inventory></simple-inventory>
      </ng-container>
      <ng-container *ngSwitchDefault>
      </ng-container>
    </ng-container>
    <chat-input [isBlack]="isBlack" [chatTabidentifier]="chatTabidentifier" [(sendFrom)]="sendFrom" (chat)="sendChat($event)" (tabSwitch)="chatTabSwitchRelative($event)"></chat-input>
  </div>
  <chat-edit *ngIf="isLogOnly && isEdit" [isBlack]="isBlack" [chatMessage]="editMessage" (toggleEdit)="toggleEdit()"></chat-edit>
</div>

