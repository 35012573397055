<div class="flex">
  <div class="demo">
    <div class="canvas">
      <img  #effect [style.width.px]="width" [style.height.px]="height" src="./assets/images/effect.png">
    </div>
    <div class="selecter">
       <select [style.width.px]="170" size="10" [(ngModel)]="effectName">
          <option *ngFor="let effect of effectsName" value="{{effect}}">{{effect}}</option>
       </select>
    </div>
  </div>
  <div>
    <button (click)="play()">再生</button>
    <button *ngIf="!isDrag"  (click)="setEffect()">エフェクトを付与</button>
    <button *ngIf="isDrag" (click)="cancel()">キャンセル</button>
  </div>
  <div class="bottom">
    <div style="width:100%">
    エフェクトを付与をクリック後、付与したいキャラクターをクリック。<br>
    または、チャット末尾に @effect(エフェクト名)
    </div>
  </div>
</div>
