<div #content class="content">
  <ng-container *ngFor="let message of cards">
    <div class="card" [ngClass]="{'secret' : message.dataType == '2',
      'update' : updateList.includes(message.identifier),
      'new' : newList.includes(message.identifier)}"
        [@flyInOut]="'active'" (click)="openCard(message)">
        <span [innerHTML]="message.titleAsHtml"></span>
    </div><div></div>
  </ng-container>
  <button style="width:260px;margin: 6px 12px;" (click)="createCard()" >新規作成</button>
</div>

