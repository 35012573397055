<div class="container">
  <div class="title">ラウンド</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/round.png">
    </div>
    <div class="description">
      ラウンド単位、またはイニシアティブ単位でラウンドの管理ができます。<br/>
      画面上部「ラウンド開始」をクリックすることでラウンドが進行していきます。<br/>
      右クリックで設定を行い、進行状況をチャットに出力するタブの設定、<br/>
      イニシアティブに使う項目の設定が可能です<br/>
    </div>
  </div>
  <div class="title">カウンター</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/counter.png">
    </div>
    <div class="description">
      キャラクターにカウンターを付与することができます<br/>
      付与されたカウンターは永続に設定されていない限り、ラウンドの進行に伴って寿命が減衰していき<br/>
      0になると赤く表示され、-1になると消滅します。<br/>
    </div>
  </div>
  <div class="title">カウンターリスト(機能→カウンター設定)</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/counter-list.png">
    </div>
    <div class="description">
      カウンターの作成、配布はカウンターリストで行います。<br/>
      カウンターはデフォルトでは重複不可で作成され、同じカウンターが配布されると寿命がリセットされます<br/>
      作成したカウンターは右クリックで詳細が表示されます<br/>
      カウンターを配布するときは、配布したいカウンターをクリック後、対象のキャラクターをクリックします。
    </div>
  </div>
  <div class="title">カウンター一覧(機能→カウンターリスト→一覧　または　リスト→配布カウンター一覧)</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/counter-inventory.png">
    </div>
    <div class="description">
      カウンターの配布状況はカウンターインベントリで確認できます<br/>
      また、この画面からカウンターの削除が行なえます
    </div>
  </div>
</div>
