<div class="container">
  <div class="title">ダイスボット表</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/diceroll.png">
    </div>
    <div class="description">
      名前、コマンド、振るダイスを設定し、ダイスの数字で表を参照し、表示します。<br/>
      チャットでコマンドを送信することにより、ダイスボットと同様に結果が送信されます。<br/>
      表の書き方はbcdiceのオリジナル表の書式に準じます。<br/>
      参考:<span style="color:aqua" (click)="onLinkClick($event)">https://docs.bcdice.org/original_table.html</span><br/>
      <br/>
      表を参照する際は先にあるものが優先されます。<br/>
    </div>
  </div>
</div>