<div style="margin-bottom: 4px;">
  <button style="float: right;" (click)="helpStandSeteing()">?</button>
  <div class="position-indicate">
    <b>Pos: </b>
    <input style="width: 350px" type="range" min="0" max="100" [(ngModel)]="position">
    <input style="width: 3em; text-align: right; margin-right: 3px;" type="number" min="0" max="100" [(ngModel)]="position"><b sytle="margin-left: 3px; margin-right: 1em ">％　</b>
  </div>
  <div class="position-indicate">
    <b>Height (0=元画像のまま): </b>
    <input style="width: 350px" type="range" min="0" max="100" [(ngModel)]="height">
    <input style="width: 3em; text-align: right; margin-right: 3px;" type="number" min="0" max="200" [(ngModel)]="height"><b sytle="margin-left: 3px; margin-right: 1em ">％</b>
  </div>
</div>
<div class="overview-selecter" [style.border-color]="overviewIndex == -1 ? 'red' : 'transparent'" style="padding: 0px; width: 695px;">
  <label style="font-size: smaller; font-weight: bolder;"><input type="radio" name="{{panelId}}_overviewIndex" value="-1" [checked]="overviewIndex == -1" (click)="overviewIndex = -1"> 立ち絵画像をオーバービューに使用しない</label>
</div>
<hr>
<div style="display: flex; flex-direction: row; flex-wrap: wrap">
  <div *ngFor="let standElement of standElements; let i = index" style="width: 750px">
    <div style="display: flex; flex-direction: row; justify-content: space-between;" [style.border-color]="overviewIndex == i ? 'red' : 'transparent'" class="overview-selecter">
      <div style="display: flex; align-items: center; justify-content: center;">
        <input style="z-index: 10;" type="radio" name="{{panelId}}_overviewIndex" value="{{ i }}" [checked]="overviewIndex == i" (click)="overviewIndex = i">
      </div>
      <div style="margin-left: -1em; margin-right: -1em;">
        <stand-element #standElementComponent [standElement]="standElement" [imageList]="imageList" [gameCharacter]="character"></stand-element>
      </div>
      <div style="text-align: right; width: 3em;">
        <button class="remove-button danger" (click)="delele(standElement, i)">×</button>
        <button class="remove-button" style="margin-top: 5px" (click)="upStandIndex(standElement)">↑</button>
        <button class="remove-button" style="margin-top: 2px" (click)="downStandIndex(standElement)">↓</button>
      </div>
    </div>
    <hr>
  </div>
</div>
<button (click)="add()">立ち絵設定の追加</button>
<ng-container *ngIf="standSettingXML">
  <button (click)="restore()">直前に削除した立ち絵を戻す</button>
</ng-container>
