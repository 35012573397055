<div class="component is-3d is-pointer-events-none"
  [style.width.px]="width * gridSize" [style.height.px]="depth * gridSize"
  appMovable [movable.option]="movableOption" [movable.disable]="isLocked" [movable.interact]="isInteract" (movable.ondragstart)="onMove()" (movable.ondragend)="onMoved()">
  <div class="component-content is-3d is-pointer-events-auto" [ngClass]="{'is-grab': !isLocked}"
    appRotable [rotable.option]="rotableOption" (rotable.ondragstart)="onMove()" (rotable.ondragend)="onMoved()" [rotable.disable]="isLocked || stopRotate">
    <ng-container *ngIf="hasFloor && isVisibleFloor && 0 < floorImage?.url.length">
      <div *ngIf="isDropShadow && (terreinAltitude != 0 || (isSlope && !hasWall))"
        style="opacity: 0.7; filter: brightness(0) blur(1px); transform: translateZ(0.3px) scale(0.98); backface-visibility: visible"
        class="texture" [ngClass]="{'blinking-animation': !isLocked}" [style.background-image]="'url(' + floorImage.url + ')' | safe: 'style'"></div>
    </ng-container>
    <div class="is-3d texture" style="z-index: 1" [ngClass]="{'upright-transform': (height < 0.5 && altitude == 0), 'fall': (height >= 0.5 && altitude == 0)}" [ngStyle]="{transform: 'translateZ(' + (altitude * gridSize) + 'px)'}">
      <ng-container *ngIf="hasFloor && isVisibleFloor && 0 < floorImage?.url.length">
        <div class="texture" [ngClass]="{'blinking-animation': !isLocked}" [ngStyle]="{'backface-visibility': isSlope ? 'visible' : 'hidden', 'transform': 'translateZ(' + ((height / (isSlope ? 2 : 1)) * gridSize) + 'px)' + floorModCss, filter: 'brightness(' + floorBrightness + ')'}"
          [style.background-image]="'url(' + floorImage.url + ')' | safe: 'style'"></div>
      </ng-container>
      <ng-container *ngIf="isWallExist">
        <ng-container *ngIf="slopeDirection != slopeDirectionState.TOP">
          <div *ngIf="isVisibleWallTopBottom" [ngClass]="{'blinking-animation': !isLocked, 'no-shadow': !isSurfaceShading, 'is-slope': slopeDirection == slopeDirectionState.LEFT, 'is-inverse-slope': slopeDirection == slopeDirectionState.RIGHT}" class="texture of-wall-top" [style.background-image]="'url(' + wallImage.url + ')' | safe: 'style'"
            [style.width.px]="width * gridSize" [style.height.px]="height * gridSize"
            [ngStyle]="{'transform': 'translateY(-100%) rotateX(90deg) rotateZ(180deg) scaleX(-1.0)'}"></div>
        </ng-container>
        <ng-container *ngIf="slopeDirection != slopeDirectionState.BOTTOM">
          <div *ngIf="isVisibleWallTopBottom" [ngClass]="{'blinking-animation': !isLocked, 'no-shadow': !isSurfaceShading, 'is-slope': slopeDirection == slopeDirectionState.LEFT, 'is-inverse-slope': slopeDirection == slopeDirectionState.RIGHT}" class="texture of-wall-bottom" [style.background-image]="'url(' + wallImage.url + ')' | safe: 'style'"
            [style.width.px]="width * gridSize" [style.height.px]="height * gridSize"
            [ngStyle]="{'transform': 'rotateX(-90deg)'}"></div>
        </ng-container>
        <ng-container *ngIf="slopeDirection != slopeDirectionState.LEFT">
          <div *ngIf="isVisibleWallLeftRight" [ngClass]="{'blinking-animation': !isLocked, 'no-shadow': !isSurfaceShading, 'is-slope': slopeDirection == slopeDirectionState.BOTTOM, 'is-inverse-slope': slopeDirection == slopeDirectionState.TOP}" class="texture of-wall-left" [style.background-image]="'url(' + wallImage.url + ')' | safe: 'style'"
            [style.width.px]="depth * gridSize" [style.height.px]="height * gridSize"
            [ngStyle]="{'transform': 'rotateZ(90deg) rotateX(-90deg) scaleX(-1.0) translateX(-100%) translateY(-100%)'}"></div>
        </ng-container>
        <ng-container *ngIf="slopeDirection != slopeDirectionState.RIGHT">
          <div *ngIf="isVisibleWallLeftRight" [ngClass]="{'blinking-animation': !isLocked, 'no-shadow': !isSurfaceShading, 'is-slope': slopeDirection == slopeDirectionState.BOTTOM, 'is-inverse-slope': slopeDirection == slopeDirectionState.TOP}" class="texture of-wall-right" [style.background-image]="'url(' + wallImage.url + ')' | safe: 'style'"
            [style.width.px]="depth * gridSize" [style.height.px]="height * gridSize"
            [ngStyle]="{'transform': 'rotateZ(-90deg) rotateX(-90deg) translateY(-100%)'}"></div>
        </ng-container>
      </ng-container>
    </div>
    <ng-container *ngIf="!isLocked">
      <div class="is-3d" style="z-index: 0" [style.opacity]="isInteract ? '1.0': '0.5'" [style.height.px]="depth * gridSize">
        <div class="pedestal-grab rotate-grab is-3d"></div>
        <div class="rotate-grab of-left-top rotate-grab-icon is-3d">
          <i class="material-icons">autorenew</i>
        </div>
        <div class="rotate-grab of-left-bottom rotate-grab-icon is-3d">
          <i class="material-icons">autorenew</i>
        </div>
        <div class="rotate-grab of-right-bottom rotate-grab-icon is-3d">
          <i class="material-icons">autorenew</i>
        </div>
        <div class="rotate-grab of-right-top rotate-grab-icon is-3d">
          <i class="material-icons">autorenew</i>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="is-3d" *ngIf="isAltitudeIndicate && !((isSlope || isWallExist) && -height < altitude && altitude < 0) && (terreinAltitude <= -0.5 || 0.5 <= terreinAltitude)" style="display:flex; align-items: center; border-left: 2px dashed blanchedalmond; position: absolute; transform-origin: top left"
    [style.height.px]="math.abs(terreinAltitude * gridSize)"
    [ngStyle]="{transform: 'translateX(' + (width * gridSize / 2) + 'px) translateY(' + (-depth * gridSize / 2) + 'px) rotateX(-90deg) translateY(' + (terreinAltitude > 0 ? -(altitude + ((isSlope || isWallExist) ? 0 : height)) * gridSize : 0) + 'px) rotateY(' + (viewRotateZ) + 'deg)'}">
    <div class="altitude-indicator is-3d" style="position: absolute">{{ math.abs(terreinAltitude) }}</div>
    <div class="altitude-indicator is-3d" style="position: absolute; transform: rotateY(-180deg)">{{ math.abs(terreinAltitude) }}</div>
  </div>
</div>
