<div class="content">
  <span class="title">Udonite</span><br>
  ユドナイトはサーバー・クライアント式に変更したユドナリウムです。<br>
  通常のユドナリウムと下記の点が異なります。<br>
  <ul>
    <li>データは全てサーバーに保存されます。音楽以外の全てのデータは次回セッション時に持ち越されます。</li>
    <li>2D/3Dの切り替え機能。</li>
    <li>画面内の好きな位置で立ち絵を表示可能。</li>
    <li>プレイヤー情報を保持します。ルームを作成したプレイヤーはルームを管理する権限が付与されます。</li>
    <li>ゲームキャラクターの情報を他のプレイヤーに伏せることができます。</li>
    <li>ゲームキャラクターがフリーテキストのメモ欄をステータスとは別に持ちます。</li>
    <li>ラウンド・カウンター管理</li>
    <li>透過モード(伏せられたカード・ダイス・ゲームキャラクターの情報をルームマスターが確認可能なモード)</li>
    <li>タブ式チャットパレット「パレットバインダー」。複数のキャラクターを一つのパネルで切り替えることが可能です。</li>
    <li>リソース管理機能。チャット画面から選択したキャラクターのリソースを変更可能です。</li>
    <li>更新通知付きの掲示板機能。</li>
    <li>視覚的なアラーム機能。</li>
    <li>画面上を指示するポップアップメッセージ機能。</li>
    <li>イニシアティブ補正値の実装 。</li>
    <li>消耗リソース管理機能。</li>
  </ul>
</div>
<div class="content">
  <span class="title">基本操作</span><br>
  <ul>
  <li>左クリック：オブジェクトをつかむ</li>
  <li>右クリック：オブジェクトのコマンド表示</li>
  <li>オブジェクトの端をつかんでドラッグ：オブジェクトを回転させる</li>
  <li>マップで左クリックホールド＋ドラッグ or 方向キー：マップ上を移動</li>
  <li>マップで右クリックホールド＋ドラッグ or Shift+方向キー：視点角度変更</li>
  <li>マップでスクロール or Ctrl+方向キー(上下)：画面の拡大縮小</li>
  <li>ファイルのアップロード：画面にドラッグ＆ドロップ</li>
   </ul>
</div>
<div class="content">
  <span class="title">パネル</span><br>
  <img src="./assets/help/panel.png">
  <ul>
  <li><i class="material-icons" style="font-size: 14px;">minimize</i>：パネルを最小化します</li>
  <li><i class="material-icons" style="font-size: 14px;">fullscreen</i>：パネルを最大化します</li>
  <li><i class="material-icons" style="font-size: 14px;">close</i>：パネルを閉じます</li>
  </ul>
</div>
