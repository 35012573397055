<div class="flex-container">
  <div class="menu" *ngIf="aliasName === 'character'">
      <button class="menu-button" [ngClass]="{'select':mode == ''}" (click)="setMode('')">データ</button>
      <button class="menu-button" [ngClass]="{'select':mode == 'character-image'}" (click)="setMode('character-image')">画像設定</button>
      <button class="menu-button" [ngClass]="{'select':mode == 'stand'}" (click)="setMode('stand')">立ち絵設定</button>
      <button class="menu-button" [ngClass]="{'select':mode == 'note'}" (click)="setMode('note')">メモ</button>
      <button class="menu-button" [ngClass]="{'select':mode == 'palette'}" (click)="setMode('palette')">チャットパレット設定</button>
      <button class="menu-button" [ngClass]="{'select':mode == 'resource'}" (click)="setMode('resource')">リソース管理</button>
      <button class="menu-button" [ngClass]="{'select':mode == 'counter'}" (click)="setMode('counter')">カウンター</button>
  </div>
  <div class="sub-menu" *ngIf="mode == ''">
    <button  class="menu-button" [ngClass]="{'select':isEdit}" (click)="toggleEdit()">編集</button>
    <ng-container *ngIf="isEdit; else control">
      <button  class="menu-button" (click)="addDataElement()">新しい項目を追加</button>
      <ng-container [ngSwitch]="aliasName">
        <ng-container *ngSwitchCase="'card'">
          <button  class="menu-button" (click)="openModal('front')" [disabled]="!tabletopObject['isVisible']">表面の画像を変更</button>
          <button  class="menu-button" (click)="openModal('back')" [disabled]="!tabletopObject['isVisible']">裏面の画像を変更</button>
        </ng-container>
        <ng-container *ngSwitchCase="'dice-symbol'">
          <button  class="menu-button" (click)="openModal(tabletopObject['face'])" [disabled]="!tabletopObject['isVisible']">ダイス目の画像を変更</button>
        </ng-container>
        <ng-container *ngSwitchCase="'terrain'">
          <button class="menu-button" (click)="openModal('floor')">床の画像を変更</button>
          <button  class="menu-button" (click)="openModal('wall')">壁の画像を変更</button>
        </ng-container>
        <ng-container *ngSwitchCase="'table-mask'">
          <button  class="menu-button" (click)="openModal('imageIdentifier')">画像変更</button>
        </ng-container>
        <ng-container *ngSwitchCase="'text-note'">
          <button  class="menu-button" (click)="openModal('imageIdentifier')">画像変更</button>
        </ng-container>
      </ng-container>
    </ng-container>
    <div style="float: right; margin-top: 3px; margin-right: 3px">
      <button (click)="help()">?</button>
    </div>
  </div>
  <div class="data-area">
    <ng-container [ngSwitch]="mode">
      <ng-container *ngSwitchCase="'character-image'">
        <character-image [character]="tabletopObject"></character-image>
      </ng-container>
      <ng-container *ngSwitchCase="'stand'">
        <stand-setting [character]="tabletopObject"></stand-setting>
      </ng-container>
      <ng-container *ngSwitchCase="'note'">
        <inner-note [character]="tabletopObject"></inner-note>
      </ng-container>
      <ng-container *ngSwitchCase="'palette'">
        <palette-edit [character]="tabletopObject"></palette-edit>
      </ng-container>
      <ng-container *ngSwitchCase="'resource'">
        <limit-resource [character]="tabletopObject"></limit-resource>
      </ng-container>
      <ng-container *ngSwitchCase="'counter'">
        <character-counter [character]="tabletopObject"></character-counter>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <data-sheet [tabletopObject]="tabletopObject"></data-sheet>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #control>
  <button class="menu-button" (click)="clone()">コピーを作る</button>
  <button class="menu-button" *ngIf="!roomService.disableSeparateDataSave" [disabled]="isSaveing" (click)="saveToXML()">
    <div style="position: relative;">
      <div [ngClass]="{'is-hidden': isSaveing}">保存</div>
      <div *ngIf="isSaveing" class="progress">{{progresPercent}}%</div>
    </div>
  </button>
  <select *ngIf="aliasName === 'character'" style="font-size:14px" (change)="setLocation($event.target.value)" [ngModel]="tabletopObject.location.name">
    <option value="table">テーブル</option>
    <option value="common">共有インベントリ</option>
    <option value="{{playerService.myPlayer.playerId}}">個人インベントリ</option>
    <option value="graveyard">墓地</option>
  </select>
</ng-template>
