<div class="flex-container">
  <div class="flex-item no-grow selecter">
    <button style="display: block; width: 190px; font-size: smaller" (click)="createGameTable()">新しいテーブルを作る</button>
    <div  class="flex-item selecter-item">
      <select style="display: block;width: 155px" size="10" (change)="selectGameTable($event.target.value)" [ngModel]="selectedTable?.identifier">
        <option *ngFor="let gameTable of getGameTables()" value="{{gameTable.identifier}}">{{gameTable.name}}</option>
      </select>
      <div class="flex-item switch">
        <button style="display: block; padding: 0.6em;" (click)="upTableIndex()">↑</button>
        <button style="display: block; padding: 0.6em;" (click)="downTableIndex()">↓</button>
      </div>
    </div>
    <div  class="flex-item auto-play">
      <label class="auto-play-setting">
        BGM自動再生
        <div class="toggle">
        <input type="checkbox" [(ngModel)]="autoPlayBGM" name="autoPlayBGM" />
        <div><div></div></div>
        </div>
      </label>
      <label class="auto-play-setting">
        カットイン自動再生
        <div class="toggle">
        <input type="checkbox" [(ngModel)]="autoPlayCutin" name="autoPlayCutin" />
        <div><div></div></div>
        </div>
      </label>
    </div>
  </div>
  <div class="flex-item">
    <ng-container *ngIf="!isEmpty || selectedTable">
      <div class="images-select">
        <div>
          画像
          <div *ngIf="!tableBackgroundImage.url">
            <button (click)="openBgImageModal()" style="height: 120px;" [attr.disabled]="!isEditable ? '' : null">テーブル画像を追加</button>
          </div>
          <div (click)="openBgImageModal()" style="position: relative; overflow: hidden">
            <img height="120" [src]="tableBackgroundImage.url | safe: 'resourceUrl'">
          </div>
        </div>
        <div>
          背景
          <div *ngIf="!tableDistanceviewImage.url">
            <button (click)="openDistanceViewImageModal()" style="height: 120px;" [attr.disabled]="!isEditable ? '' : null">背景画像を追加</button>
          </div>
          <div *ngIf="tableDistanceviewImage.url" style="position: relative; overflow: hidden" (click)="openDistanceViewImageModal()">
            <img height="120" [src]="tableDistanceviewImage.url | safe: 'resourceUrl'">
          </div>
        </div>
      </div>
      <div>Name :
        <input [(ngModel)]="tableName" placeholder="Name" [attr.disabled]="!isEditable ? '' : null" />
      </div>
      <div>
        <input [(ngModel)]="tableWidth" type="range" title="{{tableWidth}}" min="{{minSize}}" max="{{maxSize}}" name="tableWidth" [attr.disabled]="!isEditable ? '' : null"
        /> Width :
        <input [(ngModel)]="tableWidth" type="number" min="{{minSize}}" max="{{maxSize}}" style="width: 5em;" [attr.disabled]="!isEditable ? '' : null">
      </div>
      <div>
        <input [(ngModel)]="tableHeight" title="{{tableHeight}}" type="range" min="{{minSize}}" max="{{maxSize}}" name="tableHeight" [attr.disabled]="!isEditable ? '' : null"
        /> Height :
        <input [(ngModel)]="tableHeight" type="number" min="{{minSize}}" max="{{maxSize}}" style="width: 5em;" [attr.disabled]="!isEditable ? '' : null">
      </div>
      <div>
        グリッド:
        <select [(ngModel)]="tableGridType" name="tableGridType" [attr.disabled]="!isEditable ? '' : null">
          <option value="-1">なし</option>
          <option value="0">スクエア</option>
          <option value="1">ヘクス（縦揃え）</option>
          <option value="2">ヘクス（横揃え）</option>
        </select>
        色:
        <select [(ngModel)]="tableGridColor" name="tableGridColor" [attr.disabled]="!isEditable ? '' : null">
          <option value="#000000e6">黒</option>
          <option value="#dddddde6">白</option>
        </select>
      </div>
      <div>
        背景フィルタ
        <select [(ngModel)]="tableDistanceviewFilter" name="tableDistanceviewFilter" [attr.disabled]="!isEditable ? '' : null">
          <option value="">なし</option>
          <option value="white">ホワイト</option>
          <option value="black">ブラック</option>
        </select>
      </div>
      <div>
        <div>
          初期BGM
          <select [(ngModel)]="sceneBGM" name="sceneBGM" [attr.disabled]="!isEditable ? '' : null">
            <option value="">なし</option>
            <option value="--stop--">停止</option>
            <option *ngFor="let audio of audios" value="{{audio.identifier}}">{{ audio.name }}</option>
          </select>
        </div>
        <div>
          カットイン
          <select [(ngModel)]="sceneCutin" name="sceneCutin" [attr.disabled]="!isEditable ? '' : null">
            <option value="">なし</option>
            <option *ngFor="let cutIn of cutIns" value="{{ cutIn.uniqueIdentifier }}">{{ cutIn.name }}</option>
          </select>
        </div>
      </div>
      <hr/>
      <div>
        <label class="self-setting">
          グリッドを常に表示<sup class="self-only">*</sup>
          <div class="toggle">
          <input type="checkbox" [(ngModel)]="tableGridShow" name="tableGridShow" />
          <div><div></div></div>
          </div>
        </label>
        <label class="self-setting">
          スナップ<sup class="self-only">*</sup>
          <div class="toggle">
          <input type="checkbox" [(ngModel)]="tableGridSnap" name="tableGridSnap" />
          <div><div></div></div>
          </div>
        </label>
        <ng-container *ngIf="!isDeleted">
          <button *ngIf="!roomService.disableSeparateDataSave" [disabled]="isSaveing" (click)="save()">
            <div style="position: relative;">
              <div [ngClass]="{'is-hidden': isSaveing}">保存</div>
              <div *ngIf="isSaveing" class="progress">{{progresPercent}}%</div>
            </div>
          </button>
          <button class="danger" (click)="delete()" [attr.disabled]="getGameTables().length <= 1 ? '' : null">削除</button>
        </ng-container>
        <ng-container *ngIf="isDeleted">
          <button (click)="restore()">元に戻す</button>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
<ng-container *ngIf="isEmpty">
  <div style="font-size: 12px;">※テーブルが１つも作成されていません。「新しいテーブルを作る」から新しいテーブルを作成することができます。</div>
</ng-container>
