<div class="flex-container flex-column">
  <div class="flex-column metal-background">
    <div class="chat-tab-background flex-row" style="height: 46px;">
      <div class="flex-column control small-font" style="width:8em;">
        <span>チャットと同期</span>
        <div class="toggle"><label>
          <input name="showPalette" type="checkbox" [(ngModel)]="isSyncChatWindow">
          <div><div></div></div>
        </label></div>
      </div>
      <ng-container *ngIf="!isSyncChatWindow; else syncWindow" >
        <form  class="chat-tab scroll-white" style="width:calc(100% - 84px);">
          <label *ngFor="let chatTab of chatMessageService.controledChatTabList">
            <input name="chat-tab" type="radio" value="{{chatTab.identifier}}" ng-control="options" [disabled]="!chatTab.isAllowed" [ngModelOptions]="{ standalone: true }" [(ngModel)]="chatTabidentifier">
            <div>
              <span *ngIf="chatTab.isLock"><i class="material-icons" style="font-size:0.8em;;color: #FFF;">lock</i></span>
              {{chatTab.name}}
            </div>
          </label>
        </form>
      </ng-container>
    </div>
    <div class="flex-nogrow">
      <chat-input [isBlack]="isBlack" [chatTabidentifier]="chatTabidentifier" [isPalette]="true"
      [sendFrom]="sendFrom" [(text)]="text" (chat)="sendChat($event)" #chatInput></chat-input>
    </div>
    <div *ngIf="!disableControl">
      <player-palette-control [sendFrom]="sendFrom" (chat)="sendChat($event)"></player-palette-control>
    </div>
  </div>
  <div #characterSelect class="character-select scroll-white">
    <label>
      <img *ngIf="myImage" class="imgicon" [ngClass]="{'select-img': selectCharacter === myPlayerId + ',-1'}" [src]="myImage.url | safe: 'resourceUrl'" />
      <input type="radio" name="chara-tab" [value]="myPlayerId + ',-1'" [(ngModel)]="selectCharacter">
      <div>{{myPlayer.name}}</div>
    </label>
    <ng-container *ngFor="let gameCharacter of characterPaletteList">
      <label>
        <img *ngIf="gameCharacter.imageFile?.url.length > 0" class="imgicon" [ngClass]="{'select-img': sendFrom === gameCharacter.identifier}"
          [src]="gameCharacter.imageFile.url | safe: 'resourceUrl'" />
        <input type="radio" name="chara-tab" [value]="gameCharacter.identifier + ','" [(ngModel)]="selectCharacter">
        <div  (contextmenu)="displayContextMenu($event,gameCharacter); false">{{gameCharacter.name}}</div>
      </label>
      <label *ngFor="let palette of gameCharacter.subPalette.palettes">
        <img *ngIf="gameCharacter.imageFile?.url.length > 0" class="imgicon" [ngClass]="{'select-img': sendFrom === gameCharacter.identifier}"
        [src]="gameCharacter.imageFile.url | safe: 'resourceUrl'" />
        <input type="radio" name="chara-tab" [value]="gameCharacter.identifier + ',' + palette.identifier" [(ngModel)]="selectCharacter">
        <div  (contextmenu)="displayContextMenu($event,gameCharacter); false">{{palette.paletteLines[0].palette}}</div>
      </label>
    </ng-container>
  </div>
  <div *ngIf="showPalette && !isEdit" class="chat-pallete">
    <select [class]="bgColor" class="palette scroll-white" style="overflow-x: hidden;" size="5" (input)="selectPalette($event.target.value)" (click)="clickPalette($event.target.value)" #chatPlette>
      <ng-container *ngIf="palette">
        <ng-container *ngFor="let palette of palette.getPalette()">
          <option value="{{palette}}" [ngStyle]="{'color':color}">{{palette}}</option>
        </ng-container>
      </ng-container>
    </select>
  </div>
  <div *ngIf="showPalette && isEdit" style="flex-grow: 1; height: 0; min-height: 100px;">
    <textarea [class]="bgColor" class="palette scroll-white" style="resize: none" [(ngModel)]="editPalette" placeholder="チャットパレット"></textarea>
  </div>
  <div class="bottom flex-between">
    <div class="flex-row">
      <div class="flex-column control small-font">
        <span>チャットパレット</span>
        <div class="toggle"><label>
          <input name="showPalette" type="checkbox" [(ngModel)]="showPalette">
          <div><div></div></div>
        </label></div>
      </div>
      <div class="control flex-nogrow">
        <button *ngIf="showPalette" type="submit" (click)="toggleEditMode()">
          <span *ngIf="!isEdit">編集</span>
          <span *ngIf="isEdit">確定</span>
        </button>
        <button *ngIf="showPalette && character" (click)="addPalette()">
        追加
        </button>
        <button *ngIf="showPalette && paletteIdentifier" (click)="removePalette()">
        削除
        </button>
      </div>
    </div>
    <div class="flex-row">
      <div class="flex-column control small-font">
        <span>キャラクターリスト</span>
        <select style="width: 10em" [(ngModel)]="selectedCharacter">
          <option value="default">-追加対象の選択-</option>
          <option value="create">-新規作成(簡易)-</option>
          <option *ngFor="let gameCharacter of gameCharacters" style="font-weight: normal;" value="{{gameCharacter.identifier}}">{{gameCharacter.name}}</option>
        </select>
      </div>
      <button (click)="addList()">追加</button>
      <button (click)="removeList(sendFrom)">削除</button>
    </div>
  </div>
</div>
<ng-template #syncWindow>
  <span style="font-size: 12px;">選択されたチャットタブ - {{chatTab ? chatTab.name : ''}}</span>
</ng-template>
