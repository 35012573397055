import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'help-character',
  templateUrl: './help-character.component.html',
  styleUrls: ['../help.content.css']
})
export class HelpCharacterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
