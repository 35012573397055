<div class="container">
  <div id="menu" class="menu">
    <div id="menu" class="left">
      <button class="select" [ngClass]="{'select-off':  selectMenu == 'file' }"
       (click)='openSubMenu($event,"file")'>
        <span *ngIf="!minimumMode; else miniFile">ファイル{{isOpen("file")}}
        </span>
      </button>
      <button class="select" [ngClass]="{'select-off':  selectMenu == 'room' }"
       (click)='openSubMenu($event,"room")'>
        <span *ngIf="!minimumMode; else miniRoom">ルーム{{isOpen("room")}}
        </span>
      </button>
      <button class="select" [ngClass]="{'select-off':  selectMenu == 'chat' }"
       (click)='openSubMenu($event,"chat")'  (contextmenu)="chatOpen($event); false">
        <span *ngIf="!minimumMode; else miniChat">チャット{{isOpen("chat")}}
        </span>
     </button>
      <button class="select"  [ngClass]="{'select-off':  selectMenu == 'data' }"
       (click)='openSubMenu($event,"data")'  (contextmenu)="inventoryOpen($event); false">
        <span *ngIf="!minimumMode; else miniData">リスト{{isOpen("data")}}
        </span>
      </button>
      <button class="select" [ngClass]="{'select-off':  selectMenu == 'work' }"
       (click)='openSubMenu($event,"work")'>
        <span *ngIf="!minimumMode; else miniWork">機能{{isOpen("work")}}
        </span>
      </button>
      <button class="select" [ngClass]="{'select-off':  selectMenu == 'view' }"
       (click)='openSubMenu($event,"view")'>
        <span *ngIf="!minimumMode; else miniView">表示{{isOpen("view")}}
        </span>
      </button>
      <button class="select"
       (click)='roundAdd($event)'  (contextmenu)="roundContext($event); false" >
       {{roundText}}
      </button>
    </div>
    <div class="right">
      <div class="select" [ngClass]="{'boxAlert': isAlert }" style="line-height: 24px;" (contextmenu)="roomMenu($event); false">{{roomInfo}}</div>
      <button class="select" *ngIf="!minimumMode"  (click)='menuHelp()'>？</button>
    </div>
  </div>
  <div id="app-table-layer" class="game-table">
      <game-table *ngIf="!isFlat;else flat"></game-table>
  </div>
</div>
<div #modalLayer></div>
<div #subMenu class="subMenu">
  <ng-container [ngSwitch]="selectMenu">
    <ng-container *ngSwitchCase="''">
    </ng-container>
    <ng-container *ngSwitchCase="'view'">
      <view-setting (closeMe)="closeSub()"></view-setting>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <sub-menu [selectMenu]="this.selectMenu" (closeMe)="closeSub()"></sub-menu>
    </ng-container>
  </ng-container>
</div>
<div class="ui-tray">
  <ui-tray></ui-tray>
</div>
<ng-template #flat>
   <game-table-flat></game-table-flat>
</ng-template>
<ng-template #miniFile>
  <i class="material-icons">
    sd_storage
  </i>
</ng-template>
<ng-template #miniRoom>
  <i class="material-icons">
    people
  </i>
</ng-template>
<ng-template #miniChat>
  <i class="material-icons">
    speaker_notes
  </i>
</ng-template>
<ng-template #miniData>
  <i class="material-icons">
    folder_shared
  </i>
</ng-template>
<ng-template #miniWork>
  <i class="material-icons">
    settings
  </i>
</ng-template>
<ng-template #miniView>
  <i class="material-icons">
    emoji_objects
  </i>
</ng-template>
