<div class="container" (contextmenu)="displayContextMenu($event)">
  <div *ngFor="let character of characters; trackBy: trackByCharacter" class="card"
    [ngClass]="{'black-paint': character.isBlackPaint}"
    [ngStyle]="{
      'background-image': 'url(' + character.imageFile?.url + ')' ,
      'background-position': character.imageFile?.aspect > 1.25 ?
        character.imageFile?.aspect > 1.75 ? 'center 25% ' : 'center 15% '
      : 'center 10%'
    }" [appTooltip]="character">
    <div *ngIf="initiative" class="init-count">
      <span style="transform: translateY(-1px);">{{initDataElm(character.identifier)}}</span>
    </div>
    <div class="data-container">
      <ng-container *ngIf="!character.hasOwner || character.isTransparent ; else forbidden" >
        <div class="data" *ngFor="let dataElm of dataElms(character.identifier)">
          <ng-container *ngIf="dataElm && dataElm.name.length < 7">
            <ng-container *ngTemplateOutlet="swDataElm; context:{DataElm :dataElm}">
            </ng-container>
          </ng-container>
          <ng-container *ngIf="dataElm && dataElm.name.length >= 7">
            <ng-container *ngTemplateOutlet="swDataElmShort; context:{DataElm :dataElm}">
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #swDataElm let-dataElm="DataElm">
  <ng-container [ngSwitch]="dataElm.type">
    <ng-container *ngSwitchCase="'simpleNumber'">
      {{dataElm.name}}: {{dataElm.value}}
    </ng-container>
    <ng-container *ngSwitchCase="'numberResource'">
      {{dataElm.name}}: {{dataElm.currentValue}}/{{!+dataElm.value ? '???' : dataElm.value}}
    </ng-container>
    <ng-container *ngSwitchCase="'checkProperty'">
      {{dataElm.name}} <input type="checkbox" style="width: 2em;" checked="{{dataElm.value ? 'checked' : ''}}">
    </ng-container>
    <ng-container *ngSwitchCase="'abilityScore'">
      {{dataElm.name}}: {{dataElm.value}}
    </ng-container>
    <ng-container *ngSwitchDefault>
      {{dataElm.name}}: {{dataElm.value}}
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #swDataElmShort let-dataElm="DataElm">
  <ng-container [ngSwitch]="dataElm.type">
    <ng-container *ngSwitchCase="'simpleNumber'">
      {{dataElm.value}}
    </ng-container>
    <ng-container *ngSwitchCase="'numberResource'">
      {{dataElm.currentValue}}/{{!+dataElm.value ? '???' : dataElm.value}}
    </ng-container>
    <ng-container *ngSwitchCase="'checkProperty'">
      {{dataElm.name}} <input type="checkbox" style="width: 2em;" checked="{{dataElm.value ? 'checked' : ''}}">
    </ng-container>
    <ng-container *ngSwitchCase="'abilityScore'">
      {{dataElm.value}}
    </ng-container>
    <ng-container *ngSwitchDefault>
      {{dataElm.value}}
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #forbidden>
  <div class="data">非公開</div>
</ng-template>
