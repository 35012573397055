<div class="container">
  <div class="title">立ち絵</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/stand.png">
    </div>
    <div class="description">
      キャラクターを選択してチャットメッセージを送信すると、立ち絵が表示されます。<br/>
      チャットテキストが「」で囲まれていた場合、立ち絵が吹き出しで喋ります。<br/>
      チャットテキストの末尾が"@退去"または"@farewell"の場合は、<br/>
      そのキャラクターの立ち絵を退去させます。<br/>
      <br/>
      優先順位は高いものから<br/>
      １. "@退去"、"@farewell"による退去<br/>
      ２. チャットウィンドウ、チャットパネルのリストで選択した名前<br/>
      ３. 「指定画像 かつ チャット末尾」<br/>
      ４. 「指定画像 または チャット末尾」<br/>
      ５. 「チャット末尾」<br/>
      ６. 「指定画像」<br/>
      <br/>
      どの条件も満たさない場合「デフォルト」のものが使用され、<br/>
      同じ優先順位の条件が複数ある場合はランダムで1つが選択されます。<br/>
      チャット末尾一致を判定する際、全角半角、アルファべットの大文字小文字は区別されません。<br/>
      また、"@退去"、"@farewell"による退去時、<br/>
      あるいは"@笑い"のように先頭が"@"で始まる条件を設定している場合、<br/>
      （立ち絵の有効無効、条件を満たすかに関わらず）そのキャラクターでの送信時に、<br/>
      条件に一致するチャットテキスト末尾の@以下は切り落とされます。<br/>
      <br/>
    </div>
  </div>
  <div class="title">立ち絵設定(任意のキャラクター右クリック→立ち絵設定)</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/stand-setting.png">
    </div>
    <div class="description">
      キャラクターの立ち絵の名前、位置と画像の高さ<br/>
      （それぞれ画面サイズに対する相対指定）、<br/>
      チャット送信時に立ち絵が表示される条件を設定できます。<br/>
      <br/>
      立ち絵に名前を設定した場合、<br/>
      チャットウィンドウ、チャットパネルのリストに表示され、選択可能になります。<br/>
      また、タグを設定した場合、<br/>
      異なるタグでは同じキャラクターであっても登場/退去のアニメーションが行われます。<br/>
      <br/>
      画像の位置と高さは個別指定も可能です。<br/>
      位置の個別指定はチェックなし、高さは0とした場合に全体の設定が使用されます。<br/>
      縦位置調整(AdjY)は、立ち絵画像の高さに対する相対指定となります。<br/>
      （例えば、-50%とすると画像の下半分が画面端より下に隠れます）。<br/>
      条件の「指定画像」はチャット送信時のキャラクター画像あるいは顔ICです。<br/>
    </div>
  </div>
  <div class="title">立ち絵表示設定(表示→立ち絵表示設定)</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/stand-view.png">
    </div>
    <div class="description">
      立ち絵の表示範囲の設定と、現在設定されている立ち絵位置の確認ができます。<br/>
      <br/>
      パネルの横幅がそのまま立ち絵の表示範囲になり、<br/>
      パネルの下端が立ち絵が表示される高さになります。<br/>
      これは、パネルの幅を変更したり、ドラッグすることで変更可能です。<br/>
      <br/>
      パネル内で立ち絵が表示されている場所がその立ち絵の表示位置になります。<br/>
    </div>
  </div>
</div>