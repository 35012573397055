<ng-container *ngIf="!isEdit; else edit">
  <ng-container *ngIf="isTransparent; else forbidden">
    <button class="toggleSW" (click)="toggleMode()">
      <span *ngIf="mode === 'status'">ステータス</span>
      <span *ngIf="mode === 'resource'">リソース</span>
    </button>
    <ng-container *ngIf="mode === 'status'">
      <ng-container *ngTemplateOutlet="status">
      </ng-container>
    </ng-container>
    <ng-container *ngIf="mode === 'resource'">
      <ng-container *ngTemplateOutlet="resource">
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #status>
  <div class="frame" *ngFor="let dataElm of inventoryDataElms">
    <ng-container *ngIf="dataElm && dataElm.name !== newLineString">
      <ng-container *ngTemplateOutlet="dataElement; context: { dataElm: dataElm }">
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #resource>
  <div class="resource-control">
    <select style="font-size:1em" [ngModelOptions]="{standalone: true}" [(ngModel)]="resourceTitle">
      <option *ngFor="let name of limitResource.names" value="{{name}}">{{name}}</option>
    </select>
    <button class="reset" (click)="resetResource()">回数をリセット</button>
  </div>
  <limit-resource-control class="resource" *ngFor="let dataElm of currentResouce" [characterName]="gameCharacter.name" [dataElement]="dataElm" (chat)="sendChat($event)"></limit-resource-control>
</ng-template>

<ng-template #dataElement let-dataElm="dataElm">
  <ng-container *ngIf="0 < dataElm.children.length; then thenBlock; else elseBlock"></ng-container>
  <ng-template #thenBlock>
    <div class="section">
      <div *ngIf="0 < dataElm.name.trim().length">{{dataElm.name}}</div>
      <div class="grid-container">
        <ng-container *ngFor="let childElm of dataElm.children">
          <ng-container *ngTemplateOutlet="dataElement; context: { dataElm: childElm }"></ng-container>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <ng-template #elseBlock>
    <data-element [dataElement]="dataElm" [viewOnly]="true" (click)="setDataElm($event,dataElm)"></data-element>
  </ng-template>
</ng-template>

<ng-template #edit>
  <ng-container *ngIf="isString;else NumberEdit">
    <ng-template [ngTemplateOutlet]="StringEdit"></ng-template>
  </ng-container>
</ng-template>

<ng-template #NumberEdit>
  <div class="edit">
    <data-element style="margin-right: 8px;" [dataElement]="selectElm" [viewOnly]="true"></data-element>
    <div>
    <textarea class="rc" style="height:1.6em;width:30em;" placeholder='文頭に+で加算、-で減算、他は計算後の数値で置き換え' [(ngModel)]="innerText" (keydown.enter)="sendCalc($event)"
          #textArea></textarea>
    </div>
    <button style="height:2em; padding:2px 4px; "  (click)="sendCalc(null)">SEND</button>
    <button style="height:2em; padding:2px 4px; "  (click)="cancelEdit()">戻る</button>
  </div>
</ng-template>

<ng-template #StringEdit>
  <div class="edit">
    <span style="margin-right: 8px;">{{selectElm.name}}:</span>
    <textarea class="rc" style="height:1.6em;width:30em;" [(ngModel)]="selectElm.value" (keydown.enter)="cancelEdit()"
          #textArea></textarea>
    <button style="height:2em; padding:2px 4px; "  (click)="cancelEdit()">確定</button>
  </div>
</ng-template>

<ng-template #forbidden>
  非公開
</ng-template>
