<div class="box">
  <div class="sendFrom">
    <div class="info">
      チャットメッセージ編集モード：Escキーでキャンセル・Enterキーで 保存
    </div>
  </div>
  <div class="rightObject">
    <div class="chatSend">
      <textarea class="chat-input" [(ngModel)]="text" style=" width: calc(100% - 250px);" [ngClass]="{'nonblack': !isBlack}"
        [ngModelOptions]="{standalone: true}" (keydown.enter)="save()" (keydown.esc)="cancel()" #textArea>
      </textarea>
      <button class="send" (click)="save()"><b>SAVE</b></button>
      <button class="send" (click)="cancel()"><b>CANCEL</b></button>
    </div>
  </div>
</div>
