<div class="selecter">
  <div *ngIf="!touched && !isLock ;else images">
    <select name="sendFrom" class="select" (change)="touch()" [(ngModel)]="sendFrom"
     [ngStyle]="{'background-color':isBlack ? '#212121' : '#FFF'  ,'color': getColor() , 'font-weight': (sendFrom != myPlayerId ? 'normal' : 'bolder')}">
       <option value="{{myPlayerId}}" [style.color]="colorValication(myColor)" style="font-weight: bolder;">{{player?.name}}（あなた）</option>
       <option *ngFor="let gameCharacter of gameCharacters"
          [style.color]="colorValication(this.gameCharacterService.color(gameCharacter.identifier))"
         value="{{gameCharacter.identifier}}">{{gameCharacter.name}}
       </option>
    </select>
  </div>
</div>
<div class="setting">
  <chat-input-setting  [character]='character' (chatSetting)='_chatSetting($event)'></chat-input-setting>
</div>
<ng-template #images>
  <img *ngIf="0 < imageFile?.url?.length && auraStyle" class="image aura" [ngStyle]="auraStyle" [src]="imageFile.url | safe: 'resourceUrl'">
  <img *ngIf="0 < imageFile?.url?.length" class="image front-image" [ngStyle]="imgStyle" [src]="imageFile.url | safe: 'resourceUrl'" />
  <div class="text-space" (click)="canSelect()">
    <div *ngIf="!character;else characterName"><span class="name" [style.color]="getColor()" [style.backgroundColor]="isBlack ? 'rgba(30,30,30,0.8)' : 'rgba(200,200,200,0.8)'">
      {{player?.name}}
    </span><span class="tag">▼</span></div>
  </div>
</ng-template>
<ng-template #characterName>
  <span class="name" [style.color]="getColor()" [style.backgroundColor]="isBlack ? 'rgba(30,30,30,0.8)' : 'rgba(200,200,200,0.8)'">
    {{character.name}}
  </span>
  <span class="tag">▼</span>
</ng-template>
