<div class="dummy-will-change" appMovable [movable.option]="movableOption">
</div>
<div class="component is-3d" [ngClass]="{'is-grab': !isLock}" style="pointer-events: none"
  [style.width.px]="width * gridSize" [style.height.px]="height * gridSize"
  appMovable [movable.option]="movableOption" [movable.disable]="isLock" (movable.ondragstart)="onMove()" (movable.ondragend)="onMoved()">
  <div class="is-3d text" style="left: 2px; top: 2px" [ngClass]="{fall: altitude == 0}"
    [style.width.px]="width * gridSize - 4" [style.height.px]="height * gridSize - 4"
    [ngStyle]="{'transform': 'translateZ(' + (altitude * gridSize) + 'px)'}">
    <div class="is-3d" [ngClass]="{'is-inverse': 90 < viewRotateZ % 360 && viewRotateZ % 360 < 270}" [style.font-size.px]="fontSize + 9" [style.color]="color" style="z-index: 5; pointer-events: none" [innerHtml]="rubiedText"></div>
  </div>
  <div class="border is-fit-full is-3d"
    style="pointer-events: auto" [style.opacity]="opacity"
    [style.background-color]="imageFile.url.length < 1 || blendType > 0 ? bgcolor : null"
    [ngClass]="{fall: altitude == 0, image: imageFile.url.length >= 1}"
    [ngStyle]="{'transform': 'translateZ(' + (altitude * gridSize) + 'px)'}">
    <img *ngIf="imageFile.url.length >= 1" class="is-fit-full" style="pointer-events: none" [style.mix-blend-mode]="blendType == 1 ? null : 'hard-light'" [src]="imageFile.url | safe: 'resourceUrl'">
  </div>
  <div *ngIf="isLock" class="lock-icon is-center is-3d" style="pointer-events: none; z-index: 10" [ngClass]="{fall: altitude == 0}" [ngStyle]="{'transform': 'translateZ(' + (altitude * gridSize) + 'px)'}">
    <i class="material-icons is-3d" style="pointer-events: none">lock</i>
  </div>
  <div class="is-3d" *ngIf="isAltitudeIndicate && math.abs(gameTableMaskAltitude) >= 0.5"
    style="display: flex; align-items: center; border-left: 2px dashed blanchedalmond; transform-origin: top left; pointer-events: none"
    [style.height.px]="math.abs(gameTableMaskAltitude * gridSize)"
    [ngStyle]="{transform: 'translateX(' + (width * gridSize / 2) + 'px) translateY(' + (-height * gridSize / 2) + 'px) rotateX(-90deg) translateY(' + (altitude > 0 ? -altitude * gridSize : 0) + 'px) rotateY(' + (viewRotateZ) + 'deg)'}">
    <div class="is-3d" style="pointer-events: none">
      <span class="altitude-indicator is-3d" style="position: absolute; pointer-events: none">{{ math.abs(gameTableMaskAltitude) }}</span>
      <span class="altitude-indicator is-3d" style="position: absolute; pointer-events: none; transform: rotateY(-180deg)">{{ gameTableMaskAltitude }}</span>
    </div>
</div>
