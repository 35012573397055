<div class="container">
  <div class="title">メモ(任意のキャラクターを右クリック→メモ)</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/character-memo.png">
    </div>
    <div class="description">
      通常のステータスとは別に、フリーテキスト形式でメモを記載できます。<br/>
      メモはオーバービュー時にステータスと切り替えて表示可能です。<br/>
    </div>
  </div>
  <div class="title">エフェクト(機能→エフェクト)</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/character-effect.png">
    </div>
    <div class="description">
      キャラクターに３Dエフェクトを表示することが可能です<br/>
      表示方法はエフェクトビューを開き、表示したいエフェクトを選択後、<br/>
      エフェクトを付与をクリックし付与したいキャラクターをクリック。<br/>
      またはチャット末尾に@effect(エフェクト名)で表示されます<br/>
    </div>
  </div>
  <div class="title">チャットからのリソース操作</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/character-resource.png">
    </div>
    <div class="description">
      チャットからリソースを操作することが可能です<br/>
      書式:<br/>
      <div style="margin-left: 1em;">
        :[ステータス名]c(計算式)<br/>
        :[ステータス名]=(ダイスコマンド)<br/>
        :[ステータス名]+(ダイスコマンド)<br/>
        :[ステータス名]-(ダイスコマンド)<br/>
      </div>
      <br/>
      例)<br/>
      <span style="margin-left: 1em;">
        :HP-2d6<br/>
      </span>
      <br/>
      C(計算式)と＝はステータスの値を出力結果で置き換えます。<br/>
      ＋とーはステータスの値に加算・減算します。<br/>
      <br/>
      ステータス名はデータが数値と扱えるものであればなんでも指定可能です。<br/>
      ダイスコマンドはbcdiceから出力される結果が数値であれば使用可能です。<br/>
      各システム用のコマンドでも問題ありません。<br/>
      (最終結果が数値である必要はありません。<br/>
      末尾に 成功 や クリティカル とついていても、過程に数値が出力されていれば大丈夫です。)<br/>
    </div>
  </div>
</div>
