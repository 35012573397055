<div class="container">
  <div class="title">ゲームキャラクターのキャラクターシート</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/sheet-character.png">
    </div>
    <div class="description">
      キャラクターシート内でデータの設定、立ち絵の設定、<br/>
      メモの記入、チャットパレットの設定、消耗リソースの設定、<br/>
      カウンターの状況確認が可能です。<br/>
      <br/>
      新しいデータ項目を追加する場合、「編集」状態にする必要があります。<br/>
    </div>
  </div>
  <div class="title">その他のオブジェクトのキャラクターシート</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/sheet-other.png">
    </div>
    <div class="description">
      新しいデータ項目を追加・画像の変更は「編集」状態でのみ可能です。<br/>
    </div>
  </div>
  <div class="title">データ項目の編集</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/sheet-data.png">
    </div>
    <div class="description">
      各項目の右上の編集アイコンをクリックすることで、編集モードへ移行できます。<br/>
      <br/>
      また、上部メニューの編集をクリックすることで項目を追加できます。<br/>
      
      「要素をリソースに変更」をクリックするとその項目以下のデータが全てリソース管理に移行されます。<br/>
      3階層以上ある場合は自動的に2階層に変換されます。<br/>
    </div>
  </div>
  <div class="title">画像設定</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/sheet-image.png">
    </div>
    <div class="description">
      ゲームキャラクターの画像は専用の設定ページで設定します。<br/>
      <br/>
      ここでは「キャラクターイメージ」「アイコン」「シャドウイメージ」を設定できます。<br/>
      <br/>
      「追加」ボタンを押すことで画像を追加できます。<br/>
      画像をシングルクリックで使用するイメージを選択、<br/>
      ダブルクリックすることで画像を変更することが可能です。<br/>
    </div>
  </div>
  <div class="title">リソース管理</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/sheet-resource.png">
    </div>
    <div class="description">
      アイテムや回数制のリソースを管理するための機能です。<br/>
      <br/>
      項目ごとにデータを設定しておくことで、チャットウィンドウからリソースを使用できます。<br/>
    </div>
  </div>
</div>
