<div class="component">
  <div [@bgInOut]="'in'" class="modal-background" (click)="clickBackground($event)"></div>
  <div [@flyInOut]="'in'" class="modal-panel" [style.width]="width ? width  + 'px' : 'auto'" [style.height]="height ? height + 'px' : 'auto'">
    <div class="title">
      <div class="title-button">
        <button (click)="resolve()"><i class="material-icons" style="font-size: 14px;">close</i></button>
      </div>
      {{modalService.title}}
    </div>
    <div class="scrollable-panel">
      <ng-content></ng-content>
      <div #content></div>
    </div>
    <!--<div class="footer"><button (click)="resolve()">OK</button><button (click)="resolve()">CANCEL</button></div>-->
  </div>
</div>