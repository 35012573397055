<div class="dummy-will-change" appMovable [movable.option]="movableOption">
</div>
<div class="component is-3d is-pointer-events-none"
  appMovable [movable.option]="movableOption" [movable.disable]="isSelected || isLocked" (movable.ondragstart)="onMove()" (movable.ondragend)="onMoved()">
  <div class="is-3d" appRotable [rotable.option]="rotableOption" [rotable.disable]="isLocked" (rotable.ondragstart)="onMove()" (rotable.ondragend)="onMoved()">
    <div class="component-content is-3d is-pointer-events-auto"
      [style.width.px]="width * gridSize">
      <div class="upright-transform is-3d is-bg-color is-bg-texture" [ngStyle]="{'transform': 'translateZ(' + (altitude * gridSize) + 'px) rotateY(90deg) rotateZ(' + (isUpright ? -90 : 0) + 'deg) rotateY(-90deg)' , 'height' : isSizeLocked ? height * gridSize + 'px' : 'auto' }"
        [style.background-image]="'url(' + imageFile?.url + ')' | safe: 'style'" [style.width.px]="width * gridSize" [style.min-height.px]="height * gridSize">
        <div *ngIf="title.length" class="title is-black-background is-pointer-events-none">{{title}}</div>
        <textarea #textArea (input)="calcFitHeight();" class="textarea is-3d" [ngClass]="{'is-pointer-events-none': !isSelected, 'is-pointer-events-auto': isSelected, 'hide-area': !isSelected}"
          [style.font-size.px]="fontSize + 9" [(ngModel)]="text" spellcheck="false"></textarea>
        <div (input)="calcFitHeight();" class="is-3d is-outline-text note-text" style="margin-top:-18px" [ngClass]="{'hide-area': isSelected}"
          [style.font-size.px]="fontSize + 9" [innerHTML]="lastNewLineAdjust(rubiedText)"></div>
      </div>
      <div *ngIf="!isLocked" [ngClass]="{'is-grab': !isLocked}" class="grand-grabing-area" style="position: absolute"
        [ngStyle]="{'transform': 'translateX(' + gridSize / 2 + 'px) translateY(' + (-gridSize / 4) + 'px)'}"
        [style.width.px]="(width - 1) * gridSize"
        [style.min-height.px]="gridSize / 2"></div>
      <div [ngStyle]="{'transform': 'translateZ(' + (altitude * gridSize) + 'px)'}">
        <div class="rotate-grab of-left-top">
          <i class="material-icons">{{ isLocked ? 'lock' : 'autorenew' }}</i>
        </div>
        <div class="rotate-grab of-right-top">
          <i class="material-icons">{{ isLocked ? 'lock' : 'autorenew' }}</i>
        </div>
      </div>
      <div *ngIf="!isLocked">
        <div class="rotate-grab of-left-top">
          <i class="material-icons">autorenew</i>
        </div>
        <div class="rotate-grab of-right-top">
          <i class="material-icons">autorenew</i>
        </div>
      </div>
    </div>
  </div>
  <div class="is-3d" *ngIf="isAltitudeIndicate && math.abs(textNoteAltitude) >= 0.5"
    style="display: flex; align-items: center; border-left: 2px dashed blanchedalmond; transform-origin: top left"
    [style.height.px]="math.abs(textNoteAltitude * gridSize)"
    [ngStyle]="{transform: 'rotateX(-90deg) translateY(' + (altitude > 0 ? -altitude * gridSize : 0) + 'px) rotateY(' + (viewRotateZ) + 'deg)'}">
    <div class="is-3d">
      <span class="altitude-indicator is-3d" style="position: absolute">{{ math.abs(textNoteAltitude) }}</span>
      <span class="altitude-indicator is-3d" style="position: absolute; transform: rotateY(-180deg)">{{ textNoteAltitude }}</span>
    </div>
  </div>
</div>
<ng-template #hideContent>
  <div [ngStyle]="{ 'height' : height * gridSize + 'px'}"></div>
</ng-template>
