<div class="dummy-will-change" appMovable [movable.option]="movableOption">
</div>
<div class="component is-grab is-pointer-events-none" [style.width.px]="size * gridSize" [style.height.px]="size * gridSize"
  appMovable [movable.option]="movableOption" (movable.ondragstart)="onMove()" (movable.ondragend)="onMoved()">
  <div class="component-content" [@bounceInOut]="'in'">
    <div appRotable [rotable.option]="rotableOption" (rotable.ondragstart)="onMove()" (rotable.ondragend)="onMoved()"
      class="component-content is-pointer-events-auto">
      <div class="pedestal-inner" [ngClass]="{'is-gray-border': hasOwner}"></div>
      <div class="pedestal-outer"></div>
      <ng-container *ngIf="!isIconHidden">
      <div class="pedestal-grab rotate-grab">
        <div class="pedestal-grab-border">
          <div class="material-icons of-front">autorenew</div>
        </div>
      </div>
      </ng-container>
      <div class="component-content">
        <div class="is-fit-width" >
          <div class="rotate-frame" [style.width.px]="size * gridSize" [style.height.px]="size * gridSize">
            <div class="rotate-inner">
              <div [@changeFaceNameTag]="isVisible ? face : null" [@diceRollNameTag]="animeState" (@diceRollNameTag.done)="animationShuffleDone($event)" style="transform-origin: center bottom;">
                <div *ngIf="0 < name.length" class="name-tag is-nowrap is-black-background" [ngStyle]="{'transform' :'translateX(-50%) translateX(' + size * gridSize / 2 +'px)'}">
                  <div class="fornt-tag"><i *ngIf="isLock && hasOwner" class="material-icons lock-icon">lock</i>{{name}}:<span *ngIf="isVisible">{{face}}</span><span *ngIf="!isVisible">???</span></div>
                </div>
              </div>
              <div *ngIf="hasOwner && 0 < ownerName.length" class="owner-tag is-nowrap is-black-background" [ngStyle]="{'background-color': ownerColor + 'dd', 'transform': 'translateX(-50%) translateX(' + size * gridSize / 2 +'px)'}">
                <div class="fornt-tag" style="color: #222"><b>{{ownerName}}</b></div>
              </div>
              <div class="component-content"
                [@changeFace]="!isCoin && isVisible ? face : null" [@changeFaceCoin]="isCoin && isVisible ? (face == '裏' ? 0 : 1) : null"
                [@diceRoll]="!isCoin && animeState" [@coinFlip]="isCoin && animeState"
                (@diceRoll.done)="animationShuffleDone($event)" (@coinFlip.done)="animationShuffleDone($event)" [style.transform]="isCoin ? 'rotateX(30deg)' : ''">
                  <img class="image front-face" *ngIf="0 < imageFile.url.length" [src]="imageFile.url | safe: 'resourceUrl'" [ngClass]="{'is-black-mask': !isVisible && !canTransparent, 'is-harf-black-mask': isMine || canTransparent}">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
