<div class="frame">
  <div class="item">
    <div *ngIf="isEdit; else showAuthority">
      <select style="max-width: 140px" [(ngModel)]="dataType">
        <option value=0>全体に公開</option>
        <option value=1>全体に公開(編集不可)</option>
        <option value=2>公開範囲を制限</option>
      </select>
    </div>
    <div style="margin: 1px 6px"></div>
    <div *ngIf="isEdit; else showTitle">
        <input class="title" style="width:100%" type="text" placeholder="件名" [(ngModel)]="title">
    </div>
  </div>
  <div class="item" style="height:50px;" *ngIf="isEdit">
    <div class="peer-select" *ngIf='dataType == 2'>
      <label>許可するプレイヤー</label >
      <select style="max-width: 10em" [(ngModel)]="player">
          <option *ngFor="let player of playerService.otherPlayers" value="{{player.playerId}}" [style.color]="player.color">{{player.name}}
          </option>
      </select>
      <button class="control-text" (click)="addPlayer()">追加</button>
    </div>
    <div class="type-select">
      <label>
        <input name="type-select" type="checkbox" [(ngModel)]="isImage">
        <div><div></div></div>
      </label>
    </div>
  </div>
  <ng-container *ngIf="!isImage ;else imageBox">
    <div class="item">
      <ng-container *ngIf="isEdit; else showText">
        <textarea name="textArea" rows="20" cols="65" [readOnly]="!isEdit"  placeholder="本文" [(ngModel)]="text"></textarea>
      </ng-container>
    </div>
  </ng-container>
  <div class="control">
    <div>
      <p></p>
    </div>
    <div class="buttons">
      <button *ngIf="isEdit&&!card" class="control-text" (click)="create()" >作成</button>
      <button *ngIf="!isEdit&&!readOnly&&!isSecret" class="control-text" (click)="edit()" >編集</button>
      <button *ngIf="isEdit&&card" class="control-text" (click)="remove()" >削除</button>
      <button *ngIf="isEdit&&card" class="control-text" (click)="save()" >保存</button>
      <button class="control-text" (click)="close()">閉じる</button>
    </div>
  </div>
  <div class="allowPlayers">
    <span *ngIf="card">オーナー: {{card.ownerName}}  </span>
    <span>許可されたプレイヤー:</span>
    <div class="player" *ngFor="let player of allowPlayers">
      {{player.playerName}}
      <div class="erase" *ngIf="isEdit" (click)="removePlayer(player.playerId)" >☓</div>
    </div>
  </div>
</div>


<ng-template #showTitle>
  <span class="title" [innerHTML]="titleAsHtml"></span>
</ng-template>
<ng-template #showText>
  <div class="show-text" [innerHTML]="textAsHtml"></div>
</ng-template>
<ng-template #showAuthority>
  <span style="font-size:14px">{{authType[dataType]}}</span>
</ng-template>
<ng-template #imageBox>
  <div class="imageEdit" *ngIf="isEdit ;else image" [style.background-image]="'url(' + imageurl + ')' | safe: 'style' ">
    <button (click)="changeImage()">変更</button>
  </div>
</ng-template>
<ng-template #image>
  <div class="image-area" (click)="viewImg()">
    <img class="image" [src]="imageurl | safe: 'resourceUrl'" />
  </div>
</ng-template>
