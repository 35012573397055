<div class="dummy-will-change" appMovable [movable.option]="movableOption">
</div>
<div class="component is-3d is-pointer-events-none is-grab" [style.width.px]="size * gridSize"
  appMovable [movable.option]="movableOption" (movable.ondragstart)="onMove()" (movable.ondragend)="onMoved()">
  <div @slidInOut class="is-pointer-events-none is-3d">
    <div class="component-content is-pointer-events-auto is-3d"
      appRotable [rotable.option]="rotableOption" (rotable.ondragstart)="onMove()" (rotable.ondragend)="onMoved()">
      <div *ngIf="isFront || canTransparent" @flipOpen class="text is-3d" style="width: 100%; height: 100%" [style.opacity]="canTransparent ? 0.7 : 1.0">
        <div [@inverse]="state" [style.font-size.px]="fontSize + 9" [style.color]="color" class="is-3d" [innerHTML]="rubiedText"></div>
      </div>
      <img [@inverse]="state" *ngIf="imageFile" class="card-image is-3d" [src]="(isFront ? frontImage.url : backImage.url) | safe: 'resourceUrl'">
      <div *ngIf="!isFront && hasOwner && 0 < ownerName.length" class="name-tag is-nowrap has-length-limit is-black-background is-3d" [ngStyle]="{'background-color': ownerColor, 'transform': 'translateY(-75%) translateX(-50%) translateX(' + size * gridSize / 2 +'px)'}">
        <span class="is-3d"><b style="color: #222;">{{ownerName}}</b></span>
      </div>
      <img *ngIf="canTransparent && frontImage" class="card-image thumbnail-transform is-translucent is-3d" [src]="frontImage.url | safe: 'resourceUrl'">
      <ng-container *ngIf="!isIconHidden">
        <div class="rotate-grab of-left-top is-3d">
          <i class="material-icons">autorenew</i>
        </div>
        <div class="rotate-grab of-left-bottom is-3d">
          <i class="material-icons">autorenew</i>
        </div>
        <div class="rotate-grab of-right-bottom is-3d">
          <i class="material-icons">autorenew</i>
        </div>
        <div class="rotate-grab of-right-top is-3d">
          <i class="material-icons">autorenew</i>
        </div>
        <div class="border-bg is-pointer-events-none is-3d"></div>
      </ng-container>
    </div>
  </div>
</div>
