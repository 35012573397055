<div class="container">
  <div class="title">ポップアップメッセージ(任意の場所をを右クリック→メッセージを送信)</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/popup1.png">
    </div>
    <div class="description">
      ゲームテーブル内の任意の場所を指示することができます<br/>
      クリックした場所にメッセージが表示されます
    </div>
  </div>
  <div class="title">ポップアップメッセージの表示</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/popup2.png">
    </div>
    <div class="description">
      ポップアップメッセージは15秒間メッセージとともに表示され<br/>
      消滅します
    </div>
  </div>
</div>