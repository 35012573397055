<div [style.visibility]="isShowStand ? 'visible' : 'hidden'"
  [style.pointer-events]="isShowStand ? 'auto' : 'none'">
  <div [@standInOut]="groupValue" *ngIf="!isFarewell && (isVisible || !isCanBeGone)" class="stand-image-container"
    [style.bottom.px]="standBottom"
    [style.left]="position + 'px'"
    [style.height]="height == 0 ? '' : height + 'vh'"
    [style.margin-left.px]="-imageWidth / 2"
    [style.margin-bottom.px]="adjustY"
    [ngClass]="{'is-can-be-gone': isCanBeGone, 'is-ghostly': isGhostly, backyard: isBackyard, secret: isSecret}" (click)="toGhostly(); toBackyard()">
    <ng-container *ngIf="gameCharacter && standImage.url && standImage.url.length > 0">
      <div @fadeAndScaleInOut *ngIf="!isBackyard && gameCharacter.isEmote"
        class="emote chat-bubble-buyo"
        style="position: absolute; pointer-events: none; text-align: center; margin-top: -1em"
        [style.visibility]="isShowStand && isApplyDialog ? 'visible' : 'hidden'"
        [style.width.px]="imageWidth"
        [style.font-size.px]="imageWidth / 4.5 > 16 ? imageWidth / 4.5 : 16"
        [style.color]="color ? color : chatWHITECOLOR"
        [style.bottom.px]="dialogBoxCssBottom + (imageWidth / 4.5 > 16 ? imageWidth / 4.5 : 16)"
        [ngClass]="{'emote-secret': isSecret}">{{ gameCharacter.text }}</div>
      <div [ngStyle]="{'transform-origin': standImageTransformOrigin, 'transform': isApplyRoll ? ('rotate(' + gameCharacter.roll + 'deg)') : ''}">
        <div class="color-fillter">
          <img class="stand-image aura" draggable="false"
            [style.height]="height > 0 ? height + 'vh' : 'auto'"
            [src]="standImage.url | safe: 'resourceUrl'"
            [ngStyle]="auraStyle">
          <img class="stand-image front-image" draggable="false" #standImageElement
            [style.height]="height > 0 ? height + 'vh' : 'auto'"
            [src]="standImage.url | safe: 'resourceUrl'"
            [ngStyle]="imgStyle">
          </div>
      </div>
    </ng-container>
  </div>
  <span *ngIf="isShowStand && !isFarewell && (isVisible || !isCanBeGone) && isShowNameTag && gameCharacter && gameCharacter.name" class="name-tag"
    style="pointer-events: none;"
    [style.text-shadow]="'1px 1px 2px ' + color"
    [ngClass]="{'is-can-be-gone': isCanBeGone, 'is-ghostly': isGhostly, backyard: isBackyard}"
    [style.left]="position + 'px'"
    [style.bottom.px]="(standBottom + 2)"
    [style.margin-bottom.px]="adjustY > 0 ? adjustY : 0"
    [style.border-color]="(color && color != chatWHITECOLOR ) ? color : chatBLACKCOLOR"
    [style.box-shadow]="'2px 2px 2px ' + color + ' inset,-2px -2px 2px ' + color + ' inset'"
    [style.margin-left.px]="nameTagCSSMarginLeft"
    ><b>{{ gameCharacter.name }}</b></span>
  <div @fadeAndScaleInOut *ngIf="!isFarewell && isVisible && !isBackyard && gameCharacter && gameCharacter.text && !gameCharacter.isEmote" class="name-tag dialog chat-bubble-buyo"
    (click)="toGhostly(); toBackyard();"
    style="margin-left: 12px; min-width: 5em; white-space: pre-wrap; pointer-events: none;"
    [style.visibility]="isShowStand && isApplyDialog ? 'visible' : 'hidden'"
    [style.color]="isSecret ? '#FFF' : '#222'"
    [style.text-shadow]="isSecret ? '2px 2px #222' : ('1px 1px 1px ' + color)"
    [style.left.px]="dialogBoxCSSLeft"
    [style.bottom.px]="(dialogBoxCssBottom + adjustY) > 12 ? dialogBoxCssBottom : 12"
    [style.max-width.px]="dialogBoxCSSMaxWidth"
    [style.margin-bottom.px]="(dialogBoxCssBottom + adjustY) > 12 ? adjustY : 0"
    [ngStyle]="{left: (position <= halfWindow ? dialogBoxCSSLeft + 'px' : 'auto'), right: (position > halfWindow ? dialogBoxCSSRight + 'px' : 'auto'), 'transform-origin': position <= halfWindow ? '12px calc(100% + 12px)' : 'calc(100% - 12px) calc(100% + 12px)'}"
    [ngClass]="{'is-can-be-gone': isCanBeGone, 'is-ghostly': isGhostly, backyard: isBackyard, 'dialog-secret': isSecret, 'is-position-right': position <= halfWindow, 'is-position-left': position > halfWindow}"
    [style.border-color]="(color && color != chatWHITECOLOR ) ? color : chatBLACKCOLOR"><p style="width: 100%" [@dialogShake]="math.floor(dialogText.length / 2)" (@dialogShake.start)="onSpeaking($event)" [innerHTML]="dialogText"></p></div>
</div>
