<div class="dummy-will-change" appMovable [movable.option]="movableOption">
</div>
<div class="component is-3d is-pointer-events-none" [ngClass]="{'is-empty': isEmpty}"
  [style.width.px]="width" [style.height.px]="height"
  appMovable [movable.option]="movableOption" (movable.ondragstart)="onMove()" (movable.ondragend)="onMoved()">
  <div class="component-content is-3d is-pointer-events-auto is-grab"
    appRotable [rotable.option]="rotableOption" (rotable.ondragstart)="onMove()" (rotable.ondragend)="onMoved()"
    @bounceInOut (@shuffle.start)="animationShuffleStarted($event)" (@shuffle.done)="animationShuffleDone($event)"
      [@shuffle]="animeState">
    <div class="card-image" [ngStyle]="{'transform': 'translateZ(' + thick +'px)'}" [style.width.px]="width" [style.height.px]="height"
        [style.background-image]="'url(' + imageFile.url + ')' | safe: 'style'">
        <div *ngIf="topCard.isFront" class="text" style="width: 100%; height: 100%"
        [ngStyle]="{'transform': 'rotateZ(' + topCard.rotate +'deg)'}">
        <div [style.font-size.px]="topCard.fontsize + 9" [style.color]="topCard.color" [innerHTML]="rubiedText"></div>
        </div>
        <div *ngIf="isShowTotal" class="name-tag is-nowrap is-black-background is-3d" style="z-index: 10" [ngStyle]="{'transform': 'translateY(-50%) translateX(-50%) translateX(' + size * gridSize / 2 +'px)'}">
          <span>{{cards.length}}枚</span>
        </div>
        <div *ngIf="hasOwner" class="name-tag has-length-limit is-nowrap is-black-background is-3d" style="z-index: 10; color: #222" [ngStyle]="{'background-color': ownerColor,'transform': 'translateY(50%) translateX(-50%) translateX(' + size * gridSize / 2 +'px)'}">
          <span><b>{{ownerName}}</b> が確認中</span>
        </div>
    </div>
    <ng-container *ngIf="cards.length > 0">
      <div *ngIf="isVisibleWallTopBottom" class="thick of-wall-top"
        [style.width.px]="width" [style.height.px]="thick"></div>
      <div *ngIf="isVisibleWallTopBottom" class="thick of-wall-bottom"
        [style.width.px]="width" [style.height.px]="thick"></div>
      <div *ngIf="isVisibleWallLeftRight" class="thick of-wall-left"
        [style.width.px]="height" [style.height.px]="thick"></div>
      <div *ngIf="isVisibleWallLeftRight" class="thick of-wall-right"
        [style.width.px]="height" [style.height.px]="thick"></div>
    </ng-container>
    <ng-container *ngIf="!isIconHidden">
      <div class="rotate-grab of-left-top is-3d">
        <i class="material-icons">autorenew</i>
      </div>
      <div class="rotate-grab of-left-bottom is-3d">
        <i class="material-icons">autorenew</i>
      </div>
      <div class="rotate-grab of-right-bottom is-3d">
        <i class="material-icons">autorenew</i>
      </div>
      <div class="rotate-grab of-right-top is-3d">
        <i class="material-icons">autorenew</i>
      </div>
    </ng-container>
  </div>
</div>
