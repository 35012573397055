<div class="container">
  <div class="title">掲示板(Message Board)</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/board1.png">
    </div>
    <div class="description">
      画面右上に常時表示されている情報共有機能です<br/>
      新規作成・更新があると赤く点滅して更新を通知します<br/>
    </div>
  </div>
  <div class="title">掲示板を開く</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/board2.png">
    </div>
    <div class="description">
      Message Boardと書かれている場所をクリックすると情報一覧が表示されます。<br/>
      新規作成されたものはNEW、更新されたものはUPDATEと表示され、<br/>
      公開範囲が制限されたものには鍵のマークがつきます。<br/>
      開きたいものをクリックすると内容が表示されます。<br/>
    </div>
  </div>
  <div class="title">掲示板を作成する</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/board3.png">
    </div>
    <div class="description">
      新規作成をクリックすると作成画面が開きます。<br/>
      ここで掲示板の権限を全体に公開、全体に公開(編集不可)、公開範囲を制限から選択できます。<br/>
      権限に関わらず、作成したプレイヤーは編集可能です。<br/>
    </div>
  </div>
  <div class="title">画像を設定する</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/board4.png">
    </div>
    <div class="description">
      掲示板には画像を設定することが可能です。<br/>
      画像を設定した場合、画像をクリックすることでモニタが許す限り原寸大で画像を表示します。
    </div>
  </div>
</div>
