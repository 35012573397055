<div #content class="content">
  <ng-container *ngIf="0 < chatMessages.length">
    <ng-container *ngFor="let chatMessage of chatMessages; trackBy: trackByChatMessage">
      <chat-message *ngIf="chatMessage.isDisplayable" [isStickyNote]="true" [chatMessage]="chatMessage" [localFontsize]='this.localFontsize' [bgColor]='this.bgColor'
      [isSelected]="selectMessageIdentifier === chatMessage.identifier" (click)="selectMessage(chatMessage)">
      </chat-message>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="chatMessages.length <= 0">
    付箋が登録されていません
  </ng-container>
</div>
