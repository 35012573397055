<div class="flex-container">
  <div class="flex-item no-grow">
    <div>
      <button style="font-size: smaller; margin-bottom: 3px" (click)="add()">ダイスボット表の追加</button>
    </div>
    <select #diceRollTableSelecter style="width: 14em;" size="15" (change)="onChangeDiceRollTable($event.target.value)">
      <option *ngFor="let diceRollTable of diceRollTables" value="{{ diceRollTable.identifier }}">{{ diceRollTable.name }}</option>
    </select>
  </div>
  <div class="flex-item">
    <button style="float: right;" (click)="helpDiceRollTable()">?</button>
    <ng-container *ngIf="!isEmpty && selectedDiceRollTable">
      <div style="font-size: smaller;">
        <div style="margin-bottom: 3px;">
          <b>name: </b> 
          <input style="width: 60%; min-width: 5em" [(ngModel)]="diceRollTableName" placeholder="Name" [attr.disabled]="!isEditable ? '' : null" />
        </div>
        <div>
          <span style="white-space: nowrap">
            <b>command: </b>
            <input style="width: 7em" [(ngModel)]="diceRollTableCommand" placeholder="Command" [attr.disabled]="!isEditable ? '' : null" />
          </span>
          <span style="white-space: nowrap; margin-left: 1em">  
            <b>dice: </b>
            <input style="width: 3.3em; text-align: center" [(ngModel)]="diceRollTableDice" placeholder="Dice" [attr.disabled]="!isEditable ? '' : null" />
          </span>
        </div>
      </div>
      <div>
        <textarea style="width: 100%; height: 20em; margin-top: 3px; resize: vertical;" placeholder="1:数字を結果を:（コロン）で区切って記述&#13;&#10;2:結果２&#13;&#10;3:結果３&#13;&#10;4:…" [(ngModel)]="diceRollTableText" [attr.disabled]="!isEditable ? '' : null" ></textarea>
      </div>
      <hr/>
      <div>
        <ng-container *ngIf="!isDeleted">
          <button (click)="upTabIndex()">↑</button>
          <button (click)="downTabIndex()">↓</button>
          <div style="float: right">
            <button *ngIf="!roomService.disableSeparateDataSave" [disabled]="isSaveing" (click)="save()">
              <div style="position: relative;">
                <div [ngClass]="{'is-hidden': isSaveing}">保存</div>
                <div *ngIf="isSaveing" class="progress">{{progresPercent}}%</div>
              </div>
            </button>
            <button class="danger" (click)="delete()">削除</button>
          </div>
        </ng-container>
        <ng-container *ngIf="isDeleted && selectedDiceRollTableXml">
          <button (click)="restore()">元に戻す</button>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
<ng-container *ngIf="isEmpty">
  <div style="font-size: 12px;">
    ※ダイスボット表がありません。
    <ng-container *ngIf="isDeleted && selectedDiceRollTableXml">
      <button (click)="restore()">元に戻す</button>
    </ng-container>
  </div>
</ng-container>