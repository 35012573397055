<div class="container">
  <div class="title">ルーム設定(ルーム→ルーム設定)</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/room-admin.png">
    </div>
    <div class="description">
      ルーム基本情報<BR/>
      <ul>
        <li>ルーム名：<br/>ルーム名です。Wクリックをすることで編集可能です。<br/>もう一度Wクリックすると確定します。</li>
        <li>パスワード：<br/>ルームにログインするためのパスワードです。Wクリックをすることで編集可能です。<br/>もう一度Wクリックすると確定します。</li>
        <li>ルームマスター：<br/>ルーム作成者です。権限設定を行う事が可能です</li>
        <li>画像データ使用量：<br/>画像データのアップロードされたファイルサイズと最大サイズです。<br/>最大サイズを超過して画像ファイルをアップロードすることはできません</li>
        <li>音楽データ使用量：<br/>音楽データのアップロードされたファイルサイズと最大サイズです。<br/>最大サイズを超過して音楽ファイルをアップロードすることはできません</li>
        <li>ダイスボット：<br/>ルームデフォルトのダイスボットです。<br/>特別に指定しない限り、このダイスボットが使用されます。</li>
      </ul>
      ルーム設定<BR/>
      <ul>
        <li>アラーム：<br/>アラームを設定します。詳細は後述を確認してください</li>
        <li>操作ログ：<br/>ダイス・カードの操作ログを残します</li>
        <li>キャラクターテンプレート：<br/>新しいキャラクターを作成したときに、ここで選択したキャラクターの画像以外のデータをコピーします。</li>
      </ul>
      ルーム権限-透過モード<BR/>
      <ul>
        <li>透過モード：<br/>ルームマスターが伏せられたダイス・カード・キャラクターを確認可能にします</li>
      </ul>
      ルーム権限-アップロード禁止<BR/>
      <ul>
        <li>ルームデータアップロード禁止：<br/>ルームデータのアップロードを禁止します。ルームマスターも含まれます。</li>
        <li>データアップロード禁止(オブジェクト)：<br/>ルームマスター以外がオブジェクト(キャラクターや地形、共有メモ等)をアップロードすることを禁止します</li>
        <li>データアップロード禁止(その他)：<br/>ルームマスター以外がオブジェクト以外(チャットログ等)をアップロードすることを禁止します</li>
        <li>画像アップロード禁止：<br/>ルームマスター以外が画像をアップロードすることを禁止します</li>
        <li>音楽アップロード禁止：<br/>ルームマスター以外が音楽をアップロードすることを禁止します</li>
      </ul>
      ルーム権限-設定・操作禁止<BR/>
      <ul>
        <li>テーブル設定禁止：<br/>ルームマスター以外がテーブルを操作することを禁止します</li>
        <li>チャットタブ設定禁止：<br/>ルームマスター以外がチャットタブを操作することを禁止します</li>
        <li>カウンター設定禁止：<br/>ルームマスター以外がカウンターを操作することを禁止します</li>
        <li>カットイン設定禁止：<br/>ルームマスター以外がカットインを操作することを禁止します</li>
        <li>ダイスボット表設定禁止：<br/>ルームマスター以外がダイスボット表を操作することを禁止します</li>
        <li>ラウンド操作禁止：<br/>ルームマスター以外がラウンドを操作することを禁止します</li>
      </ul>
      ルーム権限-保存禁止<BR/>
      <ul>
        <li>部屋全データセーブ禁止：<br/>ルームマスター以外がルームの全データを保存できなくします</li>
        <li>個別データセーブ禁止：<br/>ルームマスター以外がデータを保存できなくします</li>
      </ul>
    </div>
  </div>
  <div class="title">アラーム</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/alarm.png">
    </div>
    <div class="description">
      指定した秒数後にアラームを鳴らします<br/>
      アラームが送信されたプレイヤーにはカウントダウンタイマーが表示され、0:00になったときにアラームが鳴り響きます。<br/>
      カウントダウンタイマーは消すことができず、例え表示上から消えても時間になればアラームが鳴ります。<br/><br/>
      アラームのキャンセルはできません
    </div>
  </div>
  <div class="title">プレイヤー情報(ルーム→プレイヤー情報)</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/peer-menu.png">
    </div>
    <div class="description">
      <ul>
        <li>プレイヤーアイコン：<br/>プレイヤーアイコンです</li>
        <li>あなたの色：<br/>プレイヤーカラーです。ゲーム内で変更してもブラウザには保存されません。</li>
        <li>ニックネーム：<br/>プレイヤー名です。ゲーム内で変更してもブラウザには保存されません。</li>
        <li>あなたのID：<br/>プレイヤーのIDです。</li>
        <li>bcdiceサーバ：<br/>使用しているダイスボットのバージョンです</li>
      </ul>
      ログイン中のプレイヤー：<br/>現在オンライン状態のプレイヤー一覧です<br/>
      全プレイヤーの管理：<br/>登録されている全てのプレイヤーを管理します。詳細は後述を確認してください<br/>
    </div>
  </div>
  <div class="title">全プレイヤー(ルーム→プレイヤー情報→全プレイヤーの管理)</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/player-control.png">
    </div>
    <div class="description">
      ルームマスターはこの画面で、オフライン状態のプレイヤーを削除する事が可能です<BR/>
      ルームマスター以外はこの画面を開くことはできません
    </div>
  </div>
</div>

