<div class="background-image is-pointer-events-none" [ngClass]="[(backgroundFilterType ? 'is-filter' : ''), backgroundFilterType]"
[style.background-image]="'url(' + backgroundImage.url + ')' | safe: 'style'"></div>
<div class="component is-fill is-perspective" [ngClass]="{'is-grabbing': isPointerDragging}" #root>
  <div class="component-content is-fill is-3d" #gameTable>
    <div id="app-game-table" class="game-table table-image is-3d" [style.background-image]="'url(' + tableImage.url + ')' | safe: 'style'" #gameObjects>
      <canvas class="grid-canvas is-fill is-pointer-events-none" #gridCanvas
        [ngStyle]="{'transform': 'translateZ(' + (gridHeight + 0.1) + 'px)'}"></canvas>
      <div *ngIf="terrains.length > 0" class="layer terrain-layer">
        <div class="dummy"></div>
        <terrain class="is-3d" *ngFor="let terrain of terrains; trackBy: trackByGameObject" [terrain]="terrain"></terrain>
      </div>
      <div *ngIf="tableMasks.length > 0" class="layer mask-layer">
        <div class="dummy"></div>
        <game-table-mask class="is-3d" *ngFor="let mask of tableMasks; trackBy: trackByGameObject" [gameTableMask]="mask"></game-table-mask>
      </div>
      <div *ngIf="textNotes.length > 0" class="layer mask-layer">
        <div class="dummy"></div>
        <text-note class="is-3d" *ngFor="let textNote of textNotes; trackBy: trackByGameObject" [textNote]="textNote" [appTooltip]="textNote" [ngStyle]="{'z-index' : textNote.zindex}"></text-note>
      </div>
      <div *ngIf="cardStacks.length > 0" class="layer mask-layer">
        <div class="dummy"></div>
        <card-stack class="is-3d" *ngFor="let cardStack of cardStacks; trackBy: trackByGameObject" [cardStack]="cardStack" [appTooltip]="cardStack" [ngStyle]="{'z-index' : cardStack.zindex, 'transform': 'translateZ(' + (cardStack.zindex * 0.001) +'px)'}"></card-stack>
      </div>
      <div *ngIf="cards.length > 0" class="layer mask-layer">
        <div class="dummy"></div>
        <card class="is-3d" *ngFor="let card of cards; trackBy: trackByGameObject" [card]="card" [appTooltip]="card" [ngStyle]="{'z-index' : card.zindex, 'transform': 'translateZ(' + (card.zindex * 0.001) +'px)'}"></card>
      </div>
      <div *ngIf="diceSymbols.length > 0" class="layer character-layer">
        <div class="dummy"></div>
        <dice-symbol class="is-3d" *ngFor="let diceSymbol of diceSymbols; trackBy: trackByGameObject" [diceSymbol]="diceSymbol" [appTooltip]="diceSymbol"></dice-symbol>
      </div>
      <div *ngIf="characters.length > 0" class="layer character-layer">
        <div class="dummy"></div>
        <game-character class="is-3d" *ngFor="let character of characters; trackBy: trackByGameObject" [gameCharacter]="character" [appTooltip]="character"></game-character>
      </div>
      <div *ngIf="popups.length > 0" class="layer character-layer">
        <div class="dummy"></div>
        <popup class="is-3d" *ngFor="let popup of popups; trackBy: trackByGameObject" [popup]="popup"></popup>
      </div>
      <div class="layer cursor-layer">
        <div class="dummy"></div>
        <peer-cursor class="is-3d" *ngFor="let cursor of peerCursors; trackBy: trackByPeerId" [cursor]="cursor"></peer-cursor>
      </div>
    </div>
  </div>
</div>

