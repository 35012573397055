import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'help-object',
  templateUrl: './help-object.component.html',
  styleUrls: ['../help.content.css']
})
export class HelpObjectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
