<div class="component is-pointer-events-none" [@fadeInOut]="'in'" appDraggable #draggablePanel>
  <ng-container [ngSwitch]="tabletopObject.aliasName">
    <ng-container *ngSwitchCase="'numberResource'">
    </ng-container>
    <ng-container *ngSwitchCase="'card'">
      <ng-container *ngTemplateOutlet="overviewCard; context: { card: tabletopObject }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'card-stack'">
      <ng-container *ngTemplateOutlet="overviewCard; context: { card: tabletopObject }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'text-note'">
      <ng-container *ngTemplateOutlet="overviewNote; context: { note: tabletopObject }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'character'">
      <ng-container *ngTemplateOutlet="overviewCharacter; context: { character: tabletopObject }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'dice-symbol'">
      <ng-container *ngTemplateOutlet="overviewDiceSymbol; context: { dice: tabletopObject }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="overviewCharacter; context: { character: tabletopObject }"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-container *ngIf="isOpenImageView">
  <ng-container *ngTemplateOutlet="imageView"></ng-container>
</ng-container>

<ng-template #overviewCard let-card="card">
  <div class="component-content is-small-size"
    [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }">
    <div *ngIf="hasImage" style="position: relative;">
      <div *ngIf="(card.aliasName == 'card' && (card.isFront || card.isHand)) || (card.aliasName == 'card-stack' && card.topCard.isFront)"
        class="text" style="z-index: 5; top: 8px"
        [style.width.px]="imageAreaRect.width" [style.height.px]="imageAreaRect.height" [style.left.px]="imageAreaRect.left"
        [ngStyle]="{transform: 'scale(' + imageAreaRect.scale + ')'}">
        <div [style.font-size.px]="cardFontSize" [style.color]="cardColor" [innerHTML]="cardText"></div>
      </div>
      <img #cardImage class="card-image" [src]="imageUrl | safe: 'resourceUrl'" (load)="onCardImageLoad()" />
      <button class="zoom-button" (click)="chanageImageView(true)" style="z-index: 10">
        <i class="material-icons">zoom_in</i>
      </button>
      <div *ngIf="card.hasOwner && 0 < card.ownerName.length" class="component-owner position-right-bottom"
        [ngClass]="{'with-button-marign': hasImage}">
        <div class="owner-tag" [style.background-color]="card.ownerColor">{{card.ownerName}}</div>
      </div>
    </div>
    <div *ngIf="0 < card?.name?.length" class="component-title title-background"
      [ngClass]="{'with-button-marign': hasImage}">
      <ng-container *ngIf="card.isVisible == null || card.isVisible">{{card.name}}</ng-container>
      <ng-container *ngIf="card.isVisible != null && !card.isVisible">カード</ng-container>
    </div>
    <div class="component-background">
      <div class="grid-container-root" *ngIf="hasDataElms && card.isVisible">
        <div class="grid-container">
          <ng-container *ngFor="let dataElm of dataElms">
            <ng-container *ngTemplateOutlet="dataElement; context: { dataElm: dataElm, name: card.name }"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #overviewNote let-note="note">
  <div class="component-content"
    [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }"
    [ngStyle]="{'width' : note.viewWidth > 1 ?  note.viewWidth * 50 + 'px' : '100%',
      'max-width' : note.viewWidth > 1 ? none : '350px'}">
    <div class="component-title title-background">{{note.title}}</div>
    <div class="component-background text-note-container">
      <div style="position: relative" (click)="textAreaActivate()">
        <div class="is-fill">
          <textarea class="textarea" #textArea style="resize: none" [(ngModel)]="note.text" spellcheck="false" [ngClass]="{'hide-text-area': !isSelected}"></textarea>
        </div>
        <div class="note-text" style="pointer-events: none; position: absolute" [innerHTML]="adjustedRubiedNote(note.text)"
        [ngClass]="{'hide-area': isSelected}"></div>
      <div class="textarea-spacer" style="pointer-events: none" [innerHTML]="adjustedRubiedNote(note.text, !isSelected)"></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #overviewCharacter let-character="character">
  <div class="component-content character-grid-container is-large-size">
    <div class="title-bg-area title-background"
      [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }">
    </div>
    <div *ngIf="hasImage" class="image-area character-image-box"
      [ngClass]="{ 'has-icon': isUseIcon,'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }">
      <ng-container *ngIf="!isUseIcon">
        <div [ngStyle]="{'transform': ('rotate(' + roll + 'deg)') }">
          <img class="character-image aura" [ngStyle]="character.auraStyle" [src]="imageUrl | safe: 'resourceUrl'">
          <img class="character-image front-image" [ngStyle]="character.imgStyle" [src]="imageUrl | safe: 'resourceUrl'">
        </div>
      </ng-container>
      <ng-container *ngIf="isUseIcon">
        <img class="character-image" [src]="imageUrl | safe: 'resourceUrl'" />
      </ng-container>
      <!--<img class="character-image" [src]="imageUrl | safe: 'resourceUrl'" />-->
    </div>
    <div class="title-area component-title" [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging,
    'pointer-events-none': this.isPointerDragging }">{{character.name}}</div>
    <div class="void-area is-pointer-events-none"></div>
    <div *ngIf="character.isTransparent ; else forbidden" class="dataElm-area component-background"
      [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }">
      <div class="grid-container-root" *ngIf="hasDataElms">
        <div class="grid-container">
          <div class="counter-container">
            <div class="counter" *ngFor="let counter of counterList(character)" [attr.data-tooltip]="counter.desc + ':' + counter.comment"
             [ngStyle]="{'color': counter.age === 0 && !counter.isPermanent ? 'maroon' : 'black'}">
              {{counter.name}}<span *ngIf="!counter.isPermanent">:{{counter.age}}</span>
            </div>
          </div>
        </div>
        <div class="grid-container">
          <button class="charaSW" (click)="toggleCharaSW()">{{charaSWtext}}</button>
        </div>
        <ng-container [ngSwitch]="character.charaSW">
          <ng-container *ngSwitchCase="'status'">
            <ng-container *ngFor="let dataElm of inventoryDataElms">
              <ng-container *ngIf="dataElm && dataElm.name !== newLineString">
                <div class="grid-container">
                  <ng-container *ngTemplateOutlet="dataElement; context: { dataElm: dataElm, name: character.name }"></ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'note'">
            <ng-container *ngTemplateOutlet="overviewCharacterNote"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'resource'">
            <ng-container *ngTemplateOutlet="overviewCharacterResource"></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #overviewCharacterNote>
  <div class="component-content"
    [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }">
    <div class="component-background inner-note-container">
      <textarea class="inner-note" rows="{{notelength}}" [(ngModel)]="_character.note" spellcheck="false"></textarea>
    </div>
  </div>
</ng-template>

<ng-template #overviewCharacterResource>
  <ng-container *ngFor="let category of resources">
    <ng-container *ngTemplateOutlet="resourceCategory; context: { category: category }"></ng-container>
  </ng-container>
</ng-template>

<ng-template #resourceCategory  let-category="category">
  <div class="resource-title">
    {{category.name}}
  </div>
  <div class="resource-container">
    <ng-container *ngFor="let item of category.children">
      <ng-container *ngTemplateOutlet="resourceItem; context: { item: item }"></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #resourceItem  let-item="item">
  <div class="resource-item">
    <ng-container [ngSwitch]="item.type">
      <ng-container *ngSwitchCase="'note'">
        {{item.name}}
      </ng-container>
      <ng-container *ngSwitchCase="'simpleNumber'">
        {{item.name}}: {{item.value}}
      </ng-container>
      <ng-container *ngSwitchCase="'numberResource'">
        {{item.name}}: {{item.currentValue}} / {{item.value}}
      </ng-container>
      <ng-container *ngSwitchCase="'checkProperty'">
        <span *ngIf="!dataElement.value">{{item.name}}</span>
        <span *ngIf="dataElement.value">{{item.name}} (使用済み)</span>
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{item.name}}: {{item.value}}
      </ng-container>
    </ng-container>
  </div>
</ng-template>


<ng-template #forbidden>
  非公開
</ng-template>

<ng-template #overviewDiceSymbol let-dice="dice">
  <div class="component-content character-grid-container is-middle-size">
    <div class="title-bg-area title-background"
      [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }">
    </div>
    <div *ngIf="dice.hasOwner && 0 < dice.ownerName.length" class="owner-name-area component-owner"
      [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }">
      <div class="owner-tag" [style.background-color]="dice.ownerColor">{{dice.ownerName}}
      </div>
    </div>
    <div class="image-area character-image-box"
      [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }"
      *ngIf="hasImage">
      <img class="dice-image" [ngClass]="{'is-black-mask': !dice.isVisible, 'is-harf-black-mask': dice.isMine}"
        [src]="imageUrl | safe: 'resourceUrl'" />
    </div>
    <div class="title-area component-title"
    [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }">
      {{dice.name}}:
      <ng-container *ngIf="dice.isVisible">{{dice.face}}</ng-container>
      <ng-container *ngIf="!dice.isVisible">???</ng-container>
    </div>
    <div class="void-area is-pointer-events-none"></div>
    <div class="dataElm-area component-background"
      [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }">
      <div class="grid-container-root" *ngIf="hasDataElms && dice.isVisible">
        <div class="grid-container">
          <ng-container *ngFor="let dataElm of dataElms">
            <ng-container *ngIf="dataElm && dataElm.name !== newLineString">
              <ng-container *ngTemplateOutlet="dataElement; context: { dataElm: dataElm, name: dice.name }"></ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #dataElement let-dataElm="dataElm" let-name="name">
  <ng-container *ngIf="0 < dataElm.children.length; then thenBlock; else elseBlock"></ng-container>
  <ng-template #thenBlock>
    <div class="section" style="padding-top: 0.5em;">
      <div *ngIf="0 < dataElm.name.trim().length" class="section-text" [attr.title]="dataElm.name">{{dataElm.name}}</div>
      <div class="grid-container indetnt">
        <ng-container *ngFor="let childElm of dataElm.children">
          <ng-container *ngTemplateOutlet="dataElement; context: { dataElm: childElm, name: name }"></ng-container>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <ng-template #elseBlock>
    <ng-container [ngSwitch]="dataElm.type">
      <ng-container *ngSwitchCase="'note'">
        <div class="section">
          <div [attr.title]="dataElm.name">{{dataElm.name}}</div>
          <div class="note" style="position: relative;">
            <div class="is-fill">
              <textarea class="textarea" [(ngModel)]="dataElm.value" spellcheck="false"></textarea>
            </div>
            <div class="textarea-spacer">{{dataElm.value}}</div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'simpleNumber'">
        <div class="tag" [attr.title]="dataElm.name">{{dataElm.name}}</div>
        <div class="value">
          <div class="hidden-spacer">{{dataElm.value}}</div>
          <input class="input" style="width:4em" type="{{(dataElm.value && dataElm.value == +dataElm.value) ? 'number' : 'text'}}" size="1" [(ngModel)]="dataElm.value" />
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'numberResource'">
        <div class="tag" style="min-width: 100px" [attr.title]="dataElm.name">{{dataElm.name}}</div>
        <div class="value">
          <div style="display: inline-block; width: min-content;">
            <div class="hidden-spacer" style="margin-right: 2em;">{{dataElm.currentValue}}</div>
            <input class="input" style="width:4em" type="number" size="1" [(ngModel)]="dataElm.currentValue" />
          </div>
          <div style="display: inline;"> / {{!+dataElm.value ? '???' : dataElm.value}}</div>
        </div>
        <div class="section" style="line-height: 1em;">
          <input type="range" class="status-range" title="{{dataElm.currentValue}}"
            [style]="'font-size: 1em; width: 100%; --min: 0; --max:' + dataElm.value + '; --val:' + dataElm.currentValue +';' | safe: 'style'"
            min="0" max="{{!+dataElm.value ? 1000 : dataElm.value}}" [(ngModel)]="dataElm.currentValue" />
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'checkProperty'">
        <div class="tag" [attr.title]="dataElm.name"><span [style.textDecoration]="dataElm.value ? 'none' : 'line-through'">{{dataElm.name}}</span> {{ dataElm.currentValue.split(checkRegExp)[ dataElm.value ? 0 : 1 ] }}</div>
        <div class="value">
          <input type="checkbox" style="margin-right:1em" [(ngModel)]="dataElm.value" checked="{{dataElm.value ? 'checked' : ''}}" (change)="dataElm.value = (dataElm.value ? dataElm.name : '')">
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'abilityScore'">
        <div class="tag" [attr.title]="dataElm.name">{{dataElm.name}}</div>
        <div class="value">
          <div class="hidden-spacer">{{dataElm.value}}</div>
            <input class="input" style="width:4em" type="{{(dataElm.value && dataElm.value == +dataElm.value) ? 'number' : 'text'}}" size="1" [(ngModel)]="dataElm.value" />
            <span style="font-size: smaller;">({{ (dataElm.calcAbilityScore() >= 0 ? '+' : '') + dataElm.calcAbilityScore() }})</span>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'url'">
        <div class="tag" [attr.title]="dataElm.name">{{dataElm.name}}</div>
        <div class="value">
          <div class="hidden-spacer">{{dataElm.value}}</div>
          <input class="input" style="width:calc(8em - 1.6rem)" type="url" size="1" [(ngModel)]="dataElm.value" />
          <button class="link" style="width: 1.6rem" [attr.disabled]="!stringUtil.validUrl(dataElm.value) ? '' : null" (click)="stringUtil.validUrl(dataElm.value) ? openUrl(dataElm.value, name, dataElm.name) : false"><i class="material-icons" style="font-size: 1.0rem">{{ !stringUtil.validUrl(dataElm.value) ? 'link_off' : (stringUtil.sameOrigin(dataElm.value) ? 'link' : 'open_in_new') }}</i></button>
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <div *ngIf="dataElm.name" class="tag" [attr.title]="dataElm.name">{{dataElm.name}}</div>
        <div class="value">
          <div *ngIf="dataElm.name" class="hidden-spacer">{{dataElm.value}}</div>
          <input class="input" [ngStyle]="{'min-width': dataElm.name ? '8em' : '100%'}" type="{{dataElm.name === 'size' || dataElm.name === 'altitude' ? 'number' : 'text'}}" min="{{dataElm.name === 'size' ? '0' : ''}}" size="1" [(ngModel)]="dataElm.value" />
        </div>
      </ng-container>
    </ng-container>
    <div class="section border"></div>
  </ng-template>
</ng-template>

<ng-template #imageView>
  <div class="image-view-background is-fill" (click)="chanageImageView(false)" [@fadeInOut]="'in'">
    <div *ngIf="(tabletopObject.aliasName == 'card' && (tabletopObject.isFront || tabletopObject.isHand)) || (tabletopObject.aliasName == 'card-stack' && tabletopObject.topCard.isFront)"
      class="text" style="z-index: 5"
      [style.width.px]="fullImageAreaRect.width" [style.height.px]="fullImageAreaRect.height" [style.left.px]="fullImageAreaRect.left" [style.top.px]="fullImageAreaRect.top"
      [ngStyle]="{transform: 'scale(' + fullImageAreaRect.scale + ')'}">
      <div [style.font-size.px]="cardFontSize" [style.color]="cardColor" [innerHTML]="cardText"></div>
    </div>
    <div class="close-button is-pointer-events-none" style="z-index: 10"><i class="material-icons">close</i></div>
    <img #fullCardImage class="contain-image" [src]="imageUrl | safe: 'resourceUrl'" />
  </div>
</ng-template>
