<div class="sub">
  <nav>
    <ul>
      <li *ngIf="isOpen('file')" (click)="open('FileStorageComponent')">
        <a routerLink="/" routerLinkActive="active">
          <div><i class="material-icons icon-size">photo_library</i></div>
          <div>画像</div>
        </a>
      </li>
      <li *ngIf="isOpen('file')">
        <a routerLink="/" routerLinkActive="active">
          <label>
            <div><i class="material-icons">open_in_browser</i></div>
            <div>ZIP読込</div>
            <input type="file" style="display: none;" (change)="handleFileSelect($event)" multiple
              accept="application/xml,text/xml,application/zip" />
          </label>
        </a>
      </li>
      <li *ngIf="isOpen('file') && !disableAllDataSave && !isSaveing" (click)="save()">
        <a routerLink="/" routerLinkActive="active">
          <div><i class="material-icons">sd_storage</i></div>
          <div>ルーム保存</div>
        </a>
      </li>
      <li *ngIf="isOpen('file')   && !disableAllDataSave && isSaveing" style="pointer-events: none;">
        <a>
          <div><i class="material-icons vibration-amine">sd_storage</i></div>
          <div>{{progresPercent}}%</div>
        </a>
      </li>
      <li *ngIf="isOpen('room')" (click)="open('RoomControlComponent')">
        <a routerLink="/" routerLinkActive="active">
          <div><i class="material-icons icon-size">edit_attributes</i></div>
          <div>ルーム設定</div>
        </a>
      </li>
      <li *ngIf="isOpen('room')" (click)="open('PeerMenuComponent')">
        <a routerLink="/" routerLinkActive="active">
          <div><i class="material-icons icon-size">people</i></div>
          <div>プレイヤー情報</div>
        </a>
      </li>
      <li *ngIf="isOpen('room') && !disableTableSetting" (click)="open('GameTableSettingComponent')">
        <a routerLink="/" routerLinkActive="active">
          <div><i class="material-icons icon-size">layers</i></div>
          <div>テーブル設定</div>
        </a>
      </li>
      <li *ngIf="isOpen('room')" (click)="open('JukeboxComponent')">
        <a routerLink="/" routerLinkActive="active">
          <div><i class="material-icons">queue_music</i></div>
          <div>音楽</div>
        </a>
      </li>
     <li *ngIf="isOpen('room') && adminAuth" (click)="diceAllOpne()">
        <a routerLink="/" routerLinkActive="active">
          <div><i class="material-icons icon-size">all_out</i></div>
          <div>ダイス一斉公開</div>
        </a>
      </li>
      <li *ngIf="isOpen('room') && !disableRoundControl" (click)="resetRound()">
        <a routerLink="/" routerLinkActive="active">
          <div><i class="material-icons icon-size">refresh</i></div>
          <div>ラウンドをリセット</div>
        </a>
      </li>
      <li *ngIf="isOpen('chat')" (click)="open('ChatWindowComponent')">
        <a routerLink="/" routerLinkActive="active">
          <div><i class="material-icons icon-size">speaker_notes</i></div>
          <div>チャットウィンドウ</div>
        </a>
      </li>
      <li *ngIf="isOpen('chat')" (click)="open('PlayerPaletteComponent')">
        <a routerLink="/" routerLinkActive="active">
          <div><i class="material-icons icon-size">library_books</i></div>
          <div>パレットバインダー</div>
        </a>
      </li>
      <li *ngIf="isOpen('chat')" (click)="htmlsave()">
        <a routerLink="/" routerLinkActive="active">
          <div><i class="material-icons icon-size">web</i></div>
          <div>ログ保存</div>
        </a>
      </li>
      <li *ngIf="isOpen('data')" (click)="open('GameObjectInventoryComponent')">
        <a routerLink="/" routerLinkActive="active">
          <div><i class="material-icons icon-size">folder_shared</i></div>
          <div>インベントリ</div>
        </a>
      </li>
      <li *ngIf="isOpen('data')" (click)="open('SimpleInventoryComponent')">
        <a routerLink="/" routerLinkActive="active">
          <div><i class="material-icons icon-size">view_list</i></div>
          <div>イニシアティブリスト</div>
        </a>
      </li>
      <li *ngIf="isOpen('data')" (click)="open('CounterInventoryComponent')">
        <a routerLink="/" routerLinkActive="active">
          <div><i class="material-icons icon-size">blur_linear</i></div>
          <div>配布カウンター一覧</div>
        </a>
      </li>
      <li *ngIf="isOpen('work') && !disableCutinSetting" (click)="open('CounterListComponent')">
        <a routerLink="/" routerLinkActive="active">
          <div><i class="material-icons icon-size">grain</i></div>
          <div>カウンター設定</div>
        </a>
      </li>
      <li *ngIf="isOpen('work') && !disableAllDataSave" (click)="open('DiceRollTableSettingComponent')">
        <a routerLink="/" routerLinkActive="active">
          <div><i class="material-icons">build</i></div>
          <div>ダイスボット表</div>
        </a>
      </li>
      <li *ngIf="isOpen('work') && !disableCutinSetting" (click)="open('CutInSettingComponent')">
        <a routerLink="/" routerLinkActive="active">
          <div><i class="material-icons">perm_media</i></div>
          <div>カットイン設定</div>
        </a>
      </li>
      <li *ngIf="isOpen('work')" (click)="open('EffectViewComponent')">
        <a routerLink="/" routerLinkActive="active">
          <div><i class="material-icons icon-size">water_drop</i></div>
          <div>エフェクト</div>
        </a>
      </li>
    </ul>
  </nav>
  <div class="footer"></div>
</div>
