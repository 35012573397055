<div class="container">
  <div class="title">オブジェクトの回転</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/object1.png">
    </div>
    <div class="description">
      オブジェクトの端を掴んでドラッグすることで、<br/>
      オブジェクトを回転させることができます<br/>
      回転が邪魔な場合、右クリックでメニューを開き、回転を禁止にチェックを入れてください<br/>
    </div>
  </div>
  <div class="title">共有メモ</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/text-note.png">
    </div>
    <div class="description">
      記載する文章が長い場合、<br/>
      「サイズ固定」、「プレビュー時のみ本文を表示」の機能を使うことによって、<br/>
      本文の量に関わらずテーブル上のサイズを固定することができます。<br/>
    </div>
  </div>
  <div class="title">カード山札</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/cardstack.png">
    </div>
    <div class="description">
      カードの山札の枚数に応じて高さが変わります
    </div>
  </div>
</div>