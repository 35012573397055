<div class="dummy-will-change" appMovable [movable.option]="movableOption">
</div>
<div class="component is-grab is-pointer-events-none" [style.width.px]="size * gridSize"
  [style.height.px]="size * gridSize" appMovable [movable.option]="movableOption" (movable.ondragstart)="onMove()"
  (movable.ondragend)="onMoved()">
  <div class="component-content" [@bounceInOut]="'in'">
    <div class="component-content is-pointer-events-auto" appRotable [rotable.option]="rotableOption"
      (rotable.ondragstart)="onMove()" (rotable.ondragend)="onMoved()"  [rotable.disable]="stopRotate">
      <div [@switchImagePedestal]="gameCharacter.currntImageIndex" class="pedestal-inner" [ngClass]="{'not-inventory': !gameCharacter.isInventoryIndicate }"></div>
      <div [@switchImagePedestal]="gameCharacter.currntImageIndex" class="pedestal-outer"></div>
      <div class="pedestal-grab rotate-grab">
        <div class="pedestal-grab-border">
          <div class="material-icons of-front">autorenew</div>
          <div class="material-icons of-back">autorenew</div>
        </div>
      </div>
      <div class="is-fit-width">
        <div class="rotate-frame" [style.width.px]="size * gridSize" [style.height.px]="size * gridSize"
          appRotable [(rotable.value)]="roll" (rotable.ondragstart)="onMove()" (rotable.ondragend)="onMoved()" (dblclick)="nextImage()" [rotable.disable]="stopRotate">
          <div class="rotate-inner">
            <div *ngIf="0 < name.length" class="name-tag is-nowrap is-black-background"
              [ngStyle]="{'transform': 'translateX(-50%) translateX(' + size * gridSize / 2 +'px )translateY(-' + size * gridSize +'px'}">
              <div class="fornt-tag has-length-limit">{{name}}</div>
            </div>
            <div *ngIf="statusBar1 != null" [ngStyle]="{'width': size * gridSize + 'px','transform': 'translateY(-' + size * gridSize +'px'}" class=" status-bar-base">
              <div class="status-bar"
                [ngStyle]="{'width': size * gridSize * statusBar1 + 'px' , 'background-color': statusColor1}"
              ></div>
            </div>
            <div *ngIf="statusBar2 != null" [ngStyle]="{'width': size * gridSize + 'px','transform': 'translateY(-' + size * gridSize +'px'}" class=" status-bar-base">
              <div class="status-bar"
                [ngStyle]="{'width': size * gridSize * statusBar2 + 'px' , 'background-color': statusColor2}"
              ></div>
            </div>
            <div *ngIf="statusBar3 != null" [ngStyle]="{'width': size * gridSize + 'px','transform': 'translateY(-' + size * gridSize +'px'}" class=" status-bar-base">
              <div class="status-bar"
                [ngStyle]="{'width': size * gridSize * statusBar3 + 'px' , 'background-color': statusColor3}"
              ></div>
            </div>
            <img *ngIf="0 < imageFile.url.length && auraStyle" class="image is-3d aura" [style.width.px]="size * gridSize" [style.height.px]="size * gridSize" [ngStyle]="auraStyle" [src]="imageFile.url | safe: 'resourceUrl'">
            <img #characterImage *ngIf="0 < imageFile.url.length" class="image is-3d front-image" [style.width.px]="size * gridSize" [style.height.px]="size * gridSize" [ngStyle]="imgStyle" [src]="imageFile.url | safe: 'resourceUrl'"
            [@switchImage]="gameCharacter.currntImageIndex">
          </div>
        </div>
      </div>
  </div>
</div>
