<div class="flex-box">
  <div class="flex-item">
    <div class="item-name">
      プレイヤーアイコン
    </div>
    <div class="item-data">
      <div *ngIf="player.image" class="image cover" [style.background-image]="'url(' + player.image.url + ')' | safe: 'style' "
      (click)="changeIcon()">
      </div>
      <button (click)="changeIcon()">変更</button>
    </div>
  </div>
  <div class="flex-item">
    <div class="item-name">
      あなたの色
    </div>
    <div class="item-data">
      <input type="color" style="width:2em; height:2.5em;" [(ngModel)]="myColor">
      <button (click)="colorReset()" >初期化</button>
    </div>
  </div>
  <div class="flex-item">
    <div class="item-name">
      ニックネーム
    </div>
    <div class="item-data">
      <input [(ngModel)]="myName" [style.color]="myColor" style="font-weight: bolder;" placeholder="ニックネーム" />
    </div>
  </div>
  <div class="flex-item">
    <div class="item-name">
    あなたのID
    </div>
    <div class="item-data">
    {{player.playerId}}
    </div>
  </div>
  <div class="flex-item">
    <div class="item-name">
      新規キャラクターの秘匿
    </div>
    <div class="item-data">
      <div class="toggle"><label>
        <input name="isHideCharacterOnLoad" type="checkbox" [(ngModel)]="playerService.isHideCharacterOnLoad">
        <div><div></div></div>
      </label></div>
    </div>
  </div>
  <div class="flex-item">
    <div class="item-name">
      bcdiceサーバ
    </div>
    <div class="item-data">
      <span *ngIf="diceBotService.isConnect; else disconnect">version {{diceBotService.api.bcDiceVersion}}</span>
    </div>
  </div>
</div>
<hr/>
<ng-container>
  ログイン中のプレイヤー
  <div *ngFor="let peer of otherPeers">
    <div>
      <span>[{{peer.player.playerId}}] <b [style.color]="peer.player.color">{{peer.player.name}}</b></span>
      <span *ngIf="peer.peerId == myPeer">[あなた]</span>
    </div>
  </div>
</ng-container>
<button (click)="playerControl()" [disabled]="!auth"> 全プレイヤーの管理 </button>
<div><span style="font-size: 12px;">{{help}}</span></div>
<ng-template #disconnect>
  <span style="font-weight: bold;">未接続</span>
</ng-template>
