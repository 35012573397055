<div class="container">
  <div class="title">ファイル</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/menu-file.png">
    </div>
    <div class="description">
      <ul>
        <li>画像：画像のアップロード、設定、削除を行います</li>
        <li>ZIP読み込み：保存したZIPファイルを読み込ませます</li>
        <li>ルーム保存：ルームデータ全てをローカルに保存します</li>
      </ul>
    </div>
  </div>
  <div class="title">ルーム</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/menu-room.png">
    </div>
    <div class="description">
      <ul>
        <li>ルーム設定：ルーム全体にかかわる権限・設定が行えます</li>
        <li>プレイヤー情報：自分のプレイヤーの設定、他のプレイヤーの確認、削除が行えます</li>
        <li>テーブル設定：ゲームテーブル・背景の設定が行えます</li>
        <li>音楽：BGM・SEの再生・停止・削除が行えます</li>
        <li>ダイス一斉公開：伏せられたダイスを公開します。</li>
        <li>ラウンドをリセット：ラウンドをリセットします。</li>
      </ul>
    </div>
  </div>
  <div class="title">チャット</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/menu-chat.png">
    </div>
    <div class="description">
      <ul>
        <li>チャットウィンドウ：チャットウィンドウを新たに表示します</li>
        <li>パレットバインダー：チャットパレットを表示します</li>
        <li>チャットログ：全てのチャットタブのログをタブごとに保存します</li>
     </ul>
    </div>
  </div>
  <div class="title">リスト</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/menu-data.png">
    </div>
    <div class="description">
      <ul>
        <li>インベントリ：キャラクターインベントリを表示します</li>
        <li>イニシアティブリスト：イニシアティブリストを表示します</li>
        <li>配布カウンター一覧：カウンター一覧を表示します</li>
     </ul>
    </div>
  </div>
  <div class="title">機能</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/menu-work.png">
    </div>
    <div class="description">
      <ul>
        <li>カウンター設定：ゲームキャラクターにカウンターを配布します</li>
        <li>ダイスボット表：カスタムダイスボット表を設定します</li>
        <li>カットイン：カットインを設定します</li>
        <li>エフェクト：ゲームキャラクターに３Dエフェクトを表示します</li>
     </ul>
    </div>
  </div>
  <div class="title">表示</div>
  <div class="section">
    <div class="image">
      <img src="./assets/help/menu-view.png">
    </div>
    <div class="description">
      パネル<br>
      <ul>
        <li>全てのパネルを消去：チャットウィンドウを新たに表示します</li>
      </ul>
     視点(３Dモード限定)
      <ul>
        <li>初期視点に戻す：視点を初期に戻します</li>
        <li>真上から見る：視点をテーブルの真上に移動します</li>
      </ul>
      立ち絵
      <ul>
        <li>立ち絵表示設定：立ち絵の表示位置の確認、表示範囲の設定ができます</li>
        <li>立ち絵全消去：表示中の立ち絵を全て消します</li>
        <li>立ち絵表示：立ち絵表示のON/OFFを切り替えます</li>
        <li>ネームタグ表示：立ち絵表示時のネームタグのON/OFFを切り替えます</li>
        <li>透明化、自動退去：立ち絵が発言後、自動的に消滅するかどうかを切り替えます</li>
     </ul>
     キャラクター<br>
     <ul>
       <li>ステータスバー表示：ステータスバー表示のON/OFFを切り替えます</li>
     </ul>
     エフェクト<br>
     <ul>
       <li>エフェクト表示：エフェクト表示のON/OFFを切り替えます</li>
     </ul>
    </div>
  </div>
</div>
