import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'help-stand',
  templateUrl: './help-stand.component.html',
  styleUrls: ['../help.content.css']
})
export class HelpStandComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
