import { ChatMessage, ChatMessageContext } from './chat-message';
import { SyncObject, SyncVar } from './core/synchronize-object/decorator';
import { ObjectNode } from './core/synchronize-object/object-node';
import { InnerXml, ObjectSerializer } from './core/synchronize-object/object-serializer';
import { ObjectStore } from './core/synchronize-object/object-store';
import { EventSystem } from './core/system';
import { StringUtil } from './core/system/util/string-util';
import { RoomAdmin } from './room-admin';

@SyncObject('chat-tab')
export class ChatTab extends ObjectNode implements InnerXml {
  @SyncVar() name: string = 'タブ';
  @SyncVar() isUseStandImage: boolean = true;
  @SyncVar() allowedPlayers:string[] = [];
  get chatMessages(): ChatMessage[] { return <ChatMessage[]>this.children; }

  private _unreadLength: number = 0;
  get unreadLength(): number { return this._unreadLength; }
  get hasUnread(): boolean { return 0 < this.unreadLength; }

  get latestTimeStamp(): number {
    let lastIndex = this.chatMessages.length - 1;
    return lastIndex < 0 ? 0 : this.chatMessages[lastIndex].timestamp;
  }

  get isAllowed():boolean {
    if (this.allowedPlayers.length < 1) return true;
    return  (RoomAdmin.auth || this.allowedPlayers.includes(RoomAdmin.myPlayerID));
  }

  // ObjectNode Lifecycle
  onChildAdded(child: ObjectNode) {
    super.onChildAdded(child);
    if (child.parent === this && child instanceof ChatMessage && child.isDisplayable) {
      this._unreadLength++;
      this.messageAdd(child.identifier);
    }
  }

  async messageAdd(identifier :string) {
    if (ObjectStore.instance.get(identifier))
      EventSystem.trigger('MESSAGE_ADDED', { tabIdentifier: this.identifier, messageIdentifier: identifier });
    else
      setTimeout(() => {
        this.messageAdd(identifier)
        }, 100);
  }

  getMessage(identifier :string): ChatMessage {
    let object = ObjectStore.instance.get(identifier);
    if (object instanceof ChatMessage) {
      return object;
    }
    return null;
  }

  addMessage(message: ChatMessageContext): ChatMessage {
    message.tabIdentifier = this.identifier;

    let chat = new ChatMessage();
    for (let key in message) {
      if (key === 'identifier') continue;
      if (key === 'tabIdentifier') continue;
      if (key === 'text') {
        chat.value = message[key];
        continue;
      }
      if (message[key] == null || message[key] === '') continue;
      chat.setAttribute(key, message[key]);
    }
    chat.value =  this.filterEffect(String(chat.value) ,chat.characterIdentifier);
    if (chat.value == '') {
      chat.destroy;
      return null;
    }
    chat.initialize();
    EventSystem.trigger('SEND_MESSAGE', { tabIdentifier: this.identifier, messageIdentifier: chat.identifier });
    this.appendChild(chat);
    return chat;
  }

  clearAll() {
    this.chatMessages.forEach( chatMessage => {
      chatMessage.destroy();
    })
    EventSystem.trigger('REMOVE_MESSAGE', this.identifier);
  }

  markForRead() {
    this._unreadLength = 0;
  }

  filterEffect(text :string ,identifier :string):string {
    let result = text.match(/\@effect\((.*)\)$/i);
    if (result && identifier) {
      text = text.replace(/\@effect\(.*?\)$/,'');
      let eventstat = [result[1] , [identifier]]
      EventSystem.call('CHARACTER_EFFECT', eventstat);
    }
    return text;
  }

  innerXml(): string {
    let xml = '';
    for (let child of this.children) {
      if (child instanceof ChatMessage && !child.isDisplayable) continue;
      xml += ObjectSerializer.instance.toXml(child);
    }
    return xml;
  };

  parseInnerXml(element: Element) {
    return super.parseInnerXml(element);
  };
}
