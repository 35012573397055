<div class="content">
  <div class="volume-panel" style="padding: 3px 6px;">
    <div class="flex-row flex-between volume-type">
      <div class="flex-row flex-center" style="width:60px">試聴音量</div>
      <div class="flex-row flex-center" style="width: calc(100% - 64px)">
        <i class="material-icons" style="font-size: 22px">{{ auditionVolume == 0 ? 'volume_off' : auditionVolume <= 0.5 ? 'volume_down' : 'volume_up' }}</i>
        <input [(ngModel)]="auditionVolume" type="range" title="{{percentAuditionVolume + '%'}}" min="0" max="1" step="0.001" style="width:65%" />
        <span style="white-space: nowrap;"><input type="number" min="0" max="100" step="1" style="width: 3em" [(ngModel)]="percentAuditionVolume"><b style="font-size: smaller; padding-left: 3px;">%</b></span>
      </div>
    </div>
    <div class="flex-row flex-between volume-type">
      <div class="flex-row flex-center" style="width:60px">再生音量</div>
      <div class="flex-row flex-center" style="width: calc(100% - 64px)">
        <i class="material-icons" style="font-size: 22px">{{ volume == 0 ? 'volume_off' : volume <= 0.5 ? 'volume_down' : 'volume_up' }}</i>
        <input [(ngModel)]="volume" type="range" min="0" title="{{percentVolume + '%'}}" max="1" step="0.001" style="width:65%" />
        <span style="white-space: nowrap;"><input type="number" min="0" max="100" step="1" style="width: 3em" [(ngModel)]="percentVolume"><b style="font-size: smaller; padding-left: 3px;">%</b></span>
      </div>
    </div>
    <div class="flex-row flex-between volume-type">
      <div class="flex-row flex-center" style="width:60px">SE音量</div>
      <div class="flex-row flex-center" style="width: calc(100% - 64px)">
        <i class="material-icons" style="font-size: 22px">{{ seVolume == 0 ? 'volume_off' : seVolume <= 0.5 ? 'volume_down' : 'volume_up' }}</i>
        <input [(ngModel)]="seVolume" type="range" min="0" title="{{percentSeVolume + '%'}}" max="1" step="0.001" style="width:65%" />
        <span style="white-space: nowrap;"><input type="number" min="0" max="100" step="1" style="width: 3em" [(ngModel)]="percentSeVolume"><b style="font-size: smaller; padding-left: 3px;">%</b></span>
      </div>
    </div>
  </div>
  <div class="audio-player">
    <div class="flex-row flex-center player" >
      <ng-container *ngIf="jukeboxIdentifier && !auditionIdentifier">
        再生中：<span class="audio-name">{{jukeboxName}}</span>
      </ng-container>
      <ng-container *ngIf="auditionIdentifier">
        試聴中：<span class="audio-name">{{auditionPlayerName}}</span>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="!roomService.disableAudioControl">
    <div class="control-panel">
      <button *ngIf="auditionIdentifier"class="round-button"  (click)="stop()">試聴停止</button>
      <button *ngIf="jukeboxIdentifier" class="round-button" (click)="stopBGM()" >BGM停止</button>
      <button class="round-button"  (click)="stopSE()">SE停止</button>
      <button class="round-button" (click)="toggleEdit()">ファイル追加</button>
      <button class="round-button" (click)="help()">？</button>
    </div>
    <div style="width: 98%;">
      <div class="tag-menu scroll-white">
        <form>
          <label>
            <input name="menu" type="radio" value="" [(ngModel)]="selectedTag">
            <div>全て</div>
          </label>
          <label>
            <input name="menu" type="radio" value="--notag--" [(ngModel)]="selectedTag">
            <div>タグ無し</div>
          </label>
          <label *ngFor="let tag of taglist">
            <input name="menu" type="radio" value="{{tag}}" [(ngModel)]="selectedTag">
            <div>{{tag}}</div>
          </label>
        </form>
      </div>
      <div *ngIf="!isEdit ;else editAudio" class="play-list scroll-white">
        <ng-container *ngFor="let audio of audios">
          <ng-container *ngTemplateOutlet="playlist_audio; context:{ audio: audio}">
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #playlist_audio let-audio="audio">
  <ng-container *ngIf="selectedAudioIdentifier !== audio.identifier ; else playlist_play">
    <div class="card" [ngClass]="{'playing': (jukeboxIdentifier ===  audio.identifier || auditionIdentifier === audio.identifier)}" [attr.data-volume]="'Volume : ' + audio.volume" (click)="selectCard(audio.identifier)">
      <span *ngIf="jukeboxIdentifier ===  audio.identifier" style="font-size:10px;color:lime">再生中<br/></span>
      <span *ngIf="auditionIdentifier === audio.identifier" style="font-size:10px;color:yellow">視聴中<br/></span>
      <span class="audio-name">{{audio.name}}</span>
    </div>
  </ng-container>
</ng-template>

<ng-template #playlist_play>
  <div class="card play" [attr.data-volume]="'Volume : ' + selectedAudio.volume" (click)="selectCard(selectedAudio.identifier)">
    <span class="audio-name">{{selectedAudio.name}}</span><br/>
    <button class="round-button" (click)="play()">試聴</button>
    <button class="round-button" (click)="playBGM()" >再生</button>
    <button class="round-button" (click)="playSE()">SE</button>
    <button style="margin-left: 2.5em;" class="round-button" (click)="toggleEdit(true)">編集</button>
  </div>
</ng-template>

<ng-template #editAudio>
  <div class="edit">
    <div  *ngIf="!editingIdentifier">
      <form>
        <input name="fileType" type="radio" value="local" [(ngModel)]="fileType">
        ローカルファイルをアップロード<br/>
        <div style="margin-left:1em">
          １ファイルにつき10MBまで<br/>
          ルームに誰もいなくなると削除<br/>
          <span *ngIf="fileType === 'local'"><input type="file" name="audiofile" (change)="handleFileSelect($event)" accept="audio/*" /></span>
        </div>
        <br/>
        <input name="fileType" type="radio" value="url" [(ngModel)]="fileType">
        外部URLを追加<br/>
        <div style="margin-left:1em">
          サイズ制限なし<br/>
          ルームに情報が保存されます<br/>
          <span *ngIf="fileType === 'url'">外部URL：<input name="url" type="text" width="32em" [(ngModel)]="url" /></span>
        </div>
        <br/>
      </form>
    </div>
    <span style="display: inline-block;width: 8em;">名前：</span><input type="text" width="24em" [(ngModel)]="name" /><br/>
    <ng-container *ngIf="editingIdentifier">
      <ng-container *ngIf="canEditUrl">
      <span style="display: inline-block;width: 8em;">外部URL：</span><input name="url" type="text" width="32em" [(ngModel)]="url" /><br/>
      </ng-container>
      <span style="display: inline-block;width: 8em;">Volume:</span><input type="text" width="6em" [(ngModel)]="editingVolume" /><br/>
    </ng-container>
    <span style="display: inline-block;width: 8em;">タグ:</span><input type="text" width="6em" [(ngModel)]="editingTag" /><br/>
    <div class="flex-row flex-between" style="height:3em">
      <div class="flex-row flex-center">
        <button style="height: 3em;" [disabled]="!canSave" (click)="saveEdit()">保存</button>
        <button style="height: 3em;" (click)="toggleEdit()" >キャンセル</button>
      </div>
      <div class="flex-row flex-center">
        <button *ngIf="editingIdentifier" class="remove" style="font-size:14px; height: 3em;" (click)="remove()"><i class="material-icons">delete</i></button>
      </div>
    </div>
  </div>
</ng-template>

