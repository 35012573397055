<div class="flex-container no-grow">
  <div class="window-setting">
      <div>チャットウィンドウ設定</div>
      <div class="comment">元のチャットウィンドウのみ変更されます</div>
      <div class="window-item">
        <div class="item-text">メッセージの背景色</div>
        <div class="item-setting">
           <div>
             <form>
              <input name="bgColor" type="radio" value="white" [(ngModel)]="bgColor">
              白
              <input name="bgColor" type="radio" value="grey" [(ngModel)]="bgColor">
              灰
              <input name="bgColor" type="radio" value="black" [(ngModel)]="bgColor">
              黒
             </form>
           </div>
        </div>
      </div>
      <div class="window-item">
        <div class="item-text">メッセージの文字サイズ</div>
        <div class="item-setting">
          <div>
             <form>
            {{this.localFontsize}}
            <input type="range" name="fontsize" min="10"  max="32" [(ngModel)]="localFontsize">
             </form>
          </div>
        </div>
      </div>
      <div class="window-item">
        <div class="item-text">操作パネル</div>
        <div class="item-setting" style="font-size: 12px;">
          <div>
            <form>
              <input name="controlType" type="radio" class="metal" value="" [(ngModel)]="controlType">
              なし
              <input name="controlType" type="radio" class="metal" value="resource" [(ngModel)]="controlType">
              リソース
              <input name="controlType" type="radio" class="metal" value="inventory" [(ngModel)]="controlType">
              インベントリ
            </form>
          </div>
        </div>
      </div>
      <div class="window-item">
        <div class="item-text">表示</div>
        <div class="item-setting">
          <form>
            <div style="display: flex;">
              <div class="toggle"><label>
              <input name="isEase" type="checkbox" [(ngModel)]="isEase" />
                <div><div></div></div>
              </label></div>
              <span> メッセージ簡易表示</span>
            </div>
            <div style="display: flex;">
              <div class="toggle"><label>
              <input name="isLogOnly" type="checkbox" [(ngModel)]="isLogOnly" />
              <div><div></div></div>
              </label></div>
              <span>チャット入力欄表示</span>
            </div>
          </form>
        </div>
      </div>
  </div>
  <div><HR /></div>
  <div *ngIf="!disableTabSetting" class="tab-setting no-grow">
    <div style="width:100%;">チャットタブ設定</div>
    <div class="tab-master">
    <div class="flex-item no-grow">
      <div>
        <button (click)="create()">チャットタブを作る</button>
      </div>
      <select name="selectedTabIdentifier" style="width: 10em;" size="8" [(ngModel)]="selectedTabIdentifier">
        <option *ngFor="let chatTab of chatTabs" value="{{chatTab.identifier}}">{{chatTab.name}}</option>
      </select>
    </div>
    <div class="flex-item" style="max-width: 300px;">
      <ng-container *ngIf="!isEmpty && selectedTab">
        <div>タブ名 :
          <input [(ngModel)]="tabName" placeholder="Name" [attr.disabled]="!isEditable ? '' : null" />
        </div>
        <div style="font-weight: bolder; font-size: smaller;display: flex;">
          <span>スタンド</span>
          <div class="toggle"><label>
            <input type="checkbox" [(ngModel)]="isUseStandImage" checked="{{isUseStandImage ? 'checked' : ''}}" (change)="isUseStandImage = (isUseStandImage ? true : false)" [attr.disabled]="!isEditable ? '' : null" />
            <div><div></div></div>
            </label></div>
        </div>
        <hr/>
        <div class="buttons">
          <ng-container *ngIf="!isDeleted">
            <button (click)="upTabIndex()">↑</button>
            <button (click)="downTabIndex()">↓</button>
            <button *ngIf="!roomService.disableSeparateDataSave" [disabled]="isSaveing" (click)="save()">
              <div style="position: relative;">
                <div [ngClass]="{'is-hidden': isSaveing}">保存</div>
                <div *ngIf="isSaveing" class="progress">{{progresPercent}}%</div>
              </div>
            </button>
            <button (click)="htmlsave()">チャットログ保存
            </button>
            <button *ngIf="roomService.adminAuth" class="danger" (click)="logClear()">ログのクリア</button>
            <button *ngIf="roomService.adminAuth" class="danger" (click)="delete()" [attr.disabled]="chatTabs.length <= 1 ? '' : null">削除</button>
            <div *ngIf="adminAuth" class="permission">
              <div>
                アクセス権限
                <select style="max-width: 10em" [(ngModel)]="select_player">
                    <option *ngFor="let player of playerService.otherPlayers" value="{{player.playerId}}" [style.color]="player.color">{{player.name}}
                    </option>
                </select>
                <button (click)="addPlayer()">追加</button>
              </div>
              <div>
                <span>許可されたプレイヤー:</span>
                <span *ngIf="allowPlayers.length < 1">制限なし</span>
                <div class="player" *ngFor="let player of allowPlayers">
                  {{player.playerName}}
                  <div class="erase" (click)="removePlayer(player.playerId)" >☓</div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="isDeleted">
            <button (click)="restore()">元に戻す</button>
          </ng-container>
        </div>
      </ng-container>
    </div>
    </div>
  </div>
</div>
