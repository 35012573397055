import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'help-room',
  templateUrl: './help-room.component.html',
  styleUrls: ['../help.content.css']
})
export class HelpRoomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
